import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    propsChildren: {
        marginBottom: '10px',
    }
});


const TitleHeader = (props: any) => {
    const { title, component, showSearch } = props;
    const classes = useStyles();

    return (
        <Box bgcolor="#EEEEEE" style={{ marginTop:'10px' }}>
            <Grid container  className={classes.propsChildren} alignItems="center">
                <Grid item xs={showSearch ? 12 : 6} sm={6} md={6} container>
                    {title && <Typography gutterBottom variant="h6" component="h5">
                        {title}
                    </Typography>}
                    {component ? component : null}
                </Grid>
                <Grid item xs={showSearch ? 12 : 6} sm={6} md={6}  container>
                    <Grid item xs container justifyContent="flex-end" alignItems="center" spacing={2}>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default TitleHeader;