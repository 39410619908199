import { Button, Grid, TextField, FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deactivateDistributionList, postCreateDistributionList, updateDistributionList } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import { emailValidator } from '../../utils';
import ConfirmDialog from '../Common/ConfirmDialog';
import { APP_STATUS } from '../../utils/contants';

const MuiCheckbox = withStyles({
    root: {
        color: '#0085ad',
        '&$checked': {
            color: '#0085ad',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: 20
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    label: {
        background: 'white',
        padding: '0px 5px',
    },
    buttonLabel: {
        textTransform: "none"
    },

}));

const DistributionListModal = (props: any) => {
    const { handleClose, triggerList, customerId } = props;
    const classes = useStyles();
    const navigate = useNavigate()
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const dispatch: any = useDispatch();
    const [editData, setEditData] = useState(props.editData || null);
    const [alertMessage, setAlertMessage] = useState<any>();
    const [subCheckBox, setSubCheckBox] = React.useState<any>({
        maintenanceAlert: true,
        incidentAlert: true,
    });
    const [formData, setFormData] = useState<any>({
        id: '',
        title: '',
        email: '',
    });
    const [errors, setErrors] = useState<any>({});
    const [showLoader, setShowLoader] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<any>(null);

    useEffect(() => {
        if (!editData) {
            return;
        }
        const form: any = {};
        form.id = editData.id;
        form.email = editData.channelId;
        if (editData.json) {
            form.json = editData.json;
        }
        if (form?.json?.title) {
            form.title = form?.json?.title;
        }
        if (form?.json?.jSubCheckBox) {
            setSubCheckBox(form.json.jSubCheckBox);
        }
        setFormData(Object.assign(formData, form));
    }, [editData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const data: any = Object.assign({}, formData);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setFormData(data);
        setErrors(err);
    };


    const handleCheckBoxChange = (event: any) => {
        setSubCheckBox({
            ...subCheckBox,
            [event.target.name]: event.target.checked
        });
    };

    const handleDeactivate = () => {
        setShowConfirmDialog(false);
        const status = editData && editData.status === APP_STATUS.VERIFIED ? APP_STATUS.INACTIVE : editData.status === APP_STATUS.INACTIVE ? APP_STATUS.VERIFIED : APP_STATUS.INVITED;
        deactivateDistributionList(editData.id, customerId, status)
        .then((resp: any) => {
            const res = resp && resp.data;
            if (res && res.success) {
                setAlertMessage({
                    message: res.message,
                    type: 'success'
                });
            } else {
                setAlertMessage({
                    message: 'Activate/Deactivate distribution list failed...!',
                    type: 'fail'
                });
            }
        });
    }

    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        setShowLoader(false);
        triggerList();
        setAlertMessage({
            message:msg, 
            type
        });
    };

    const handleDialogClose = () => {
        setAlertMessage(null);
    };

    const validateData = () => {
        let formIsValid = true;
        const err = Object.assign({}, errors);
        if (!formData.title) {
            formIsValid = false;
            err.title = 'Field is Required';
        }

        if (!formData.email) {
            formIsValid = false;
            err.title = 'Field is Required';
        } else if (!emailValidator(formData.email)) {
            formIsValid = false;
            err.email = "Please enter valid email address.";
        }

        setErrors(err);
        return formIsValid;
    };

    const handleSubmit = () => {
        const checkForm = validateData();
        if (!checkForm) {
            return;
        }
        const json: any = {
            title: formData.title,
            jSubCheckBox: subCheckBox,
            customerId
        }
        const postData: any = {};
        if (formData?.id) {
            postData.id = formData.id
        }
        Object.assign(postData, {
            customerId,
            channelId: formData.email.toLowerCase(),
            channelType: 'distribution_list',
            verificationToken: 'token',
            json
        });

        setShowLoader(true);
        if (postData.id) {
            dispatch(updateDistributionList(postData)).then((resp: any) => {
                setShowLoader(false);
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'Distribution list is updated');
                }
            });
        } else {
            dispatch(postCreateDistributionList(postData)).then((resp: any) => {
                setShowLoader(false);
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'Distribution list creation is successful');
                }
            });
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.title}>
                    {`${editData ? 'Edit' : 'Add'} Distribution List`}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        required
                        fullWidth
                        variant='outlined'
                        label='Title'
                        margin='dense'
                        name='title'
                        value={formData.title || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.title} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        disabled={editData ? true : false}
                        required
                        fullWidth
                        variant='outlined'
                        label='Email'
                        name='email'
                        value={formData.email.toLowerCase().trim() || ''}
                        onChange={handleChange}
                        margin='dense'
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.email} />
                </Grid>

                <Grid item container xs={12} direction='column' justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <MuiCheckbox
                                    name="maintenanceAlert"
                                    checked={subCheckBox.maintenanceAlert}
                                    onChange={handleCheckBoxChange}
                                />
                            }
                            label="Subscribe to Maintenance alerts"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <MuiCheckbox
                                    name="incidentAlert"
                                    checked={subCheckBox.incidentAlert}
                                    onChange={handleCheckBoxChange}
                                />
                            }
                            label="Subscribe to Incident alerts"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item sm={6} container justifyContent="flex-start">
                            {editData && editData.status === APP_STATUS.VERIFIED &&
                                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={()=>{setShowConfirmDialog(true)}}
                                >
                                    Deactivate Email
                                </Button>}
                            {editData && editData.status === APP_STATUS.INACTIVE &&
                                <Button
                                    color="primary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={()=>{setShowConfirmDialog(true)}}
                                >
                                    Activate Email
                                </Button>}
                        </Grid>
                        <Grid item container sm={6} justifyContent="flex-end">
                            <Button
                                variant='contained'
                                size='small'
                                className={`w3-margin-right ${classes.buttonLabel}`}
                                onClick={handleClose}
                            >Cancel
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                className={`${classes.buttonLabel}`}
                                onClick={handleSubmit}
                            >
                                {editData ? 'Save' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {alertMessage && <AlertDialog
                handleClose={() => {
                    handleDialogClose()
                    handleClose()
                    if(editData){
                        triggerList()
                    }
                }}
                type={alertMessage.type}
                message={alertMessage.message}
                handleDialogClose={() => handleDialogClose()}
            />}
            {showConfirmDialog && (
                <ConfirmDialog
                    handleCancel={() => setShowConfirmDialog(false)}
                    handleOk={handleDeactivate}
                    message={`Are you sure to ${editData.status === APP_STATUS.INACTIVE ? 'activate' : 'deactivate'} the email?`}
                />
            )}

            {showLoader && <Loader open={showLoader} />}
        </div>
    );
};

export default DistributionListModal;
