
import React, { useState, useEffect } from 'react';
import {
    Grid, Button, TextField,
    MenuItem, InputLabel, FormControl,
    Paper, Chip, Tooltip,
    IconButton, Radio, RadioGroup, FormControlLabel,
    Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    postCreateTopic, postUpdateTopic, getAllCustomers, getTopicById,
    getTopicReminderList, getAllTerms, postConfirmCustomers, setAlertDialogMessage, sendHeartbeat
} from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { createBrowserHistory } from 'history'

import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';
import Select from 'react-select';
import MuiSelect from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import DialogModal from '../Common/DialogModal';
import { MuiPickersUtilsProvider, DateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import ReminderArea from './ReminderArea';
import MuiSelectField from '../Common/MuiSelectField';
import { EMAIL_TEMPLATE, DEFAULT_WEEKS, DEFAULT_REMINDERS, TEMPLATE_PARAMS, ENVIRONMENT_TYPES, SCHEDULED_EVENT_STATUS, ALERTS_STATUS } from '../../utils/contants';
import SummaryRemainderArea from './SummaryRemainderArea';
import InfoIcon from '@material-ui/icons/Info';
import ViewTaggedCustomersModal from '../Customers/ViewTaggedCustomers';
import { emailValidator } from '../../utils';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import { NOTIFICATION_TYPES } from '../../utils/contants';
import HelperButton from '../Common/HelperButton';

declare global {
    interface Window {
        jQuery: any
    }
}

function pad(num: number) {
    return ("0" + num).slice(-2);
}
function secsToHM(secs: number) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}`;
}

const hmToSecc = (hm: string) => {
    const parts = hm.split(":");
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '10px',
        marginBottom: '10px',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center'
    },
    modalFooter: {
        textAlign: 'right'
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '15px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px'
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: "none"
    },
    button: {
        textTransform: "none",
        marginTop: "5px"
    },
    reactSelect: {
        zIndex: 5
    },
    tHead: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    tDef: {
        fontSize: 15,
    },
    tDefName: {
        fontSize: 15,
        '&:hover': {
            color: '#0085ad',
        },
    },
    input: {
        height: 31,
        position: 'relative',
        margin: theme.spacing(0.5),
    },
    emTable: {
        maxHeight: 275,
        overflowY: 'auto',

    },
    noPaddingModal: {
        paddingLeft: 0,
        paddingRight: 0,
    }
}));


const splitRegEx = new RegExp('[\n,]', 'ig')

let dirtyFlag = false;

const PatchWindowsForm = (props: any) => {
    const history = createBrowserHistory()
    const navigate = useNavigate()
    const { eventType, customerId, customer } = props;
    const { id } = useParams();
    const classes = useStyles();
    const [alertMessage, setAlertMessage] = useState<any>();
    const state: any = useSelector(st => st);
    const [errors, setErrors] = useState<any>({
        title: '',
        emailSubject: '',
        description: '',
        emailBody: '',
        scheduleType: '',
        status: '',
        eventTime: '',
        startDate: '',
        endDate: '',
        week: '',
        type: '',
        selectedCustomer: '',
        replyToEmail: '',
        customerType: '',
        selectedTags: '',
        whichWeek: '',
        duration: '',
        environment: ''
    });
    const unblock = history.block(() => {
        return window.confirm("You have unsaved changes, Do you want to leave?");
    });
    React.useEffect(() => {
        unblock();
        return () => {
            unblock();
        };
    }, []);


    const [emailList, setEmailList] = useState([]);
    const [formData, setFormData] = useState<any>({
        customerId: customerId ? customerId : '',
        id: '',
        title: '',
        emailSubject: '{{STATE}}: Maintenance Notification - {{ENV}}: {{NAME}}',
        description: '',
        emailBody: !customerId ? `${EMAIL_TEMPLATE}` : '',
        environment: 'Test',
        scheduleType: 'daily',
        duration: '01:00',
        status: 'Enabled',
        eventTime: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        week: [],
        type: 'patch_window',
        eventType: eventType === 'oneTimeEvent' ? 'oneTimeEvent' : 'scheduledEvent',
        customerType: eventType === 'oneTimeEvent' ? 'global_topic' : 'customers',
        selectedCustomer: '',
        selectedTags: '',
        replyToEmail: 'no-reply@sensus.xylem.com',
        dirty: false,
        whichWeek: '',
        brand:'xylem'
    });
    const dispatch: any = useDispatch();
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [msgType, setMsgType] = useState("success");
    const [showLoader, setShowLoader] = useState(false);
    const [weeks, setWeeks] = useState(DEFAULT_WEEKS);
    const [customerList, setCustomerList] = useState([]);
    const [reminderData, setReminderData] = useState(eventType === 'scheduledEvent' ? DEFAULT_REMINDERS : []);
    const [options, setOptions] = useState([]);
    const [addEmailText, setAddEmailText] = useState<any>()
    const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
    let tagOptions: any[] = [];
    const [customerData, setCustomerData] = useState<any>();
    const [trigger, setTrigger] = useState(false);
    const [openViewCustomers, setOpenViewCustomersModal] = useState(false);

    useEffect(() => {
        dispatch(sendHeartbeat())
    }, [formData, customerList, options,reminderData,weeks,])

    const setFormDataEx = (data: any) => {
        setFormData(Object.assign({},
            formData,
            data, {
            dirty: true
        }));
        dirtyFlag = true;
        const err = Object.assign({}, errors);
        Object.keys(data).forEach((key) => {
            if (err[key]) {
                err[key] = null;
            }
        })
        setErrors(err);
    }

    useEffect(() => {
        dispatch(getAllCustomers()).then((resp: any) => {
            const respData = resp && resp.data
            const customers = respData.filter((item: any) => item.status === 'Active');
            if (customers && customers.length) {
                const list = customers.map((item: any) => (
                    {
                        label: item.name,
                        value: item.id
                    }
                ));
                setCustomerList(list)
            }
        });

        dispatch(getAllTerms('tag')).then((termRes: any) => {
            if (termRes && termRes.status === 200) {
                const termData = termRes.data;
                setOptions(termData.map((item: any) => ({
                    id: item.id,
                    title: item.name,
                    metaId: item.metaId
                })))
            }
        }) 

        if (id) {
            setFormDataEx({
                id: '',
                title: 'loading...',
                type: '',
                eventType: '',
                customerType: '',
                description: 'loading...',
                status: '',
                environment: '',
                emailSubject: '',
                replyToEmail: '',
                scheduleType: '',
                duration: null,
                eventTime: new Date(),
                emailBody: '',
                emailList: [],
                selectedCustomer: [],
                selectedTags: [],
                whichWeek: '',
            });

            window.jQuery('#summernote').summernote('code', '');

            dispatch(getTopicById(parseInt(id))).then((resp: any) => {
                const respData = resp && resp.data
                if (!respData || !respData.success || !respData.data) {
                    dispatch(setAlertDialogMessage('Error while fetching data', 'fail', () => {
                        navigate(getUrl());
                    }))
                    return;
                }
                const { week, selectedCustomer, whichWeek,
                    selectedTags,
                    brand
                } = respData.data.json;
                const { data } = respData;
                // const emailList: any[] = [];
                const emailList: any[] = Array.isArray(data.summaryEmailList) ?
                    data.summaryEmailList.map((item: any) => Object.assign(item, {
                        email: item.email.toLowerCase()
                    })) : [];
                emailList.sort((a: any, b: any) => a.email.localeCompare(b.email));
                setEmailList(emailList);
                const topicObj = {
                    id: data.id,
                    title: data.title,
                    type: data.type,
                    eventType: data.eventType,
                    customerType: data.customerType,
                    description: data.description,
                    status: data.status,
                    environment: data.environment,
                    emailSubject: data.emailSubject,
                    replyToEmail: data.replyToEmail,
                    scheduleType: data.scheduleType,
                    duration: secsToHM(data.duration),
                    eventTime: data.eventTime,
                    emailBody: data.emailBody,
                    selectedCustomer,
                    selectedTags,
                    whichWeek,
                    brand
                }

                const editData = Object.assign({}, formData, topicObj, {
                    dirty: false
                });
                window.jQuery('#summernote').summernote('code', topicObj.emailBody);

                // prevData = JSON.stringify(editData); 
                setFormDataEx(editData);
                dirtyFlag = false;
                setWeeks(week);
            }).catch((ex: any) => {
                console.error('Error while loading items', ex);
            });
            dispatch(getTopicReminderList(parseInt(id))).then((res: any) => {
                if (res && res.status === 200) {
                    const respData = res.data;
                    setReminderData(respData.data);
                }
            });
        }
    }, [id, trigger]);

    const validateForm = () => {
        let formIsValid = true;
        let err = Object.assign({}, errors);
        if (!formData.title) {
            formIsValid = false;
            err.title = 'This field is required';
        }
        if (!formData.emailSubject) {
            formIsValid = false;
            err.emailSubject = 'This field is required';
        }

        if (!formData.emailBody) {
            formIsValid = false;
            err.emailBody = 'This field is required';
        }
        if (!emailValidator(formData.replyToEmail)) {
            formIsValid = false;
            err.replyToEmail = "Please enter valid email address.";
        }
        if (!formData.status) {
            formIsValid = false;
            err.status = 'This field is required';
        }
        if (!formData.environment) {
            formIsValid = false;
            err.status = 'This field is required';
        }
        if (!formData.type) {
            formIsValid = false;
            err.type = 'This field is required';
        }
        if (formData.customerType === 'customer_topic' && (!formData.selectedCustomer || formData.selectedCustomer.length <= 0)) {
            formIsValid = false;
            err.selectedCustomer = 'This field is required';
        }
        if (formData.customerType === 'tag_topic' && (!formData.selectedTags || formData.selectedTags.length <= 0)) {
            formIsValid = false;
            err.selectedTags = 'This field is required';
        }

        if (eventType !== 'oneTimeEvent') {

            if(formData.duration){
                if(!(Math.abs(hmToSecc(formData.duration)) >= 300)){
                    formIsValid = false;
                    err.duration = 'Duration should be greater than or equal to 5 Mins.';
                }
            }

            if (!formData.customerType) {
                formIsValid = false;
                err.customerType = 'This field is required';
            }

            if (!customerId) {
                if (formData.customerType === 'customers' && (!formData.selectedCustomer || formData.selectedCustomer.length <= 0)) {
                    formIsValid = false;
                    err.selectedCustomer = 'This field is required';
                }
                if (formData.customerType === 'tags' && (!formData.selectedTags || formData.selectedTags.length <= 0)) {
                    formIsValid = false;
                    err.selectedTags = 'This field is required';
                }
            }


            if (!formData.scheduleType) {
                formIsValid = false;
                err.scheduleType = 'This field is required';
            }
            if (!formData.scheduleType) {
                formIsValid = false;
                err.scheduleType = 'This field is required';
            }
            if (formData.scheduleType === "weekly" && !weeks.some(item => item.checked)) {
                formIsValid = false;
                err.week = 'This field is required';
            }
            if (formData.scheduleType === "fullWeek" && !weeks.some(item => item.checked)) {
                formIsValid = false;
                err.week = 'This field is required';
            }
            if (formData.scheduleType === "fullWeek" && !formData.whichWeek) {
                formIsValid = false;
                err.whichWeek = 'This field is required';
            }
        }
        setErrors(err);
        return {
            err,
            formIsValid
        };
    }

    useEffect(() => {
        window.jQuery('#summernote').summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['codeview', 'help']],
            ],
            callbacks: {
                onChange: function (contents, $editable) { 
                    dispatch(sendHeartbeat())
                }
            }
        });
    }, [1])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'customerType') {
            if (value === 'customers' || value === 'customer_topic') {
                formData.selectedTags = [];
            }
            if (value === 'tags' || value === 'tag_topic') {
                formData.selectedCustomer = [];
            }
        }
        setFormDataEx({
            [name]: value
        });
        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setErrors(err);
    }

    const handleChipClick = (selectData: any) => {
        const mWeeks = weeks.map((item, index) => {
            if (selectData.value === item.value) {
                item.checked = true;
            } else {
                item.checked = false;
            }
            return { ...item };
        })
        const err = Object.assign({}, errors);
        if (err['week']) {
            err['week'] = null;
        }
        setErrors(err);
        setWeeks(mWeeks);
    }



    const handleAddEmailText = (e: any) => {
        setInvalidEmailMsg("");
        setAddEmailText(e.target.value)
    }

    const handleEmailList = () => {
        setInvalidEmailMsg("")
        const errorList: any[] = [];
        const emailAddList = addEmailText.toLowerCase().split(splitRegEx)
            .map((item: string) => ({ email: item.trim() }));

        emailAddList.sort((a: any, b: any) => a.email.localeCompare(b.email));
        const newList: any[] = emailAddList.reduce((agg: any, item: any) => {
            if (item.email === '') {
                return agg;
            }
            if (!emailValidator(item.email)) {
                errorList.push(`${item.email} invalid`);
                return agg;
            }
            const exists = agg.find((el: any) => el.email == item.email);
            if (!exists) {
                agg.unshift(item);
            } else {
                errorList.push(`${item.email}`);
            }
            return agg;
        }, emailList.concat([]));

        if (errorList.length > 0) {
            setInvalidEmailMsg(`The following are invalid/duplicate emails:  ${errorList.join(', ')}`)
        }

        newList.sort((a: any, b: any) => a.email.localeCompare(b.email));
        setEmailList(newList);

        setFormDataEx({});
        setAddEmailText('')
    }

    const triggerDeleteByEmail = (e: any, email: string) => {
        const newData = emailList.filter(item => item.email != email);
        setEmailList(newData);
        setFormDataEx({})
    }

    const handleDateChange = (eventTime: any) => {
        setFormDataEx({
            eventTime
        });
    };

    const handleSelect = (selected: any) => {
        setFormDataEx({
            selectedCustomer: selected,
        });
    }

    const handleOptionOnChange = (event: any, newValue: any) => {
        const err = Object.assign({}, errors);
        if (err['selectedTags']) {
            err['selectedTags'] = null;
        }
        setFormDataEx({
            selectedTags: newValue,
        });
        setErrors(err);
    }

    const updateResponse = (resp: any, message: string, respType:string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dirtyFlag = false;
        setShowLoader(false);
        dispatch(setAlertDialogMessage(msg, type, () => {
            unblock();
            if(respType === 'create'){
                let eventUrl = 'alerts';
                if (eventType == 'scheduledEvent') {
                    eventUrl = 'events';
                }
                if (res && res.data && res.data.data) {
                    navigate(`/${eventUrl}/${res.data.data.id}/edit`);
                }else{
                    navigate(`/${eventUrl}`);
                }
            }
            document.location.reload();
        }));
    };

    const handleDialogClose = () => {
        setAlertMessage(null);
    };

    const handleSubmit = () => {
        const json = {};
        if (!customerId) {
            Object.assign(json, {
                selectedCustomer: formData.selectedCustomer,
                selectedTags: formData.selectedTags,
                brand: formData.brand,
            }) 
        }

        if (eventType !== 'oneTimeEvent') {
            Object.assign(json, {
                whichWeek: formData.whichWeek,
                week: weeks,
                topicReminders: reminderData
            });
        }
        formData.emailBody = window.jQuery('#summernote').summernote('code');

        const checkForm = validateForm();
        if(!checkForm.formIsValid){
            let errMsg = 'To proceed further, please fill the mandatory fields';
            Object.keys(checkForm.err).forEach((key:any) => {
                if(key === 'duration' && checkForm.err['duration']){
                    errMsg = 'Duration should be greater than or equal to 5 Mins.';
                }
            });
            dispatch(setAlertDialogMessage(errMsg, 'fail'));
            return;
        }
        if (checkForm.formIsValid) {
            Object.assign(formData, {
                summaryEmailList: emailList,
                // eventTime:moment(formData.eventTime
                json,
            });

            const data = Object.assign({}, formData, {
                duration: Math.abs(hmToSecc(formData.duration))
            });

            setShowLoader(true);
            if (data.id) {
                postUpdateTopic(data).then((resp: any) => {
                    updateResponse(resp, `Updated successfully`, 'edit');
                }).catch((err: any) => setShowLoader(false));
            } else {
                postCreateTopic(data).then((resp: any) => {
                    updateResponse(resp, `Created successfully`, 'create');
                }).catch((err: any) => setShowLoader(false));
            }
        }
    };

    const getTaggedCustomers = () => {
        dispatch(postConfirmCustomers({ selectedTags: formData.selectedTags })).then((resp: any) => {
            if (resp.status === 200 || resp.status === 201) {
                const customers = resp.data.data;
                customers.sort((a: any, b: any) => a.customerName.toLowerCase().localeCompare(b.customerName.toLowerCase()));
                setCustomerData(customers);
                setOpenViewCustomersModal(true);
            } else if (resp.status === 400) {
                setAlertMessage('Notifications sending failed');
            }
        });
    }

    const getUrl = () => {
        if (customerId) {
            return `/customer/${customerId}/alerts`;
        }
        if (eventType == 'scheduledEvent') {
            return '/events'
        }
        return '/alerts';
    }

    if (options && options.length > 0) {
        tagOptions = options;
    }

    if (formData.selectedTags && formData.selectedTags.length > 0) {
        tagOptions = options.filter((optionItem: any) => !formData.selectedTags.find((selectedTag: any) => selectedTag.id === optionItem.id))
    }
    const disableForChild = customerId != undefined;

    let format = "'every day at'  hh:mm a";
    if (formData.scheduleType == 'monthly') {
        format = "do 'of every month'  hh:mm a";
    } else if (formData.scheduleType == 'daily') {
        format = "'every day at'  hh:mm a";
    } else if (formData.scheduleType == 'fullWeek') {
        format = "'at'  hh:mm a";
    } else if (formData.scheduleType == 'weekly') {
        format = "'at' hh:mm a 'on selected day'";
    }

    return (
        <>
            {customer && <CustomerBreadcrumbs customer={customer} >
                <Link to={`/customer/${customerId}/alerts`}>Alerts</Link>
                <Typography color="textPrimary">{formData && formData.title}</Typography>
            </CustomerBreadcrumbs>
            }
            <h4> {id ? 'Edit' : 'Create'}  {eventType == 'oneTimeEvent' ? 'Alert' : 'Maintenance Window'}</h4>
            <Paper elevation={1} style={{ padding: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="title"
                                name="title"
                                margin="dense"
                                disabled={disableForChild}
                                label={eventType == 'oneTimeEvent' ? 'Alert Name' : 'Maintenance Window Name'}
                                value={formData.title || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                                required
                            />
                            <ErrorHelperText error={errors.title} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="emailSubject"
                                name="emailSubject"
                                margin="dense"
                                label="Email Subject"
                                value={formData.emailSubject || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                                required
                            />
                            <ErrorHelperText error={errors.emailSubject} />
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop10}>
                            <div id="summernote">
                                <div dangerouslySetInnerHTML={{ __html: formData.emailBody }}></div>
                            </div>
                            {TEMPLATE_PARAMS && TEMPLATE_PARAMS.length > 0 && <Grid container>
                                <Grid item xs={12}>
                                    <p> You can use template parameters</p>
                                    {TEMPLATE_PARAMS.join(', ')}
                                </Grid>
                            </Grid>}
                            <ErrorHelperText error={errors.emailBody} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                disabled={disableForChild}
                                id="description"
                                name="description"
                                margin="dense"
                                label="Description"
                                value={formData.description || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                            />
                            <ErrorHelperText error={errors.description} />
                        </div>

                        {!customerId && <div>
                            <FormControl component="fieldset">
                                <RadioGroup row name={'customerType'} value={formData.customerType} onChange={(e) => handleChange(e)}>
                                    {eventType === 'oneTimeEvent' ? <FormControlLabel value="global_topic" control={<Radio color="primary" />} label="Global Alert" /> : null}
                                    <FormControlLabel value={eventType !== 'oneTimeEvent' ? 'customers' : 'customer_topic'}
                                        control={<Radio color="primary" />}
                                        label={eventType === "oneTimeEvent" ? "Customer Alert" : "for Customers"} />
                                    <FormControlLabel value={eventType !== 'oneTimeEvent' ? 'tags' : 'tag_topic'}
                                        control={<Radio color="primary" />}
                                        label={eventType === "oneTimeEvent" ? "Tag Alert" : "for Tags"} />
                                    {(eventType === 'scheduledEvent' && formData.selectedTags && formData.selectedTags.length > 0) || (formData.customerType == 'tag_topic' && formData.selectedTags.length > 0) ?
                                        <Tooltip title="View tagged customers">
                                            <IconButton aria-label="customers" onClick={getTaggedCustomers} >
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                        : ''}
                                    <HelperButton trigger="customer_tag_radio_btn" />
                                </RadioGroup>
                            </FormControl>
                            <ErrorHelperText error={errors.customerType} />
                        </div>}
                        {!customerId && <div>
                            {((formData.customerType === 'customer_topic')
                                || (formData.customerType === 'customers')) &&
                                <>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={`${classes.formControl} ${classes.reactSelect}`}>
                                        <Select
                                            id="select-customer"
                                            isMulti
                                            options={customerList}
                                            label="Select Customers"
                                            placeholder="Select Customers*"
                                            value={formData.selectedCustomer || ''}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                    </FormControl>
                                    <ErrorHelperText error={errors.selectedCustomer} />
                                </>}
                            {((formData.customerType === 'tag_topic')
                                || (formData.customerType === 'tags')) &&
                                <>
                                    <MuiSelectField
                                        margin="dense"
                                        size="small"
                                        placeholder="Select Tags"
                                        label="Select Tags"
                                        limitTags={5}
                                        value={formData.selectedTags || []}
                                        options={tagOptions}
                                        onChange={handleOptionOnChange}
                                    />
                                    <ErrorHelperText error={errors.selectedTags} />
                                </>}
                        </div>}
                        <Grid container spacing={2}>
                            <Grid item container spacing={1} xs={12} md={6}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                            Type*
                                        </InputLabel>
                                        <MuiSelect
                                            labelId="roleLabel"
                                            id="type"
                                            name="type"
                                            disabled={disableForChild}
                                            value={formData.type || ''}
                                            onChange={handleChange}
                                            fullWidth  >
                                            {NOTIFICATION_TYPES.map((item: any) => {
                                                return <MenuItem value={item.value}>{item.title}</MenuItem>
                                            })}
                                        </MuiSelect>
                                    </FormControl>
                                    <ErrorHelperText error={errors.type} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        id="replyToEmail"
                                        name="replyToEmail"
                                        margin="dense"
                                        disabled={disableForChild}
                                        label="Reply to email id"
                                        value={formData.replyToEmail || ''}
                                        onChange={(e) => handleChange(e)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.label
                                            }
                                        }}
                                        required
                                    />
                                    <ErrorHelperText error={errors.replyToEmail} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                            Environment*
                                        </InputLabel>
                                        <MuiSelect
                                            labelId="roleLabel"
                                            id="environment"
                                            name="environment"
                                            disabled={disableForChild}
                                            value={formData.environment || ''}
                                            onChange={(e) => handleChange(e)}
                                            fullWidth
                                        >
                                            {ENVIRONMENT_TYPES.map(item => <MenuItem value={item.value}>{item.title}</MenuItem>)}
                                        </MuiSelect>
                                    </FormControl>
                                    <ErrorHelperText error={errors.environment} />
                                </Grid>
                                {!customerId && <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                            Status*
                                        </InputLabel>
                                        <MuiSelect
                                            labelId="roleLabel"
                                            id="status"
                                            name="status"
                                            disabled={disableForChild}
                                            value={formData.status || ''}
                                            onChange={(e) => handleChange(e)}
                                            fullWidth
                                        >
                                            {
                                            eventType == 'scheduledEvent' ?
                                                Object.entries(SCHEDULED_EVENT_STATUS).map(([objkey, objValue]) => <MenuItem key={`se-${objkey}`} value={objValue}>{objValue}</MenuItem>)
                                                :
                                                Object.entries(ALERTS_STATUS).map(([objkey, objValue]) => <MenuItem key={`a-${objkey}`} value={objValue}>{objValue}</MenuItem>)
                                            }
                                        </MuiSelect>
                                    </FormControl>
                                    <ErrorHelperText error={errors.status} />
                                </Grid>}
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                            Brand
                                        </InputLabel>
                                        <MuiSelect
                                            labelId="roleLabel"
                                            id="brand"
                                            name="brand"
                                            value={formData.brand || ''}
                                            onChange={(e) => handleChange(e)}
                                            fullWidth
                                            variant='outlined'
                                        >
                                            <MenuItem value="xylem">Xylem</MenuItem>
                                            <MenuItem value="xylem_sensus">Sensus</MenuItem>
                                        </MuiSelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {(!customerId && eventType === 'scheduledEvent') && (<Grid container spacing={1} direction="row" alignContent="flex-start" item xs={12} md={6} >

                                <Grid item xs={7} sm={7}>
                                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                            Schedule*
                                        </InputLabel>
                                        <MuiSelect
                                            labelId="roleLabel"
                                            id="scheduleType"
                                            name="scheduleType"
                                            value={formData.scheduleType || ''}
                                            onChange={(e) => handleChange(e)}
                                            fullWidth
                                        >
                                            <MenuItem value="onetime">One Time</MenuItem>
                                            <MenuItem value="daily">Daily</MenuItem>
                                            <MenuItem value="weekly">Weekly</MenuItem>
                                            <MenuItem value="monthly">Monthly</MenuItem>
                                            <MenuItem value="fullWeek">Monthly, by week</MenuItem>
                                        </MuiSelect>
                                    </FormControl>
                                    <ErrorHelperText error={errors.scheduleType} />
                                </Grid>
                                <Grid item xs={5} sm={5}>
                                    <TextField fullWidth
                                        id="duration"
                                        name="duration"
                                        margin="dense"
                                        placeholder="hh:mm"
                                        disabled={disableForChild}
                                        label="Duration"
                                        value={formData.duration || ''}
                                        onChange={(e) => handleChange(e)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.label
                                            }
                                        }}
                                        required
                                    />
                                    <ErrorHelperText error={errors.duration} />
                                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        label="Masked timepicker"
                                        placeholder="08:00 AM"
                                        mask="__:__"
                                        ampm={false}
                                        value={formData.duration}
                                        onChange={date => handleDateChange(date)}
                                    />
                                    </MuiPickersUtilsProvider>
                                    <ErrorHelperText error={errors.replyToEmail} /> */}
                                </Grid>
                                <Grid item xs={formData.scheduleType === 'fullWeek' ? 7 : 12}  >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        {!(formData.scheduleType === 'onetime' || formData.scheduleType === 'monthly') ? <KeyboardTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            className="picker-text-class"
                                            label="Event Time"
                                            value={formData.eventTime || ''}
                                            onChange={handleDateChange}
                                            format={format}
                                            invalidDateMessage='Invalid Time, Try Changing Time Using Picker'
                                            inputVariant="outlined"
                                            fullWidth

                                        /> :
                                            <DateTimePicker
                                                margin="normal"
                                                className="picker-text-class"
                                                id="time-picker"
                                                label="Event Date Time"
                                                fullWidth
                                                inputVariant="outlined"
                                                format={format}
                                                value={formData.eventTime || null}
                                                onChange={handleDateChange}




                                            />}
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {formData.scheduleType === 'fullWeek' &&
                                    <Grid item md={5} xs={5}>
                                        <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                            <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                                Week*
                                            </InputLabel>
                                            <MuiSelect
                                                labelId="roleLabel"
                                                id="whichWeek"
                                                name="whichWeek"
                                                value={formData.whichWeek || ''}
                                                onChange={(e) => handleChange(e)}
                                                fullWidth
                                            >
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                                <MenuItem value="4">4</MenuItem>
                                            </MuiSelect>
                                        </FormControl>
                                        <ErrorHelperText error={errors.whichWeek} />
                                    </Grid>
                                }
                                {((formData.scheduleType === 'weekly') || (formData.scheduleType === 'fullWeek')) &&
                                    <div className={classes.root}>
                                        {weeks.map((item) => (
                                            <Chip
                                                key={item.value}
                                                label={item.label}
                                                className={`${item.checked ? "w3-blue" : "w3-grey"}`}
                                                onClick={(e) => handleChipClick(item)}
                                            />
                                        ))
                                        }
                                        <ErrorHelperText error={errors.week} />
                                    </div>}
                                <Grid container justifyContent="flex-end">
                                    <HelperButton trigger="schedule_helper" />
                                </Grid>
                            </Grid>)}
                            
                        </Grid>
                    </Grid>
                    {alertMessage && (<AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                            trigger ? setTrigger(false) : navigate(getUrl())
                        }}
                        message={alertMessage}
                        type={msgType}
                        handleDialogClose={handleDialogClose}
                    />)}
                    {showLoader ? <Loader open={showLoader} /> : null}
                </Grid>
            </Paper>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                {(eventType === 'scheduledEvent' && !disableForChild) && <Grid item xs={12} md={6}>
                    <ReminderArea
                        reminderData={reminderData}
                        eventType={eventType}
                        classes={classes}
                        topicId={id}
                        setAlertMessage={setAlertMessage}
                        setMsgType={setMsgType}
                        handleSetReminderData={(someData: any) => {
                            setReminderData(someData)
                        }}
                        handleTrigger={(someData: any) => {
                            setTrigger(someData)
                        }}
                    />
                </Grid>}
                <Grid item xs={12} md={6}>
                    {(!disableForChild) &&
                        <SummaryRemainderArea
                            value={addEmailText || ''}
                            onChange={(e: any) => handleAddEmailText(e)}
                            onAdd={handleEmailList}
                            onDelete={triggerDeleteByEmail}
                            tableData={emailList || ''}
                            handleClose={() => {
                                setInvalidEmailMsg("")
                            }}
                            errorMsg={invalidEmailMsg}
                            classes={classes}
                        />
                    }
                </Grid>

            </Grid>
            <Grid item xs={12} className={classes.modalFooter}>
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            className={classes.buttonLabel}
                            onClick={e => navigate(getUrl())}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={`${classes.buttonLabel}`}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                openViewCustomers && (<DialogModal
                    fullWidth
                    isOpen={openViewCustomers}
                    hideTitle={true}
                    hideBtns={true}
                    maxWidth="md"
                    contentStyle={classes.noPaddingModal}
                    dialogContent={(
                        <ViewTaggedCustomersModal
                            customerData={customerData}
                            handleClose={() => {
                                setOpenViewCustomersModal(false);
                            }}
                        />
                    )}
                />)
            }
        </>
    );
}

export default PatchWindowsForm;