import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Checkbox } from '@material-ui/core';

function TableUI(props: any) {
    const { stickyHeader, classes, tableContent, tableColumns, scrollable, borderTop, rowCount, numSelected, onSelectAllClick, selectAll } = props
    return (
        <TableContainer className={` ${scrollable ? classes.emTable : ''} action-table`} component={Paper} elevation={0}>
            <Table className={borderTop ? `w3-border-top` : null} size="medium" stickyHeader={stickyHeader} >
                {tableColumns && <TableHead>
                    <TableRow>
                        {selectAll && <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>}
                        {tableColumns.map((col: any, idx: number) => {
                            return (
                                <TableCell key={`row_${idx}`} style={{ backgroundColor: 'white' }}
                                    className={classes.tHead}
                                    align={"left"}>{col == 'Action' ? '' : col}</TableCell>
                            )
                        })
                        }
                    </TableRow>
                </TableHead>}
                <TableBody>
                    {tableContent}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableUI