import React, { useEffect, useState, useContext } from 'react';
import './App.scss';
import LayoutRouter from './Router/LayoutRouter';
import AppRouter from './Router/AppRouter';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserRoles } from './Redux/actions';
import { CircularProgress } from '@material-ui/core';
import { AbilityContext, updateAbility } from './Context/Can';

const App: React.FC = () => {
	const dispatch: any = useDispatch();
	const state: any = useSelector(st => st);
	const { currentUser, currentUserRoles } = state;
	const [user, setUser] = useState(null);
	const ability = useContext(AbilityContext);

	useEffect(() => {
		dispatch(getCurrentUser()).then((resp: any) => {
			const res = resp && resp.data;
			if (res && res.success && res.data) {
				dispatch(getCurrentUserRoles());
				setUser(res.data);
			}
		});
	}, [dispatch]);

	useEffect(() => {
		if (!currentUserRoles || !currentUserRoles.data || !currentUserRoles.data.data) {
			return;
		}
		updateAbility(currentUserRoles.data.data, ability);
	}, [currentUserRoles]) 
	if (!currentUser || currentUser.isFetching) {
		return (
			<div className="textMarginCenter">
				<CircularProgress />
			</div>
		)
	}
	if (currentUser && localStorage.getItem('access_token')) {
		if (!currentUserRoles || currentUserRoles.isFetching) {
			return <div className="textMarginCenter">
				<CircularProgress />
			</div>
		}
		return <AppRouter currentUser={currentUser} roles={currentUserRoles.data.data} />
	} else {
		return <LayoutRouter />
	}
}

export default App;
