export const APP_STATUS = {
    ACTIVE: 'Active',
    JOINED: 'Joined',
    INVITED: 'Invited',
    INACTIVE: 'Inactive',
    EXPIRED: 'Expired',
    VERIFIED: 'Verified',
    INVITE_PENDING: 'Invite-Pending',
    INVITE_REJECTED: 'Invite-Rejected',
}

export const EMAIL_TEMPLATE_1 = `<p><strong style="color: rgb(0, 138, 0);">Affected Services</strong></p><p><br></p><ul><li>Mention Services</li></ul><p><br></p><p><strong style="color: rgb(0, 138, 0);">Reason</strong></p><p><br></p><ul><li>Mention Reason Here</li></ul><p><br></p><p><strong style="color: rgb(0, 138, 0);">Risk</strong></p><p><br></p><ul><li>[<span style="background-color: limegreen;"> LOW </span>]: Mention risks</li></ul><p><br></p><p><strong style="color: rgb(0, 138, 0);">Impact</strong></p><p><br></p><ul><li>[<span style="background-color: limegreen;"> LOW </span>]: Mention Impacts</li></ul><p><br></p><p><strong style="color: rgb(0, 138, 0);">Downtime</strong></p><p><br></p><ul><li>Outages for individual servers during the window as reboots and service restarts are required.</li></ul><p><br></p><p><strong style="color: rgb(0, 138, 0);">Datacenter Contact Information</strong></p><p><br></p><p class="ql-indent-1">You may contact our datacenter using one of the following methods below:</p><ol><li class="ql-indent-1">Call our Network Operations Center (NOC) at 1-833-677-8776 option 1.</li><li class="ql-indent-1">Email our operations email address which is monitored 24/7 <a href="mailto:datacenteroperation@xylemm" rel="noopener noreferrer" target="_blank">datacenteroperation@xylemm</a></li></ol><p><br></p><p class="ql-indent-1">You may reply to this email, but please make sure that the recipient is listed as <a href="mailto:datacenteroperation@xylem.com" rel="noopener noreferrer" target="_blank">datacenteroperation@xylem.com</a> and NOT the no-reply email address. We include an email header named "reply-to" in this email when we send it, and this should ensure that it is set correctly when replying but please double-check.</p><p><br></p><p><br></p>`;



export const EMAIL_TEMPLATE = `
<style>
     ul {
        font-family: Helvetica, Ariel;
        font-size: 12pt;
        color: #000;
     }
     .title {
        font-family: Helvetica, Ariel;
        font-size: 13pt;
        color: #0085ad;
     }
    .low {background-color:limegreen}
    .medium {background-color:yellow}
    .high {background-color:red;color:white}
</style>
<table style="width:760px">
    <tbody>  
    <tr>
        <td style="padding: 20px">
            <p class="title">Affected Services
            </p><ul>
                <li>Enter Services Here </li> 
            </ul>
            <p class="title"> Reason</p>
            <ul>
                <li> Enter Reason Here </li>
            </ul>
            <p class="title"> Risk</p>
            <ul>
                <li> Enter severity Here </li>
            </ul> 
            <p class="title"> Impact</p>
            <ul>
                <li>  Enter impact Here  </li>
            </ul> 
            <p class="title"> Downtime</p>
            <ul>
                <li> Enter Time information Here</li>
            </ul> 
            <p class="title"> Data Center Contact Information</p>
            <ul>
                <li>You may contact our data center using one of the following methods below:
                    <br>
                   <ol>
                      <li>Call our Network Operations Center (NOC) at 1-833-677-8776 option 1.</li>
                      <li>Email our operations email address which is monitored 24/7 <a href="mailto:datacenteroperation@xylem.com">datacenteroperation@xylem.com</a></li>.  You may reply to this email, but please make sure that the recipient is listed as <a href="mailto:datacenteroperation@xylem.com">datacenteroperation@xylem.com</a> and NOT the no-reply email address.  We include an email header named "reply-to" in this email when we send it, and this should ensure that it is set correctly when replying but please double-check.
                   </ol>
                </li>
            </ul>
        </td>
    </tr>
    </tbody>
</table>
`

export const DEFAULT_WEEKS = [
    { label: 'M', value: 'monday', checked: false },
    { label: 'T', value: 'tuesday', checked: false },
    { label: 'W', value: 'wednesday', checked: false },
    { label: 'T', value: 'thursday', checked: false },
    { label: 'F', value: 'friday', checked: false },
    { label: 'S', value: 'saturday', checked: false },
    { label: 'S', value: 'sunday', checked: false }
]

export const DEFAULT_REMINDERS = [
    { offset: "1", duration: "hours", type: "Before", description: "1 Hour Remaining", json: { status: "Enabled" } },
    { offset: "1", duration: "days", type: "Before", description: "1 Day Remaining", json: { status: "Enabled" } },
    { offset: "1", duration: "weeks", type: "Before", description: "1 Week Remaining", json: { status: "Enabled" } },
];

export const TEMPLATE_PARAMS = [
    '{{CUSTOMER_NAME}}',
    '{{CUSTOMER_CODE}}',
    `{{STATE}}`,
    `{{ENV}}`,
    `{{NAME}}`,
    '{{EVENT_TIME}}'
];


export const NOTIFICATION_TYPES = [
    { value: "patch_window", title: "Patch Window" },
    { value: "maintenance_window", title: "Maintenance Window" },
    { value: "periodic_updates", title: "Periodic Updates" },
    { value: "scheduled_events", title: "Scheduled Events" },
    { value: "other", title: "Other" }
];



export const ENVIRONMENT_TYPES = [
    {
        title: 'Test',
        value: 'Test'
    },
    {
        title: 'Production',
        value: 'Production'
    },
    {
        title: 'Development',
        value: 'Development'
    }
];

export const USER_ROLE_MAP ={
    XylemAdmin:'Xylem Admin',
    XylemCustomerAdmin:'Xylem Customer Admin',
    XylemPatchAdmin:'Xylem Patch Admin',
    XylemServiceAccountUser:'Xylem Service User',
    CustomerAdmin:'Customer Admin',
    TeamLead:'Team Lead',
    TeamMember: 'Team Member',
    'Primary POC': 'Primary POC',
    'Secondary POC': 'Secondary POC',
};

export const SUBJECTS = {
    ADD_CUSTOMERS: 'AddCustomers',
    ADMIN_DASHBOARD: 'AdminDashboard',
    ADMIN_SETTINGS: 'AdminSettings',
    ALERTS: 'Alerts',
    ALL_CUSTOMERS: 'AllCustomers',
    BREADCRUMB: 'BreadCrumb',
    CHANNEL_SETTINGS: 'ChannelSettings',
    CUSTOMER_USERS: 'CustomerUsers',
    DASHBOARD: 'Dashboard',
    DISTRIBUTION_LIST: 'DistributionList',
    EDIT_CUSTOMERS: 'EditCustomers',
    EDIT_PATCH_INSTANCE: 'EditPatchInstance',
    EDIT_SPECIFIC_CUSTOMER_FIELDS: 'EditSpecificCustomerFileds',
    LOAD_CSV: 'Load CSV',
    MANAGE_PATCH_WINDOW: 'ManagePatchWindow',
    MANAGE_TOPIC: 'ManageTopic',
    MEMBER: 'Member',
    PATCH_WINDOWS: 'PatchWindows',
    SETTINGS: 'Settings',
    SUBSCRIPTION_BUTTON: 'SubscriptionButton',
    SUBSCRIPTIONS: 'Subscriptions',
    TEAM: 'Team',
    TEAMS: 'Teams',
    TOPICS: 'Topics',
    USER_CHANNELS: 'UserChannels',
    USER_CHANNEL_HIDDEN: 'UserChannelHidden',
    VIEW_TOPIC: 'ViewTopic',
    WEEKLY_REMINDERS: 'WeeklyReminders',
    XYLEM_USERS: 'XylemUsers',
};

export const SCHEDULED_EVENT_STATUS = {
    ENABLED:'Enabled',
    PAUSED: 'Paused',
    DISABLED: 'Disabled',
}

export const ALERTS_STATUS = {
    ENABLED:'Enabled',
    DISABLED: 'Disabled',
}

export const SCHEDULED_EVENT_STATUS_COLOR ={
    [SCHEDULED_EVENT_STATUS.ENABLED]: 'w3-green',
    [SCHEDULED_EVENT_STATUS.DISABLED]: 'w3-red',
    [SCHEDULED_EVENT_STATUS.PAUSED]: 'w3-teal',
}