import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStats, getRoles, getTopicCount } from '../../Redux/actions';
import {
  ResponsiveContainer,
  Legend,
  PieChart, Pie, Cell, Tooltip,
  Bar, BarChart, XAxis, YAxis
} from 'recharts';
import { userRoles } from '../../Context/userRoles';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const width = 250;
const height = 320;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '25px',

  },
  title: {
    marginTop: '15px',
    fontWeight: 600,
    textAlign: 'center'
  },
  noteCardContent: {
  },
  marginTop8: {
    marginTop: '8px'
  },
  customToolTip: {
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 8px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '16px'
  }
}));

const AdminDashboard = (props: any) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(state => state);
  const { dashboardStats, getRole } = state;
  const [dashBoardData, setDashBoardData] = React.useState<any>();
  const [allTopicCount, setAllTopicCount] = useState<any>();
  const [role, setRole] = React.useState<any>();

  useEffect(() => {
    dispatch(getDashboardStats()).then((resp: any) => {
      setDashBoardData(resp.data);
    });
    dispatch(getRoles()).then((resp: any) => {
      setRole(resp.data.data);
    });

    dispatch(getTopicCount()).then((resp: any) => {
      setAllTopicCount(resp.data.data);
    })

  }, [dispatch]);

  if (!dashboardStats || dashboardStats.isFetching || !getRole || getRole.isFetching) {
    return (
      <div className="textMarginCenter">
        <CircularProgress />
      </div>);
  }

  let xa = 0;
  let xca = 0;
  let xpa = 0;
  let xsac = 0;

  let poc = 0;
  let opoc = 0;
  let ca = 0;

  let list: any[] = [];
  if (dashBoardData) {
    if (dashBoardData && dashBoardData.customers && dashBoardData.customers.length > 0) {
      list = dashBoardData.customers.map((item: any) => {
        if (item && item.customerUsers) {
          const pocUsers = item.customerUsers.filter((item: any) => item.type === userRoles.PRIMARY_POC);
          poc = pocUsers && pocUsers.length ? pocUsers.length : 0
          const opocUsers = item.customerUsers.filter((item: any) => item.type === userRoles.SECONDARY_POC);
          opoc = opocUsers && opocUsers.length ? opocUsers.length : 0
          const caUsers = item.customerUsers.filter((item: any) => item.type === userRoles.CUSTOMER_ADMIN);
          ca = caUsers && caUsers.length ? caUsers.length : 0
        }
        return {
          name: item.customerCode,
          total: item.customerUsers_count,
          poc,
          ca,
          opoc,
        }
      })
    }
  }

  let topicList: any[] = [];
  if (allTopicCount && allTopicCount.length > 0) {
    topicList = allTopicCount
  }



  if (role) {
    let item = role.filter((i: any) => i.role.name === 'XylemAdmin');
    xa = item.length ? item.length : 0;
    item = role.filter((i: any) => i.role.name === 'XylemCustomerAdmin');
    xca = item.length ? item.length : 0;
    item = role.filter((i: any) => i.role.name === 'XylemPatchAdmin');
    xpa = item.length ? item.length : 0;
    item = role.filter((i: any) => i.role.name === 'XylemServiceAccountUser');
    xsac = item.length ? item.length : 0;
  }

  const tData = [
    { name: 'XylemAdmin', value: xa },
    { name: 'XylemCustomerAdmin', value: xca },
    { name: 'XylemPatchAdmin', value: xpa },
    { name: 'XylemServiceAccountUser', value: xsac },
  ];


  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <div className={classes.customToolTip}>
          <p>{`${payload[0].payload.name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <Card className="w3-padding-32">
          <h4 className={classes.title}>Alert Stats</h4>
          {(allTopicCount && allTopicCount.length > 0) ?
            <ResponsiveContainer width={290} height={282} className="falcon-margin">
              <BarChart width={600} height={300} data={topicList}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Patch Windows" fill="#8884d8" />
                <Bar dataKey="Topics" fill="#82ca9d" />

              </BarChart>
            </ResponsiveContainer>
            : <div className="w3-center">
              No Alerts Found
              </div>
          }
        </Card>
      </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="w3-padding-32">
            <h4 className={classes.title}>Admins</h4>
            <ResponsiveContainer width={width} height={height} className="falcon-margin-auto">
              <PieChart>
                <Pie
                  data={tData}
                  cx={120}
                  cy={120}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {
                    tData.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                  }
                </Pie>
                <Legend align="left" />
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Grid>
      </Grid>

   
    </div>
  );
};
export default AdminDashboard;
