import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress, Table, CardContent, Card, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Chip, Box, CardActions, Tooltip, IconButton, Grid
} from '@material-ui/core';
import { getChannelList, destroyChannel } from '../../Redux/actions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from '../Common/AlertDialog';
import ConfirmDialog from '../Common/ConfirmDialog';
import TableUI from '../Common/TableUI';
import CheckIcon from '@material-ui/icons/Check';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Axios from 'axios';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS } from '../../utils/contants';

const useStyles = makeStyles((theme: Theme) => ({
  marginTop10: {
    marginTop: '10px'
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  emTable: {
    maxHeight: 275,
    overflowY: 'auto',
  },
}));

const SubscriptionChannelListing = (props: any) => {
  const { trigger } = props
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser } = state;
  const initialData: any[] = [];
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [confirmConfig, showConfirmDialog] = useState<any>(null);
  const [msgType, setMsgType] = useState<any>();
  const tableColumns = ['Channel', 'Status', 'Action']
  let manageSubscriptions: any = null;
  const limit = 11;

  const fetchData = () => {
    dispatch(getChannelList())
      .then((resp: any) => {
        if (resp && resp.status === 200) {
          setData(resp.data.data);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, trigger]);

  const handleDeleteSubscription = (sub: any) => {
    showConfirmDialog(null);
    destroyChannel(sub.id, sub.status)
      .then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage({
            message: res.message,
            type: 'success'
          });
          fetchData();
        } else if (res && res.success == false) {
          setAlertMessage({
            message: 'Cannot deactivate invited user',
            type: 'fail'
          })
        } else {
          setAlertMessage({
            message: 'Subscription Invite deactivation failed...!',
            type: 'fail'
          });
        }
      });

  };
  const triggerMakeDefault = (item: any) => { 
    showConfirmDialog({
      message: `Are you sure you want to make "${item.channelId}" your default channel?. You will be using this for future logins.`,
      confirm: () => {
        Axios.post(`/api/v1/notification-channel/make-default/${item.id}`, item).then(res => { 
          document.location.reload();
        }).catch(err => {

        })
      }
    })
  }
  const triggerDelete = (subscription: any) => {
    showConfirmDialog({
      message: `Are you sure to ${subscription.status == APP_STATUS.INACTIVE ? 'activate' : 'deactivate'} the channel?`,
      confirm: () => handleDeleteSubscription(subscription)
    })
  }

  const handleDialogClose = () => {
    setAlertMessage(null);
  };

  if (isLoading) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageSubscriptions = data.sort((a, b) => a.channelId.localeCompare(b.channelId)).map((item: any) => {
      return (
        <TableRow key={item.name}>
          <TableCell className={classes.tDef}>{item.channelId}</TableCell>
          <TableCell className={classes.tDef}>
            <Chip
              size="small"
              className={item.status === 'Verified' ? 'w3-green' : item.status === 'Invited' ? 'w3-yellow' : 'w3-red'}
              label={item.status}
            />
            {(item.channelId === currentUser.data.data.uniqueId) && <Chip
              size="small"
              className="w3-margin-left w3-yellow "
              label={'Default Channel'}
            />}
          </TableCell>
          <TableCell className={`${classes.tDef} ${classes.actionCell}`} >
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-end">
              {item && item.status !== APP_STATUS.INACTIVE ?
                <>
                  {(item.channelId !== currentUser.data.data.uniqueId && item.channelType == 'email' && item.status === APP_STATUS.VERIFIED) && <Tooltip title="Make Default Email">
                    <IconButton aria-label="make default" onClick={() => triggerMakeDefault(item)}>
                      <AlternateEmailIcon className="cursor-pointer" />
                    </IconButton>
                  </Tooltip>}

                  {(item.channelId !== currentUser.data.data.uniqueId) && <Tooltip title="Deactivate">
                    <IconButton aria-label="delete" onClick={() => triggerDelete(item)}>
                      <DeleteIcon className="cursor-pointer" />
                    </IconButton>
                  </Tooltip>}
                </> :
                <Tooltip title="Activate">
                  <IconButton aria-label="delete" onClick={() => triggerDelete(item)}>
                    <CheckIcon className="cursor-pointer" />
                  </IconButton>
                </Tooltip>
              }
            </Box>
          </TableCell>
        </TableRow>
      )
    });
  } else if (data && data.length === 0) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5> No Subscription Channels Found </h5>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item xs={12} style={{ paddingBottom: '20px' }}>
      <Card>
        <CardContent className="w3-border-bottom">
          <Grid container justifyContent="space-between">
            <h5>Subscription Channels</h5>
            <HelperButton trigger="subscription_channel_helper" />
          </Grid>
        </CardContent>
        <Grid item xs={12} >
          <TableUI
            stickyHeader={true}
            scrollable={true}
            borderTop={false}
            tableColumns={tableColumns}
            tableContent={manageSubscriptions}
            classes={classes}
          />
          {alertMessage && <AlertDialog
            handleClose={() => setAlertMessage(null)}
            type={alertMessage.type}
            message={alertMessage.message}
            handleDialogClose={() => setAlertMessage(null)}
          />}
          {confirmConfig && (
            <ConfirmDialog
              handleCancel={() => showConfirmDialog(null)}
              handleOk={confirmConfig.confirm}
              message={confirmConfig.message}
            />
          )}
        </Grid>

        <CardActions className="padding16">
          {props.children}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default SubscriptionChannelListing;