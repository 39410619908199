import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button, CircularProgress, ExpansionPanel, ExpansionPanelDetails, Link } from '@material-ui/core';
import { TextInputField, ErrorHelperText } from '../Common/HelperInputFields';
import { useDispatch, useSelector } from 'react-redux';
import { postAcceptInvite, getValidateTokenStatus, postAcceptOrRejectInvite } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { passwordValidator } from '../../utils';
import { APP_STATUS, USER_ROLE_MAP } from '../../utils/contants'
import { XylemLogo, XylemCopyright } from '../Common/XylemBranding';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    formTop: {
        marginTop: '100px',
    },
    cardTitle: {
        color: '#303f9f',
    },
    buttonLabel: {
        textTransform: 'none',
    },
    spanTitle: {
        color: '#303f9f',
    },
    link:{
        fontSize:18
    }
}));

const InviteMsgCard = (props: any) => {
    const { cardMsg, cardTitle, showActions } = props;
    const classes = useStyles();
    const navigate = useNavigate();

    const state: any = useSelector(st => st);
    const { currentUser } = state;

    return (
        <Card className="w3-white">
            <Grid container alignItems="center" direction="column">
                <XylemLogo />
                <CardHeader className={classes.cardTitle} title={cardTitle} />
                <CardContent>
                    <Typography component="h5">
                        {cardMsg}
                    </Typography>
                </CardContent>
                {showActions && (
                    <CardActions className="padding16">
                        <Link
                            className={classes.link}
                            component="button"
                            variant="body2"
                            onClick={() => {
                                const accessToken = localStorage.getItem('access_token');
                                if (accessToken) {
                                    localStorage.removeItem('access_token');
                                }
                                navigate('/login')
                                window.location.reload();
                            }}
                        >
                            Login
                        </Link>
                    </CardActions>
                )}
            </Grid>
            <XylemCopyright />
        </Card>
    )
}

const Invite = (props: any) => {
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const initForm: any = {
        password: '',
        confirm: ''
    };
    const { token } = useParams();
    const initErr: any = {};
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState<any>();
    const [inviteStatus, setInviteStatus] = useState(null);
    let displayCard: any = null;
    const [passReg, setPassReg] = useState(false);

    useEffect(() => {
        fetchTokenValidationStatus();
    }, [1]);

    const fetchTokenValidationStatus = () => {
        dispatch(getValidateTokenStatus(token))
            .then((resp: any) => {
                const res = resp && resp.data;
                setInviteStatus(res.data);
            });
    };

    const handleChange = (e: any) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);
        setForm(fieldValue);
    }

    const validateData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        if (form.password !== form.confirm) {
            hasError = true;
            err.confirm = "Password and confirm password must be same."
        }

        if (form.password === form.confirm) {
            if (!passwordValidator(form.password)) {
                hasError = true;
                err.password = 'Password does not meet minimum requirements';
                setPassReg(true);
            }
        }

        Object.keys(form).forEach((key) => {
            if (!form[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            valid.token = token;
            dispatch(postAcceptInvite(valid)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    setAlertMessage('Your password has been set. Please proceed to login')
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!')
                }
            });
        }
    }

    const handlePendingInvitation = (e: any, selectedOption) => {
        if (inviteStatus) {
            dispatch(postAcceptOrRejectInvite({
                userId: inviteStatus?.userId,
                optedFor: selectedOption,
                token
            })).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    fetchTokenValidationStatus();
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!')
                }
            });
        }
    }

    if (inviteStatus == null || inviteStatus == undefined || !inviteStatus) {
        return (<div className="textMarginCenter">
            <CircularProgress />
        </div>);
    }

    if (inviteStatus && inviteStatus.status === APP_STATUS.INVITED && !inviteStatus.userId) {
        displayCard = (
            <Card className="w3-white">
                <XylemLogo />
                <form onSubmit={(e) => { handleSubmit(e) }}>

                    <CardHeader title="Please set a password to Join" />
                    <div className="w3-padding w3-large">{inviteStatus.uniqueId}</div>
                    <CardContent>
                        <TextInputField
                            id="password"
                            type="password"
                            name="password"
                            placeholder="New Password"
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            errors={errors.password}
                        />
                        <TextInputField
                            id="confirm-password"
                            type="password"
                            name="confirm"
                            placeholder="Confirm Password"
                            variant="outlined"
                            margin="dense"
                            onChange={handleChange}
                            errors={errors.confirm}
                        />
                        <ErrorHelperText error={errors.token} />
                    </CardContent>

                    {
                        passReg &&
                        <CardContent>
                            <div className={classes.root}>
                                <ExpansionPanel>
                                    <ExpansionPanelDetails>
                                        <Typography className="w3-text-red">
                                            <li> Minimum password length 8</li>
                                            <li>Require at least one digit</li>
                                            <li>Require at least one upper case</li>
                                            <li> Require at least one lower case letter</li>
                                            <li>Require at least one symbol i.e.,</li>
                                            <li style={{ listStyle: "none", padding: '0px 20px' }}>{"[{$&+,:;=?@#|'<>.-^*()%!\"}]"}</li>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </CardContent>
                    }
                    <CardActions className="padding16">
                        <Button
                            id="submit-button"
                            color="primary"
                            className={`${classes.buttonLabel}`}
                            variant="contained"
                            type="submit"
                            style={{ marginLeft: 'auto' }}
                            onClick={(e) => handleSubmit(e)}
                        >Join
                        </Button>
                    </CardActions>
                </form>
                <XylemCopyright />
            </Card>
        )
    } else if (inviteStatus.status === APP_STATUS.JOINED) {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation  Accepted'}
                cardMsg={'You have accepted the invitation. Please continue.'}
                showActions={true}
            />
        )
    } else if (inviteStatus.status === APP_STATUS.EXPIRED) {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation Expired'}
                cardMsg={'Invitation got expired, for further details please contact your admin'}
                showActions={true}
            />
        )
    } else if (inviteStatus.status === APP_STATUS.INVITE_PENDING) {
        displayCard = (
            <Card className="w3-white">
                <XylemLogo />
                <CardHeader className={`${classes.cardTitle} w3-center`} title="Invitation is pending" />
                <CardContent>
                    <Typography component="h5">
                        You have been invited to join as <span className={classes.spanTitle}>{`${inviteStatus?.role && USER_ROLE_MAP[inviteStatus?.role] ? USER_ROLE_MAP[inviteStatus?.role]:inviteStatus?.role}`}</span><>{inviteStatus?.customer ? <> for the customer <span className={classes.spanTitle}>{`${inviteStatus?.customer}`}</span></> : null}</>
                    </Typography>
                </CardContent>
                <CardActions className="padding16">
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        className={`${classes.buttonLabel}`}
                        style={{ marginRight: 'auto' }}
                        onClick={(e) => { handlePendingInvitation(e, 'accept') }}
                    >
                        Accept
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={`${classes.buttonLabel}`}
                        style={{ marginLeft: 'auto' }}
                        onClick={(e) => { handlePendingInvitation(e, 'reject') }}
                    >
                        Reject
                    </Button>
                </CardActions>
            </Card>
        )
    } else if (inviteStatus.status === APP_STATUS.INVITE_REJECTED) {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation Rejected'}
                cardMsg={`You have rejected your invitation, for further details please contact your admin ${inviteStatus?.invitedBy?'- '+inviteStatus?.invitedBy:''}`}
                showActions={false}
            />
        )
    } else {
        displayCard = (
            <InviteMsgCard
                cardTitle={'Invitation Invalid'}
                cardMsg={`Invitation got revoked, for further details please contact your admin ${inviteStatus?.invitedBy?'- '+inviteStatus?.invitedBy:''}`}
                showActions={false}
            />
        )
    }


    return (
        <div>
            <Grid justifyContent="center" container className={classes.formTop} >
                <Grid item xs={12} sm={4} md={3} lg={3} >
                    {displayCard}
                </Grid>
            </Grid>
            {alertMessage && (
                <AlertDialog
                    handleClose={() => {
                        setAlertMessage(null)
                        const accessToken = localStorage.getItem('access_token');
                        if (accessToken) {
                            localStorage.removeItem('access_token');
                        }
                        navigate('/login')
                        window.location.reload();
                    }}
                    message={alertMessage}
                />
            )}
        </div>
    );
}

export default Invite;