
import React from 'react';
import xylemLogo from '../../img/xylem_logo.svg';

export  function XylemLogo(){
    return <div className="w3-center w3-padding-16">
        <img style={{ height: "50px",margin:'auto' }} alt='xylem_logo' src={xylemLogo} />
    </div>
}


export function XylemCopyright() {
    return <div className="w3-center w3-text-grey w3-padding w3-margin-bottom">
        © 2022 Xylem Inc. All rights reserved
    </div>
}