import React, { useCallback, useState, useEffect } from 'react';
import { Theme, InputBase } from '@material-ui/core';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '10px'
    },
    card: {
        width: 300,
        height: 120,
        backgroundColor: '#C4C4C4',
        margin: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    spacing: {
        marginLeft: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    }
}));
const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input:{
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da', 
            width:'100%',
            maxWidth:300,
            height: 'auto',
            padding: '10px 12px', 
            transition: theme.transitions.create(['border-color', 'box-shadow']), 
        },
    }),
)(InputBase);


const SearchBox = (props: any) => {

    const classes = useStyles();
    const { search, placeholder, value   } = props;
    const [searchValue, setSearchValue] = useState(value);
    const handler = useCallback(debounce(search, 500), [search]);

    const handleKeyDown = (event: any) => {
        const val = event.target.value;
        setSearchValue(val);
        if (val.length === 0 || val.length > 2) {
            handler(val);
        }
    };

    useEffect(()=> {
        setSearchValue(value);
    },[value])

   
    const inputProps = {
        placeholder, 
        onChange: handleKeyDown,
        value: searchValue,
        type:"search"
    } 

    return (
        <div>
            <BootstrapInput
                className={classes.margin} 
                {...inputProps}
            />
        </div>
    )
};

export default SearchBox;