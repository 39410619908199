import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Drawer, Divider, List, FormControl, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReactSelect from 'react-select';
import { getTeamListing } from '../../Redux/actions';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  label: {
    background: 'white',
    padding: '0px 5px',
  },
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    minWidth: 300,
  },
  filterIcon: {
    marginTop: '4px',
    marginRight: '20px',
  },
  reactSelect: {
    zIndex: 5
  },
  scrollMenuList:{
    maxHeight: 200, 
    overflow: 'auto'
  },
  buttonLabel: {
    textTransform: "none",
  },
});

const TeamFilters = ({ filterData, handleTeamFilters, customerId }: any) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [teamList, setTeamList] = useState(initialData);
  const [drawerState, setDrawerState] = React.useState({
    right: false,
  });
  const [selectedTeam, setSelectedTeam] = useState<any>(Object.assign({
    label: 'Select Team',
    value: ''
  }));

  useEffect(() => {
    dispatch(getTeamListing(customerId)).then((resp: any) => {
      const respData = resp && resp.data 
      if (respData && respData.length) {
          const list = respData.map((item: any) => (
              {
              label: item.name, 
              value: item.id
              }
          ));
          setTeamList(list)
        }
    });
  }, [dispatch]);


  type DrawerSide = 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    setDrawerState({ ...drawerState, [side]: open });
  };


  const handleTeamFilter = (customer: any) => {
    setSelectedTeam(customer); 
  }

  const triggerFilter = () => {
    handleTeamFilters({
      teamId: selectedTeam.value
    });
    setDrawerState({
      right: false,
    });
  }

  const handleClearFilter = () => {
    setDrawerState({
      right: false,
    });
    setSelectedTeam({ 
      label: 'Select Team',
      value: '',  
    });
    handleTeamFilters({
      teamId: ''
    });
  }


  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <h3>Filters</h3>
      <List>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={`${classes.formControl} ${classes.reactSelect}`}>
            <ReactSelect
              name="id"
              label="Select Team"
              options={teamList}
              placeHolder="Select Team"
              value={selectedTeam}
              onChange={(e) => handleTeamFilter(e)}
            />
          </FormControl>
        </Grid>
      </List>
      <Divider />
      <List>
        <Grid container justifyContent="space-between" alignItems="center">
          <Button variant="contained"
            size="small"
            onClick={handleClearFilter}>
            Clear
            </Button>
          <Button 
            variant="contained"
            size="small"
            color="primary"
            onClick={triggerFilter}
            className={`w3-center ${classes.buttonLabel}`}>
            Filter
            </Button>
        </Grid>
      </List>

    </div>
  );
 
  return (
    <div>
      <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
      <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
        {/* ModalProps={{ onBackdropClick: ()=> clearFilterState() }}> */}
        {sideList('right')}
      </Drawer>
    </div>
  )


}

export default TeamFilters;