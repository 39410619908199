import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, CircularProgress, Button, Tooltip, Chip, Typography,
  Box,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { useSearchParams } from 'react-router-dom';
import AlertDialog from '../Common/AlertDialog';
import TeamUserModal from './TeamUserModal';
import moment from 'moment';
import { getInvitedMemberList, getTeamListing, deleteInviteUser } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';
import TitleHeader from '../Common/TitleHeader';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TeamUserFilters from './TeamUserFilters';
import { Can } from '../../Context/Can';
import { ROLE_NAMES } from '../../Context/userRoles';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS, SUBJECTS } from '../../utils/contants';
import { removeNonValueFilters } from '../../utils';
import TableUI from '../Common/TableUI';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  addPaper: {
    padding: '25px',
    height: 180,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    // padding: '10px',
    display: 'inline-block'
  },
  content: {
    padding: '0px 5px 5px 5px',
    marginBottom: '8px',
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
    },
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  toolTip: {
    fontSize: '13px'
  },
  customToolTip: {
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 5px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '13px'
  },
  logo: {
    height: 68,
  },
  table: {
    minWidth: 700,
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  breadCrumb: {
    color: "#0085ad",
    paddingTop: 10,
  },
  buttonLabel: {
    textTransform: "none"
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default function ManageTeamUsers(props: any) {
  const { customerId, teamId, customer } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageTeamMembers: any = null;
  const [data, setData] = useState(initialData);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [alertMessage, setAlertMessage] = useState<any>();
  ;
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 12;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any>(Object.assign({}));
  const [msgType, setMsgType] = useState("success");
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);
  const tableColumns = ['Name', 'Email', 'Role', 'Updated On', 'Status', 'Action']

  const fetchData = () => {
    let flag = false
    const selectedTeamName = queryParams.get('teamId')
    if (selectedTeamName && selectedTeamName !== 'Teams') {
      queryParams.set('teamId', undefined)
      flag = true
    }

    let page: number = parseInt(queryParams.get('page'));
    const params = {
      ...Object.fromEntries(queryParams),
      offset: (page ? page - 1 : 0) * limit
    }
    setIsLoading(true);
    dispatch(getInvitedMemberList(params, customerId))
      .then((resp: any) => {
        if (resp && resp.status === 200) {
          const teamUsers = resp.data
          if (flag == true) {
            let filteredData: any = []
            teamUsers.data.forEach((user: any) => {
              user.json?.jSelectedTeams?.forEach((team: any) => {
                if (team.inputValue === selectedTeamName || team.title === selectedTeamName) {
                  filteredData.push(user)
                  return;
                }
              })
            })
            setData(filteredData)
            setIsLoading(false);
            return
          }


          if (!teamUsers) {
            return;
          }
          setData(resp.data.data);
          setTotalCount(resp.data.total);
          setIsLoading(false);
        }
      });
    dispatch(getTeamListing(customerId))
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [queryParams, dispatch]);

  const handleDialogClose = () => {
    setAlertMessage(null);
    setOpen(false);
  };

  const handleDeleteMember = () => {
    if (selectedMember) {
      setShowConfirmDialog(false);
      dispatch(deleteInviteUser(selectedMember.id)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage('User invitation deleted Successfully...!');
          setSelectedMember({});
          fetchData();
        } else if (res && res.success === false) {
          setAlertMessage(res.message);
          setMsgType("fail");
        } else {
          setAlertMessage('User invite deletion failed...!');
          setMsgType("fail");
        }
      });
    }
  };

  const updateQueryParams = (params: any) => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  const handleSearch = (value: string) => {
    updateQueryParams({ search: value, page: 1 });
  }

  const handlePagination = (page: number, lmt: number) => {
    updateQueryParams({ page, limit: lmt });
  };

  const triggerDelete = (member: any) => {
    setSelectedMember(member);
    setShowConfirmDialog(true);
  }

  const handleTeamUserFilters = (filters: any) => {
    const { type="", status="", teamId="" } = filters;
    updateQueryParams({ type, status, teamId, page: 1 });
  }

  let teamMemberTable: any[] = [];
  if (data && data.length > 0) {
    teamMemberTable = data.map((item, idx) => {
      return (
        <TableRow key={`user-item-${idx}`}>
          <TableCell className={classes.tDef} align="left">{item.user ? item.user.name : item.name}</TableCell>
          <TableCell className={classes.tDef} align="left">{item.user ? item.user.uniqueId : item.email}</TableCell>
          <TableCell className={classes.tDef} align="left">{ROLE_NAMES[item.type]}
          </TableCell>
          <TableCell className={classes.tDef} align="left">
            {moment(item.updatedAt).format('Do MMM YYYY')}
          </TableCell>
          <TableCell className={classes.tDef} align="left">
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
              <Chip
                size="small"
                className={`w3-margin-right ${item.status === APP_STATUS.JOINED ? 'w3-green' : item.status === APP_STATUS.INVITED ? 'w3-yellow' : 'w3-red'}`}
                label={item.status}
              />
              {item.status === APP_STATUS.JOINED &&
                item?.user?.json?.lastActiveTime &&
                <Chip
                  size="small"
                  className={`w3-margin-right ${moment().isSameOrAfter(moment(item?.user?.json?.lastActiveTime)
                    .add(20, 'minutes')) || item?.user?.json?.loginStatus === 'logged-out' ? 'w3-blue-grey' : 'w3-blue'}`}
                  label={moment().isSameOrAfter(moment(item?.user?.json?.lastActiveTime)
                    .add(20, 'minutes')) || item?.user?.json?.loginStatus === 'logged-out' ? 'Offline' : 'Online'}
                />}

              {item.status === APP_STATUS.JOINED && <Box component="div" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                {item?.user?.json?.lastActiveTime && <div className="w3-tiny">
                  <span className="w3-opacity">Last Active:</span> {moment(item?.user?.json?.lastActiveTime).format('MMM Do YYYY, hh:mm a')}
                </div>}
                {item?.user?.lastLogin && <div className="w3-tiny">
                  <span className="w3-opacity">Last Login:</span> {moment(item?.user?.lastLogin).format('MMM Do YYYY, hh:mm a')}
                </div>}
              </Box>}
            </Box>
          </TableCell>
          <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-end">
              {item && ((item.status === APP_STATUS.JOINED) || (item.status === APP_STATUS.INACTIVE)) ? '' :
                <Tooltip title="Delete">
                  <IconButton aria-label="deactivate" onClick={() => triggerDelete(item)}>
                    <DeleteIcon className="cursor-pointer" />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => {
                  setEditData(item)
                  setOpen(true)
                }}>
                  <EditIcon className="Frsor-pointer" />
                </IconButton>
              </Tooltip>

            </Box>
          </TableCell>
        </TableRow>)


    })
  }
  if (isLoading || !data) {
    manageTeamMembers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageTeamMembers = teamMemberTable;
  } else if (data && data.length === 0) {
    manageTeamMembers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5> No Members Found</h5>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <div >
      <CustomerBreadcrumbs customer={customer}  >
        <Typography color="textPrimary">Users</Typography>
      </CustomerBreadcrumbs>
      <TitleHeader
        component={
          <div>
            <Can do="manage" on={SUBJECTS.MEMBER}>
              <Button
                variant="contained"
                size="medium" color="primary"
                style={{ marginRight: 10 }}
                className={`${classes.buttonLabel}`}
                onClick={() => {
                  setEditData(null);
                  setOpen(true);
                }}
              >
                Invite User
              </Button>
              <HelperButton trigger="invite_customer_user" />
            </Can>
          </div>
        }
        showSearch={true}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            value={queryParams.get('search') || ''}
            placeholder={'Search Team Members...'}
            search={handleSearch}
          />
        </div>
        <TeamUserFilters
          customerId={customerId}
          filterData={existingParams}
          handleTeamUserFilters={handleTeamUserFilters}
        />
      </TitleHeader>
      <Grid container>
        <TableUI
          tableColumns={tableColumns}
          tableContent={manageTeamMembers}
          scrollable={false}
          stickyHeader={false}
          borderTop={false}
          classes={classes}
        />

        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={parseInt(queryParams.get('page'))}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>

      {open ? (<DialogModal
        fullWidth
        isOpen={open}
        hideTitle={true}
        hideBtns={true}
        maxWidth="sm"
        dialogContent={(
          <TeamUserModal
            triggerList={() => {
              fetchData()
            }}
            customerId={customerId}
            teamId={teamId}
            open={open}
            editData={editData}
            handleClose={() => {
              setOpen(false);
              setEditData(null);
            }}
          />
        )}
      />)
        : null}
      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
          type={msgType}
          handleDialogClose={handleDialogClose}
        />) : null}
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setSelectedMember({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteMember}
            message={'Are you sure to delete the user invitation?'}
          />
        ) : null}
    </div>
  );
}
