import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateAcceptChannelInvite } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { useNavigate, useParams } from 'react-router-dom';
import TopBar from '../Common/TopBar';
import { makeStyles } from '@material-ui/core/styles';
import { AssignmentReturnedOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    formTop: {
        marginTop: '100px',
    },
    cardTitle: {
        color: '#303f9f',
    },
    buttonLabel: {
        textTransform: 'none',
    },
}));


const InviteMsgCard = (props: any) => {
    const { cardMsg, cardTitle, showActions } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Card>
            <Grid container alignItems="center" direction="column">
                <CardHeader className={classes.cardTitle} title={cardTitle} />
                <CardContent>
                    <Typography component="h5">
                        {cardMsg}
                    </Typography>
                </CardContent>
                {showActions ? (
                    <CardActions className="padding16">
                        <Button
                            color="primary"
                            className={`${classes.buttonLabel}`}
                            variant="contained"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => navigate('/login')}
                        >Login
                        </Button>
                    </CardActions>
                ) : null}
            </Grid>
        </Card>
    )
}

const Channel = (props: any) => {
    const classes = useStyles();
    const [alertMessage, setAlertMessage] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const { verificationToken } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        updateAcceptChannelInvite(verificationToken).then(resp => { 
            setShowLoading(false);
            setAlertMessage(resp.data.message);
        }).catch(ex => {
            setAlertMessage('Something went wrong. Try again...!');
        });
    }, [1]);

    let displayCard: any = null;
    if (showLoading) {
        displayCard = <InviteMsgCard cardMsg={<CircularProgress />} />
    }
    return (
        <div>
            <Grid container className={classes.formTop} >
                <Grid item xs={6} className="marginAuto marginTop50">
                    {displayCard}
                </Grid>
            </Grid>
            {alertMessage && (
                <AlertDialog
                    handleClose={() => {
                        setAlertMessage(null)
                        navigate('/settings')
                    }}
                    message={alertMessage}
                />
            )}
        </div>
    );
}

export default Channel;

