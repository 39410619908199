import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Drawer, Divider, List, FormControl, InputLabel, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReactSelect from 'react-select';
import { getAllCustomers } from '../../Redux/actions';
import IconButton from '@material-ui/core/IconButton';
import MuiSelectField from '../Common/MuiSelectField';
import MuiSelect from '@material-ui/core/Select';
import HelperButton from '../Common/HelperButton';

const useStyles = makeStyles({
  label: {
    background: 'white',
    padding: '0px 5px',
  },
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    minWidth: 300,
  },
  filterIcon: {
    marginTop: '4px',
    marginRight: '20px',
  },
  reactSelect: {
    zIndex: 5
  },
  scrollMenuList: {
    maxHeight: 200,
    overflow: 'auto'
  },
  buttonLabel: {
    textTransform: "none",
  },
});

const CustomerFilters = ({ value, handleCustomerFilters, options }: any) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [customerList, setCustomerList] = useState(initialData);
  const [drawerState, setDrawerState] = React.useState({
    right: false,
  });
  const [selectedCustomer, setSelectedCustomer] = useState<any>(Object.assign({
    label: 'Select Customer',
    value: ''
  }));
  const [optionValue, setOptionValue] = useState<any>([]);
  const [filter, setFilter] = useState<any>(Object.assign({
    status: '',
  }));
  let tagOptions: any[] = [];

  useEffect(() => {
    dispatch(getAllCustomers()).then((resp: any) => {
      const respData = resp && resp.data
      if (respData && respData.length) {
        const list = respData.map((item: any) => (
          {
            label: item.name,
            value: item.id
          }
        ));
        setCustomerList(list)
      }
    });
  }, [dispatch]);


  type DrawerSide = 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    setDrawerState({ ...drawerState, [side]: open });
  };

  const handleOptionOnChange = (event: any, newValue: any) => {
    setOptionValue(newValue);
  }

  const handleOnChangeCustomer = (customer: any) => {
    setSelectedCustomer(customer);
  }

  const handleSelectFilter = (event: any) => {
    const { name, value } = event.target;
    filter[name] = value;
    setFilter({
      [name]: value
    });
  }

  const triggerFilter = () => {
    const filterData: any = { ...value };
    if (optionValue.length > 0) {
      const tagIds = optionValue.map((item: any) => (item.id))
      filterData.tags = tagIds.join();
    }

    if (selectedCustomer && selectedCustomer.value) {
      filterData.id = selectedCustomer.value;
    }
    if (filter) {
      filterData.status = filter.status
    }
    handleCustomerFilters(filterData);
    setDrawerState({
      right: false,
    });
  }

  const handleClearFilter = () => {
    const filterData: any = { ...value };
    setSelectedCustomer({
      label: 'Selected Customer',
      value: '',
    });
    setFilter({
      status: '',
    });
    setOptionValue([])
    filterData.tags = '';
    filterData.id = '';
    filterData.status = '';
    handleCustomerFilters(filterData);
    setDrawerState({
      right: false,
    });
  }

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  if (options && options.length > 0) {
    tagOptions = options;
  }

  if (optionValue && optionValue.length > 0) {
    tagOptions = options.filter((optionItem: any) => !optionValue.find((selectedTag: any) => selectedTag.id === optionItem.id))
  }

  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <Grid container justifyContent="space-between">
        <h3>Filters</h3>
        <HelperButton trigger="customer_filter" />
      </Grid>
      <List>
        {/* <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={`${classes.formControl} ${classes.reactSelect}`}>
            <ReactSelect
              name="id"
              label="Select Customer"
              options={customerList}
              placeHolder="Select Customer"
              value={selectedCustomer}
              onChange={(e) => handleOnChangeCustomer(e)}
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <MuiSelectField
            size="small"
            placeholder="Select Tags"
            label="Select Tags"
            limitTags={3}
            value={optionValue}
            options={tagOptions}
            onChange={handleOptionOnChange}
          />
        </Grid>
        <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
          <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusFilterLabel">
            Status
          </InputLabel>
          <MuiSelect
            labelId="statusFilterLabel"
            id="status"
            name="status"
            value={filter.status || ''}
            onChange={handleSelectFilter}
            fullWidth
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </MuiSelect>
        </FormControl>
      </List>
      <Divider />
      <List>
        <Grid container justifyContent="space-between" alignItems="center">
          <Button variant="contained"
            size="small"
            onClick={handleClearFilter}>
            Clear
            </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={triggerFilter}
            className={`w3-center ${classes.buttonLabel}`}>
            Filter
            </Button>
        </Grid>
      </List>

    </div>
  );

  return (
    <div>
      <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
        {/* ModalProps={{ onBackdropClick: ()=> clearFilterState() }}> */}
        {sideList('right')}
      </Drawer>
    </div>
  )


}

export default CustomerFilters;