import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, MenuItem, InputLabel, FormControl, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamListing, getAllCustomers, getMetaByName, getAllTerms, getTopicById, postConfirmNotification } from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from '../Common/AlertDialog'; 
import Select from 'react-select'; 
import MuiSelectField from '../Common/MuiSelectField';
import DialogModal from '../Common/DialogModal';
import EmailListingModal from './EmailListingModal';
import Loader from '../Common/Loader';
import { TEMPLATE_PARAMS } from '../../utils/contants';
import HelperButton from '../Common/HelperButton';

const useStyles = makeStyles((theme: Theme) => ({
    modalHeader: {
        padding: '15px',
        alignItems: 'center'
    },
    modalFooter: {
        textAlign: 'right'
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '15px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px'
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: "none"
    },
    button: {
        textTransform: "none",
        marginTop: "5px"
    },
    reSelectLabel: {
        marginBottom: 8,
        fontSize: 12
    },
    reactSelect: {
        zIndex: 5
    },
    noPaddingModal: {
        paddingLeft: 0,
        paddingRight: 0,
    }
}));

const EmailList = ({ list = [] }) => {
    const [maxCount, setMaxCount] = useState(10); 
    list.sort((a,b)=> a.email.localeCompare(b.email))
    return <div>
        <b>Email List : </b>
        {list.filter((item: any, idx: number) => item && idx < maxCount)
            .map((it: any) => it.email).join(', ')}
        {list.length > 10 && <button className="w3-button w3-text-blue w3-padding-small w3-margin-left"
            onClick={e => setMaxCount(maxCount == 10 ? 10000 : 10)}
        >{maxCount == 10 ? `+${list.length - 10} more` : `show less`}</button>}
    </div>

}


const NotificationForm = (props: any) => {
    const { formType } = props;
    const { topicId, customerId } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [alertMessage, setAlertMessage] = useState<any>();
    const [errors, setErrors] = useState<any>({
        subject: '',
        text: '',
        type: '',
        notificationType: '',
        selectedTeam: '',
        selectedCustomer: '',
        selectedTags: '',
    });
    const [values, setValues] = useState<any>(Object.assign({
        subject: '',
        text: '',
        type: '',
        notificationType: 'other',
        summaryEmailList: [],
        selectedTeam: '',
        selectedCustomer: '',
        selectedTags: '',
    }));
    const dispatch: any = useDispatch();
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [open, setOpen] = useState(false);
    const [msgType, setMsgType] = useState("success");
    const [teamList, setTeamList] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [emailData, setEmailData] = useState<any>();
    let tagOptionList: any[] = [];
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => { 
        if (formType === 'patchWindow') {
            dispatch(getAllCustomers()).then((resp: any) => {
                const respData = resp && resp.data
                if (respData && respData.length) {
                    const list = respData.map((item: any) => (
                        {
                            label: item.name,
                            value: item.id
                        }
                    ));
                    setCustomerOptions(list)
                }
            });
           
            dispatch(getAllTerms('tag')).then((termRes: any) => {
                if (termRes && termRes.status === 200) {
                    const termData = termRes.data;
                    setTagOptions(termData.map((item: any) => (
                        {
                            id: item.id,
                            title: item.name,
                            metaId: item.metaId
                        })))
                }
            }) 

        }
        if (topicId) {
            dispatch(getTopicById(parseInt(topicId))).then((resp: any) => {
                const respData = resp && resp.data
                if (respData && respData.success && respData.data) {
                    const {
                        selectedCustomer,
                        selectedTags,
                        brand
                    } = respData.data.json;
                    const {
                        id, title, customerType, description,
                        replyToEmail, emailSubject, emailBody, summaryEmailList
                    } = respData.data;
                    const topicObj = {
                        id: id,
                        title: title,
                        type: customerType,
                        description: description,
                        replyEmail: replyToEmail,
                        subject: emailSubject,
                        text: emailBody,
                        selectedCustomer,
                        summaryEmailList: Array.isArray(summaryEmailList) ? summaryEmailList : [],
                        selectedTags,
                        json:{brand}
                    };
                    window.jQuery('#summernote').summernote('code', respData.data.emailBody);
                    setValues(Object.assign({}, values, topicObj));
                }
            });
        }
    }, [dispatch,topicId]);

    const validateForm = () => {
        let formIsValid = true;
        let err = Object.assign({}, errors);

        Object.keys(errors).forEach(key => {
            err[key] = '';
        });

        if (!values.subject) {
            formIsValid = false;
            err.subject = 'This field is required';
        }
        if (!values.text) {
            formIsValid = false;
            err.text = 'This field is required';
        }
        // if (values.type == 'global_topic') 
        {
            console.log(values)
        }

        // if (!values.notificationType) {
        //     formIsValid = false;
        //     err.notificationType = 'This field is required';
        // }
        if (formType === 'patchWindow') {
            if (!values.selectedTags && values.summaryEmailList.length === 0) {
                formIsValid = false;
                err.selectedTags = 'Select the customer tags to send emails';
            }
        }
        setErrors(err);
        return formIsValid;
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });

        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setErrors(err);
    }

    useEffect(() => {
        window.jQuery("#summernote").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['codeview', 'help']],
            ],
        })
    }, [1])
    const handleText = (text: any) => {
        setValues({
            ...values,
            text
        });
    }

    const handleSelect = (selected: any) => {
        setValues({
            ...values,
            selectedCustomer: selected
        });
    }

    const handleOptionOnChange = (event: any, newValue: any) => {
        const err = Object.assign({}, errors);
        if (err['selectedTags']) {
            err['selectedTags'] = null;
        }
        setValues({
            ...values,
            selectedTags: newValue
        });
        setErrors(err);
    }

    const handleDialogClose = () => {
        setAlertMessage(null);
        setOpen(false);
    };

    const handleSubmit = () => {
        const checkForm = validateForm();
        if (checkForm) {
            setShowLoader(true);
            Object.assign(values, {
                topicId: values.id,
                type: values.type,
                json: {...values.json, notificationType: values.notificationType },
                subject: values.subject,
                content: values.text,
                createdBy: currentUser.data.data.id
            });
            dispatch(postConfirmNotification(values)).then((resp: any) => {
                setShowLoader(false);
                if (resp.status === 200 || resp.status === 201) {
                    const customers = resp.data.data
                    let sortByName = customers.slice(0);
                    sortByName.sort((a: any, b: any) => a.customerName.toLowerCase() < b.customerName.toLowerCase() ? - 1 : Number(a.customerName.toLowerCase() > b.customerName.toLowerCase()))
                    setEmailData(sortByName);
                    setOpen(true);
                } else if (resp.status === 400) {
                    setMsgType('fail')
                    setAlertMessage('Failed to get email list');
                }
            }).catch(() => setShowLoader(false))
        }
    };

    if (tagOptions && tagOptions.length > 0) {
        tagOptionList = tagOptions;
    }

    if (values.selectedTags && values.selectedTags.length > 0) {
        tagOptionList = tagOptions.filter((optionItem: any) => !values.selectedTags.find((selectedTag: any) => selectedTag.id === optionItem.id))
    }

    return (
        <>
            <Grid container alignItems='center'>
                <h4>Send Notification - {values.title} </h4>
                <div style={{ marginLeft: 8 }}>
                    <HelperButton trigger="send_notification_helper" />
                </div>
            </Grid>
            <div className="w3-padding-16">
                {/* <b>Description</b><br /> */}
                {values.description}</div>
            <Paper elevation={1} style={{ padding: 16 }}>
                <Grid container spacing={2}>

                    <Grid container spacing={2} className="w3-padding-small">
                        <Grid item xs={12} md={12} >
                            {(Array.isArray(values.summaryEmailList) && values.summaryEmailList.length > 0) && <EmailList
                                list={values.summaryEmailList.concat()}
                            />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth
                                id="subject"
                                name="subject"
                                margin="dense"
                                label="Email Subject"
                                placeholder="Email Subject"
                                value={values.subject || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                                required
                            />
                            <ErrorHelperText error={errors.subject} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {<div>{(values.type && values.type === 'customer_topic') ?
                                <>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        className={`${classes.formControl} ${classes.reactSelect}`}>
                                        <Select
                                            isMulti
                                            isDisabled={true}
                                            options={customerOptions}
                                            label="Select Multiple Customers"
                                            placeholder="Select Multiple Customers*"
                                            value={values.selectedCustomer || ''}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                    </FormControl>
                                    <ErrorHelperText error={errors.selectedCustomer} />
                                </> : null}
                                {(values.type && (values.type === 'tag_topic' || values.type === 'global_topic')) ?
                                    <>
                                        <MuiSelectField
                                            margin="dense"
                                            size="small"
                                            placeholder="Select Tags"
                                            label={values.summaryEmailList.length > 0 ? "Select Tags" : "Select Tags*"}
                                            limitTags={5}
                                            value={values.selectedTags || []}
                                            options={tagOptionList}
                                            onChange={handleOptionOnChange}
                                        />
                                        <ErrorHelperText error={errors.selectedTags} />
                                    </>
                                    : null}
                            </div>}
                        </Grid>


                        {/* <Grid item xs={12} md={3} >
                            <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                    Notification Type*
                                </InputLabel>
                                <MuiSelect
                                    labelId="notificationTypeLabel"
                                    id="notificationType"
                                    name="notificationType"
                                    value={values.notificationType || ''}
                                    onChange={(e) => handleChange(e)}
                                    fullWidth
                                >
                                    <MenuItem value="emergency_notification">Emergency Notification</MenuItem>
                                    <MenuItem value="patch_notification">Patch Notification</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </MuiSelect>
                            </FormControl>
                            <ErrorHelperText error={errors.notificationType} />
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.marginTop10}>
                        {/* <Editor
                                margin="dense"
                                placeholder="Topic Body"
                                label="Email Body"
                                value={values.text || ''}
                                onChange={(e: any) => handleText(e)}
                                templateParams={TEMPLATE_PARAMS}
                            /> */}
                        <div id="summernote">
                        </div>


                        {TEMPLATE_PARAMS && TEMPLATE_PARAMS.length > 0 && <Grid container>
                            <Grid item xs={12}>
                                <p> You can use template parameters</p>
                                {TEMPLATE_PARAMS.join(', ')}
                            </Grid>
                        </Grid>}


                        <ErrorHelperText error={errors.text} />
                    </Grid>

                    <Grid item xs={12} className={classes.modalFooter}>

                        <Grid container spacing={2} justifyContent='flex-end'>
                            {Object.keys(errors).filter(k => errors[k] != '').length > 0 && <Grid item md={12} xs={12}>
                                <span className="w3-text-red">Please make sure the mandatory fields are filled</span>
                            </Grid>}
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className={classes.buttonLabel}
                                    onClick={() => { navigate(`/alerts`) }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={`${classes.buttonLabel}`}
                                    onClick={handleSubmit}
                                >
                                    Confirm
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>

                    {open ? (<DialogModal
                        fullWidth
                        isOpen={open}
                        hideTitle={true}
                        hideBtns={true}
                        maxWidth="md"
                        contentStyle={classes.noPaddingModal}
                        dialogContent={(
                            <EmailListingModal
                                triggerList={() => {
                                }}
                                emailData={emailData}
                                topicId={topicId}
                                values={values}
                                open={open}
                                handleClose={() => {
                                    setOpen(false);
                                }}
                            />
                        )}
                    />)
                        : null}
                    {alertMessage ?
                        (
                            <AlertDialog
                                handleClose={() => {
                                    setAlertMessage(null);
                                    navigate(`/alerts`)
                                }}
                                message={alertMessage}
                                type={msgType}
                                handleDialogClose={handleDialogClose}
                            />
                        )
                        : null
                    }
                    {showLoader ? <Loader open={showLoader} /> : null}
                </Grid>
            </Paper>
        </>);
}

export default NotificationForm;