import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const ConfirmDialog = (props: any) => {
    const { title, message, handleCancel, handleOk, open = true } = props;
    return (
        <Dialog
            open={open}
        >
            {title && <DialogTitle id="alert-dialog-title">{title || ''}</DialogTitle>}
            {message && <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>}
            <DialogActions>
                <Button onClick={handleCancel} color="primary" id="cancel-button">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary" id="ok-button">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
