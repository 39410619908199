import { Button, Grid, TextField, MenuItem, InputLabel, FormControl, FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { postInviteUser, updateInviteUser, getCurrentUser, getTeamListing, setAlertDialogMessage } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import MuiSelect from '@material-ui/core/Select';
import ConfirmDialog from '../Common/ConfirmDialog';
import { emailValidator, textValidator } from '../../utils';
import MuiSelectField from '../Common/MuiSelectField';
import Axios from 'axios';
import HelperButton from '../Common/HelperButton';
import { userRoles } from '../../Context/userRoles';
import { APP_STATUS } from '../../utils/contants';

const MuiCheckbox = withStyles({
    root: {
        color: '#0085ad',
        '&$checked': {
            color: '#0085ad',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: 20
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginTop10: {
        marginTop: '10px',
    },
    label: {
        background: 'white',
        padding: '0px 5px',
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    buttonLabel: {
        textTransform: "none"
    },
    reSelectLabel: {
        marginBottom: 8,
        fontSize: 12
    },
}));

const TeamUserModal = (props: any) => {
    let { handleClose, triggerList, customerId } = props;
    const classes = useStyles();
    const navigate = useNavigate()
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const dispatch: any = useDispatch();
    const [editData, setEditData] = useState(props.editData || null)
    const [alertMessage, setAlertMessage] = useState<any>();
    const [msgType, setMsgType] = useState("success");
    const [options, setOptions] = useState<any>([]);
    const [selectedTeams, setSelectedTeams] = useState<any>([]);
    const [subCheckBox, setSubCheckBox] = React.useState<any>({
        maintenanceAlert: true,
        incidentAlert: true,
    });
    const [form, setForm] = useState<any>({
        id: '',
        name: '',
        email: '',
        type: '',
    });
    const [errors, setErrors] = useState<any>({});
    const [showLoader, setShowLoader] = useState(false);
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    useEffect(() => {
        dispatch(getTeamListing(customerId)).then((res: any) => {
            const respData = res.data;
            if (!respData) {
                return;
            }
            const list = respData.map((item: any) => ({
                id: item.id,
                title: item.name,
            }));
            if (list.length == 0) {
                list.push({ title: 'Default' });
                setSelectedTeams([{ title: 'Default' }])
            } else if (editData?.json?.jSelectedTeams) {
                setSelectedTeams(editData.json.jSelectedTeams);
            } else {
                const defTeam = list.find((item: any) => item.title == 'Default');
                setSelectedTeams([defTeam]);
            }
            setOptions(list);
        }).catch((err: any) => {
            console.error('err: ', err);
        });
        if (!editData) {
            return;
        }
        let json: any = {};
        if (editData.user?.json) {
            json = editData.user.json
        }
        else if (editData.json) {
            json = editData.json;
        }

        setForm(Object.assign(form, editData));

        if (editData?.json?.jSelectedTeams) {
            setSelectedTeams(editData.json.jSelectedTeams);
        }
        if (json.jSubCheckBox) {
            setSubCheckBox(json.jSubCheckBox);
        }

    }, [dispatch, editData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const data: any = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setForm(data);
        setErrors(err);
    };


    const handleCheckBoxChange = (event: any) => {
        setSubCheckBox({
            ...subCheckBox,
            [event.target.name]: event.target.checked
        });
    };

    const handleOptionOnChange = (event: any, newValue: any) => {
        const err = Object.assign({}, errors);
        if (err['selectedTeams']) {
            err['selectedTeams'] = null;
        }
        setErrors(err);
        if (typeof newValue === "string") {
            setSelectedTeams({
                title: newValue
            });
        } else if (newValue && newValue.inputValue) {
            setSelectedTeams({
                title: newValue.inputValue
            });
        } else {
            setSelectedTeams(newValue);
        }
    }

    const showAlert = (msg: any, type: any) => {
        setAlertMessage(msg);
        setMsgType(type);
    }

    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        setShowLoader(false);
        triggerList();
        showAlert(msg, type);
    };

    const handleDialogClose = () => {
        setAlertMessage(null);
    };

    const validateData = () => {
        let formIsValid = true;
        const err = Object.assign({}, errors);
        if (!form.name) {
            formIsValid = false;
            err.name = 'Field is Required';
        } else if (!textValidator(form.name)) {
            formIsValid = false;
            err.name = 'Only letters,numbers and characters[.,-_] are allowed';
        }
        if (!emailValidator(form.email)) {
            formIsValid = false;
            err.email = "Please enter valid email address.";
        }
        if (!form.type) {
            formIsValid = false;
            err.type = 'Field is Required';
        }
        if (form.type !== userRoles.CUSTOMER_ADMIN && selectedTeams.length === 0) {
            formIsValid = false;
            err.selectedTeams = 'Field is Required';
        }
        setErrors(err);
        return formIsValid;
    };

    const handleDeactivate = () => {
        setShowConfirmDialog(true);
    }

    const handleInviteResend = (id: any) => {
        Axios.post(`/api/v1/invites/resend-invite/${id}`, { customerId }).then(res => {
            setEditData(res.data);
        }).catch(ex => {
            dispatch(setAlertDialogMessage('Error while fetching data', 'fail'));
            console.error(ex);
        });

    }

    const handleDeactivateUser = () => {
        setShowConfirmDialog(false);
        const status = editData && editData.status === APP_STATUS.JOINED ? APP_STATUS.INACTIVE : editData.userId != null ? APP_STATUS.JOINED : APP_STATUS.INVITED
        Object.assign(form, { status });
        dispatch(updateInviteUser(form, form.id)).then((resp: any) => {
            if (resp.status === 200 || resp.status === 201) {
                const respData = resp.data && resp.data.data;
                const currentUserData = currentUser && currentUser.data && currentUser.data.data
                if (currentUserData && respData && currentUserData.id === respData.id) {
                    dispatch(getCurrentUser())
                }
                if (editData && ((editData.status === APP_STATUS.JOINED) || (editData.status === APP_STATUS.INVITED))) {
                    updateResponse(resp, 'User deactivated successfully...!');
                } else {
                    updateResponse(resp, 'User activated successfully...!');
                }
            } else if (resp.status === 400) {
                setAlertMessage('Enter a Valid XylemInc Email');
            }
        });
    }

    const handleSubmit = () => {
        const checkForm = validateData();
        const json: any = {
            jSelectedTeams: selectedTeams,
            jSubCheckBox: subCheckBox
        }
        Object.assign(form, {
            customerId,
            json
        });
        if (!checkForm) {
            return;
        }
        // setShowLoader(true);
        if (form.id) {
            dispatch(updateInviteUser(form, form.id)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    const respData = resp.data && resp.data?.data;
                    const currentUserData = currentUser && currentUser.data && currentUser.data.data
                    if (currentUserData && respData && currentUserData.id === respData.id) {
                        dispatch(getCurrentUser())
                    }
                    updateResponse(resp, 'The user settings were saved.');
                }
            });
        } else {
            dispatch(postInviteUser(form)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'The user will receive an invitation email shortly.');
                }
            });
        }
    };

    const descriptionMap = {
        customer_admin: 'Can create and invite other customer admins, Teams and team members',
        team_lead: 'Can manage teams and team members',
        team_member: 'Can subscribe to alerts and maintenance window notifications.'
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.title}>
                    {editData ? 'Edit User' : 'Invite Team Member'}
                    <HelperButton trigger="create_customer_user_helper" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        variant='outlined'
                        label='Name'
                        margin='dense'
                        id="name"
                        name='name'
                        value={form.name || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.name} />
                </Grid>
                <Grid item container spacing={1} xs={12} md={12} alignItems='center' justifyContent='space-between'>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={editData ? true : false}
                            required
                            fullWidth
                            variant='outlined'
                            label='Email'
                            name='email'
                            id="email"
                            value={form.email.toLowerCase().trim() || ''}
                            onChange={handleChange}
                            margin='dense'
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                },
                            }}
                        />
                        <ErrorHelperText error={errors.email} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                            <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                                Role*
                            </InputLabel>
                            <MuiSelect
                                labelId="roleLabel"
                                id="type"
                                name="type"
                                value={form.type}
                                onChange={handleChange}
                                disabled={editData && currentUser && currentUser?.data?.data?.uniqueId === editData.email ? true : false}
                                fullWidth
                            >
                                <MenuItem value="customer_admin">Customer Admin</MenuItem>
                                <MenuItem value="team_lead">Team Lead</MenuItem>
                                <MenuItem value="team_member">Team Member</MenuItem>
                            </MuiSelect>
                        </FormControl>
                        <ErrorHelperText error={errors.type} />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction='column' justifyContent='flex-start'>
                    <Grid item sm={12} md={12} lg={12}>
                        <div className='w3-text-grey'>
                            {descriptionMap[form.type]}
                        </div>
                    </Grid>
                </Grid>

                {((form.type === "team_lead") || (form.type === "team_member")) &&
                    (<Grid item xs={12}>
                        <div style={{ marginTop: -8 }}>
                            <MuiSelectField
                                size="small"
                                placeholder="Add Team"
                                label="Teams"
                                limitTags={3}
                                value={selectedTeams}
                                options={options}
                                onChange={handleOptionOnChange}
                                allowNew={true}
                            />
                            <span>If no custom team is provided, the team Default Team will be created / used</span>
                            <ErrorHelperText error={errors.selectedTeams} />
                        </div>
                    </Grid>)}

                <Grid item container xs={12} direction='column' justifyContent='flex-start'>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <MuiCheckbox
                                    name="maintenanceAlert"
                                    checked={subCheckBox.maintenanceAlert}
                                    onChange={handleCheckBoxChange}
                                />
                            }
                            label="Subscribe to Maintenance alerts"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <MuiCheckbox
                                    name="incidentAlert"
                                    checked={subCheckBox.incidentAlert}
                                    onChange={handleCheckBoxChange}
                                />
                            }
                            label="Subscribe to Incident alerts"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item sm={7} container justifyContent="flex-start">
                        {editData && currentUser && currentUser?.data?.data?.uniqueId !== editData.email && <>
                            {(editData && editData.status === 'Joined') &&
                                <div>
                                    <Button
                                        color="secondary"
                                        variant='contained'
                                        size='small'
                                        className={classes.buttonLabel}
                                        onClick={handleDeactivate}
                                    >
                                        Deactivate User
                                    </Button>
                                    <HelperButton trigger="deactivate_customer_user" />
                                </div>}

                            {(editData && ((editData.status === APP_STATUS.INVITE_REJECTED)||(editData.status === APP_STATUS.INVITED))) &&
                                <div>
                                {editData.type === form.type &&
                                <>
                                    <Button
                                        color="secondary"
                                        variant='contained'
                                        size='small'
                                        className={classes.buttonLabel}
                                        onClick={e => handleInviteResend(editData.id)}
                                    >
                                        Resend Invite
                                    </Button>
                                    <span className="w3-text-grey w3-tiny w3-margin">
                                        {(editData.status !== APP_STATUS.INVITE_REJECTED) && <>
                                            {moment(editData.expiresAt).isBefore(Date.now()) ? "Current Invite Expired " : "Current Invite Expires "}
                                            {moment(editData.expiresAt).fromNow()}
                                        </>}
                                    </span>
                                </>}
                                </div>}
                            {(editData && editData.status === APP_STATUS.INACTIVE) &&
                                <Button
                                    color="primary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={handleDeactivate}
                                >
                                    Activate User
                                </Button>}
                                </>}
                        </Grid>
                        <Grid item container sm={5} justifyContent="flex-end">
                            <Button
                                variant='contained'
                                size='small'
                                className={`w3-margin-right ${classes.buttonLabel}`}
                                onClick={handleClose}
                            >Cancel
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                className={`${classes.buttonLabel}`}
                                onClick={handleSubmit}
                            >
                                {editData ? 'Save' : 'Invite'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                            handleClose();
                        }}
                        message={alertMessage}
                        type={msgType}
                        handleDialogClose={handleDialogClose}
                    />
                )
                : null
            }
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeactivateUser}
                        message={'Are you sure to activate/deactivate the user ?'}
                    />
                ) : null}
            {showLoader ? <Loader open={showLoader} /> : null}
        </div>
    );
};

export default TeamUserModal;
