import React, { useState, useEffect } from 'react';
import {
  Grid, Drawer, Divider, List, Button, InputLabel, MenuItem, FormControl
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
import HelperButton from '../Common/HelperButton';
import { ENVIRONMENT_TYPES, NOTIFICATION_TYPES} from '../../utils/contants'

const useStyles = makeStyles({
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    minWidth: 300,
  },
  filterIcon: {
    marginTop: '4px',
    marginRight: '20px',
  },
  reactSelect: {
    zIndex: 5
  },
  scrollMenuList: {
    maxHeight: 200,
    overflow: 'auto'
  },
  buttonLabel: {
    textTransform: "none",
  },
});

const AlertFilters = ({ filterData, customerId, handleAlertFilters }: any) => {
  const classes = useStyles();
  const [filter, setFilter] = useState(filterData);
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [drawerState, setDrawerState] = React.useState({
    right: false,
  });

  const handleSelectFilter = (event: any) => {
    const { name, value } = event.target; 
    filter[name] = value;
    setFilter(Object.assign({}, filter));
  }

  const triggerFilter = () => {
    handleAlertFilters(Object.assign({}, filter));
    setDrawerState({
      right: false,
    });
  }

  type DrawerSide = 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    setDrawerState({ ...drawerState, [side]: open });
  };

  const handleClearFilter = () => {
    setDrawerState({
      right: false,
    });
    setFilter({
      status: '',
      type: '',
      eventType: '',
      eventUserAction: '',
      environment: ''
    });
    handleAlertFilters({
      eventUserAction: '',
      status: '',
      type: '',
      eventType: '',
      environment: ''
    });
  }


  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <Grid container justifyContent="space-between">
        <h3>Filters</h3>
        <HelperButton trigger="customer_alerts_filter" />
      </Grid>
      <List>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="environmentLabel">
              Environment
                </InputLabel>
            <MuiSelect
              labelId="environmentLabel"
              id="environment"
              name="environment"
              value={filter.environment || ''}
              onChange={handleSelectFilter}
              fullWidth
            > 
              {ENVIRONMENT_TYPES.map(item => <MenuItem value={item.value}>{item.title}</MenuItem>)}
            </MuiSelect>
          </FormControl>

          {/* <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="eventUserActionFilter">
              Subscription
                </InputLabel>
            <MuiSelect
              labelId="eventUserActionFilter"
              id="eventUserAction"
              name="eventUserAction"
              value={filter.eventUserAction || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              <MenuItem value="mysubscriptions">My Subscriptions</MenuItem>
              <MenuItem value="all_alerts">All Alerts</MenuItem>
            </MuiSelect>
          </FormControl> */}

         {!customerId && <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="eventTypeFilterLabel">
              Event Type
                </InputLabel>
            <MuiSelect
              labelId="eventTypeFilterLabel"
              id="eventType"
              name="eventType"
              value={filter.eventType || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              <MenuItem value="oneTimeEvent">Alerts</MenuItem>
              <MenuItem value="scheduledEvent">Maintenance Windows</MenuItem>
            </MuiSelect>
          </FormControl>}
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
              Type
                </InputLabel>
            <MuiSelect
              labelId="typeLabel"
              id="type"
              name="type"
              value={filter.type || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              {NOTIFICATION_TYPES.map((item: any) => {
                return <MenuItem value={item.value}>{item.title}</MenuItem>
              })} 
            </MuiSelect>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusFilterLabel">
              Status
                </InputLabel>
            <MuiSelect
              labelId="statusFilterLabel"
              id="status"
              name="status"
              value={filter.status || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              <MenuItem value="Enabled">Enabled</MenuItem>
              <MenuItem value="Disabled">Disabled</MenuItem>
            </MuiSelect>
          </FormControl>

        </Grid>
      </List>
      <Divider />
      <List>
        <Grid container justifyContent="space-between" alignItems="center">
          <Button variant="contained"
            size="small"
            onClick={handleClearFilter}
          >
            Clear
              </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={triggerFilter}
            className={`w3-center  ${classes.buttonLabel}`}>
            Filter
              </Button>
        </Grid>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
        {/* ModalProps={{ onBackdropClick: ()=> clearFilterState() }}> */}
        {sideList('right')}
      </Drawer>
    </div>
  )
}

export default AlertFilters;