import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CircularProgress, Breadcrumbs, Typography } from '@material-ui/core';
import {useNavigate,Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { getCustomerDashStats } from '../../Redux/actions';
import CountCard from './CountCard';
import { Can } from '../../Context/Can';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';

const useStyles = makeStyles(theme => ({
    breadCrumb: {
        color: "#0085ad",
        paddingTop: 10,
        paddingBottom: 16,
    },
}));

const CustomerDashboard = (props: any) => {
    const navigate=useNavigate()
    const { customerId, customer } = props;
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const state: any = useSelector(state => state);
    const { currentCustomer, customerDashStats } = state;

    const fetchData = () => {
        dispatch(getCustomerDashStats(parseInt(customerId)))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [customerId]);

    if (!customerDashStats || customerDashStats.isFetching) {
        return (<div className="w3-center">
            <CircularProgress />
        </div>);
    }

    return (
        <div>
            <div>
                <CustomerBreadcrumbs customer={customer} />
            </div>
            {customerDashStats && customerDashStats.data && customerDashStats.data.data &&
                <Grid className="w3-margin-top" container spacing={2} justifyContent="flex-start">
                    {/* <Grid item>
                    <CountCard
                        title="Teams"
                        count={customerDashStats.data.data.teams_count}
                        btnText="All Teams"
                        handleClick={
                            () => navigate(`/customer/${customerId}/teams`)
                        }
                    />
                </Grid> */}
                    <Grid item className="w3-hide" >
                        <CountCard
                            title="Alerts"
                            count={customerDashStats.data.data.topics_count}
                            btnText="All Alerts"
                            // handleClick={
                            //     () => navigate(`/customer/${customerId}/alerts`, true, {
                            //         eventType: 'oneTimeEvent'
                            //     })
                            // }
                            handleClick={
                                () => navigate(`/customer/${customerId}/alerts`, {
                                   state:{ eventType: 'oneTimeEvent'}
                                })
                            }
                        />
                    </Grid>
                    <Grid item>
                        <CountCard
                            title="Maintenance Windows"
                            count={customerDashStats.data.data.patchs_count}
                            btnText="All Maintenance Windows"
                            // handleClick={
                            //     () => navigate(`/customer/${customerId}/alerts`, true, {
                            //         eventType: 'scheduledEvent'
                            //     })
                            // }
                            handleClick={
                                () => navigate(`/customer/${customerId}/alerts`, {
                                   state:{eventType: 'scheduledEvent'}
                                })
                            }
                        />
                    </Grid>
                </Grid>}
        </div>
    )
};

export default CustomerDashboard;
