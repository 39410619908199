import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Button
} from '@material-ui/core';
import { postCreateNotification } from '../../Redux/actions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../Common/AlertDialog';
import TableUI from '../Common/TableUI'
const useStyles = makeStyles((theme: Theme) => ({
  marginTop10: {
    marginTop: '10px'
  },
  emTable: {
    maxHeight: 350,
    overflowY: 'auto',
  },
  width: {
    width: 200
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  buttonLabel: {
    textTransform: "none"
  },
}));

const EmailListingModal = (props: any) => {
  const navigate=useNavigate()
  const { handleClose, emailData, values } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser } = state;
  const [alertMessage, setAlertMessage] = useState<any>();
  const [msgType, setMsgType] = useState<string>('');
  let manageSubscriptions: any = null;
  const tableColumns = ['Customer', 'Email']
  const handleDialogClose = () => {
    setAlertMessage(null);
  };

  const handleSubmit = () => {
    Object.assign(values, {
      topicId: values.id,
      type: 'Alert',
      json: { ...values.json, notificationType : values.notificationType },
      expandedSubject: values.subject,
      expandedContent: values.text,
      createdBy: currentUser.data.data.id
    });
    dispatch(postCreateNotification(values)).then((resp: any) => {
      if (resp.status === 200 || resp.status === 201) {
        setMsgType('success');
        setAlertMessage('The notifications are queued for sending.');
      } else if (resp.status === 400) {
        setMsgType('fail');
        setAlertMessage('Notifications sending failed');
      }
    });
  };

  let subscriptionsTable: any[] = [];
  let hasEmails = false;
  if (emailData && emailData.length > 0) {
    subscriptionsTable = emailData.map((item: any) => {
      if (item.userList.length > 0) {
        hasEmails = true;
      }
      return (
        <TableRow key={item.customerName}>
          <TableCell className={`${classes.tDef} ${classes.width}`}>{item.customerName}</TableCell>
          <TableCell className={classes.tDef}>
            {item.userList && item.userList.length == 0 ?  'This list is empty' : item.userList.join(',  ')}
          </TableCell>
        </TableRow>
      )
    })
  }
  if (!emailData) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (emailData && emailData.length) {
    manageSubscriptions = subscriptionsTable;
  } else if (emailData && emailData.length === 0) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Emails Found
              </h5>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      
      <TableUI
        tableContent={manageSubscriptions}
        tableColumns={tableColumns}
        stickyHeader={true}
        scrollable={true}
        classes={classes}
      />

      <Grid item xs={12} className={classes.marginTop10}>
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              size='small'
              className={classes.buttonLabel}
              onClick={handleClose}
            >Cancel
                        </Button>
          </Grid>
          <Grid item>
           {hasEmails && <Button
              color='primary'
              variant='contained'
              size='small'
              className={`w3-margin-right ${classes.buttonLabel}`}
              onClick={handleSubmit}
            >
              {'Send'}
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      {alertMessage ? (
        <AlertDialog
          handleClose={() => {
            setAlertMessage(null);
            setMsgType('')
            navigate(`/alerts`)
          }}
          type={msgType}
          message={alertMessage}
          handleDialogClose={handleDialogClose}
        />) : null}
    </div>
  );
};

export default EmailListingModal;