import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { getCustomerDetailsById, setAlertDialogMessage, setStoreData } from '../../Redux/actions';
import { CustomerMenu } from '../Common/Header';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


export const CustomerContainer = (props: any) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [loading, showLoading] = useState(true);
    const [customer, setCustomer] = useState(null);
    const [error, setError] = useState(false);
    const { customerId , id } = useParams();

    useEffect(() => { 
        getCustomerDetailsById(parseInt(customerId)).then((resp: any) => {
            if (resp.data.data.code && resp.data.data.code == 404)
                throw new Error("Error while fetching customer")
            setCustomer(resp.data.data);
            dispatch(setStoreData('currentCustomer', resp.data.data))
        }).catch(err => {
            dispatch(setAlertDialogMessage('Error while fetching data', 'fail', () => {
                navigate('/customers')
            }))
            console.error(err);
        })
        return () => {
            dispatch(setStoreData('currentCustomer', null))
        }
    }, [customerId])

    return <div>
        <CustomerMenu container={props.container} customerId={parseInt(customerId)} />
        {customer == null && <div className="w3-center w3-padding-64">
            <CircularProgress />
        </div>}
        {customer && <props.component {...props} customer={customer} customerId={customerId} />}
    </div>
}
