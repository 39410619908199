import React, { useState } from 'react';
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';
import { TextInputField, ErrorHelperText } from '../Common/HelperInputFields';
import { useDispatch } from 'react-redux';
import { postResetPassword } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { useNavigate , useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { passwordValidator } from '../../utils';
import { XylemLogo, XylemCopyright } from '../Common/XylemBranding';

const useStyles = makeStyles(theme => ({
  formTop: {
    marginTop: '100px',
  },
  buttonLabel: {
    textTransform: 'none',
  },
}));

const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15), 
    }
  })
);


const ResetPassword = (props: any) => {
  const classes = useStyles();
  const panel = panelStyles();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const initForm: any = {
    password: '',
    confirm: ''
  };

  const { token } = useParams();
  const initErr: any = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [passReg, setPassReg] = useState<any>();

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
      errorField[name] = null;
      setErrors(errorField);
    }
    fieldValue[name] = value;
    setPassReg(false);
    setForm(fieldValue);
  };

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (form.password !== form.confirm) {
      hasError = true;
      err.confirm = "Password and confirm password must be same."
    }

    if (!passwordValidator(form.password)) {
      hasError = true;
      setPassReg(true);
      err.password = 'Password does not meet minimum requirements';
    }

    Object.keys(form).forEach((key) => {
      if (!form[key]) {
        hasError = true;
        err[key] = 'This field is required';
      }
    });
    if (hasError) {
      setErrors(err);
      return false;
    }
    return form;
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      valid.token = token;
      dispatch(postResetPassword(valid)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          localStorage.removeItem('access_token');
          setAlertMessage('Your password has been reset successfully.')
        } else if (res && res.data) {
          setErrors(res.data);
        } else {
          setAlertMessage('Something went wrong. Try again...!')
        }
      });
    }
  }
  return (
    <div>
      <Grid justifyContent="center" container className={classes.formTop} >
        <Grid item xs={12} sm={4} md={3} lg={3} >
          <Card className="w3-white">
            <XylemLogo />
            <form onSubmit={(e) => { handleSubmit(e) }}>
              <CardHeader title="Reset Password" />
              <CardContent>
                <TextInputField
                  id="password"
                  type="password"
                  name="password"
                  placeholder="New Password"
                  variant="outlined"
                  margin="dense"
                  onChange={handleChange}
                  errors={errors.password}
                />
                {
                  passReg === true &&
                  <div className={panel.root}>
                    <ExpansionPanel>
                      <ExpansionPanelDetails>
                        <Typography className="w3-text-red">
                          <li> Minimum password length 8</li>
                          <li>Require at least one digit</li>
                          <li>Require at least one upper case</li>
                          <li> Require at least one lower case letter</li>
                          <li>Require at least one symbol i.e.,</li>
                          <li style={{listStyle:"none", padding:'0px 20px'}}>{"[{$&+,:;=?@#|'<>.-^*()%!\"}]"}</li>
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }
                <TextInputField
                  id="confirm-password"
                  type="password"
                  name="confirm"
                  placeholder="Confirm Password"
                  variant="outlined"
                  margin="dense"
                  onChange={handleChange}
                  errors={errors.confirm}
                />
                <ErrorHelperText error={errors.token} />
              </CardContent>

              <CardActions className="padding16">
                <Button
                  id="submit-button"
                  color="primary"
                  className={`${classes.buttonLabel}`}
                  variant="contained"
                  type="submit"
                  style={{ marginLeft: 'auto' }}
                  onClick={(e) => handleSubmit(e)}
                >Reset
                            </Button>
              </CardActions>
            </form>
            <XylemCopyright />
          </Card>
        </Grid>
      </Grid>
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
              navigate('/login')
            }}
            message={alertMessage}
          />
        ) : null}
    </div>
  );
}

export default ResetPassword;
