export const emailValidator = (email:string) =>{
    const pattern = new RegExp(/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,16})+$/i);
    email = email.trim();
    return pattern.test(email);
}

export const urlValidator = (url:string)=>{
    const pattern = new RegExp(/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/);
    return pattern.test(url);
}

export const passwordValidator = (password:string)=>{
    const pattern = new RegExp(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g);
    return pattern.test(password);
}

export const textValidator = (text: string) => {
    const pattern = new RegExp(/^[A-Za-z0-9.,\s_-]+$/g);
    return pattern.test(text)
}

export const nameValidator = (text: string) => {
    const pattern = new RegExp(/^[A-Za-z0-9.,'\s_-]+$/g);
    return pattern.test(text)
}

export const removeNonValueFilters = (filterValues:Object)=>{
    const filters = {};
    Object.keys(filterValues).forEach((key: any) => {
      if (filterValues[key] !== '') {
        Object.assign(filters, { [key]: filterValues[key] });
      }
    });
    return filters;
}

export const acceptOnlyNumbersInput = (value: string) => {
    const pattern = /^[0-9\b]+$/;
    return pattern.test(value);
}

export const acceptHHMM = (value: string) => {
    const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return pattern.test(value);
}