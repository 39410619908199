import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Paper, CircularProgress, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Chip, Link as MUILink, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { disableCustomer, getCustomerList, setStoreData, getMetaByName, getAllTerms } from '../../Redux/actions';
import { Link, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import AlertDialog from '../Common/AlertDialog';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import CustomerFilters from './CustomerFilters';
import ConfirmDialog from '../Common/ConfirmDialog';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal';
import EditIcon from '@material-ui/icons/Edit';
import { Can } from '../../Context/Can';
import TableUI from '../Common/TableUI';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS, SUBJECTS } from '../../utils/contants';
import { removeNonValueFilters } from '../../utils';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  addPaper: {
    padding: '25px',
    height: 180,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    // padding: '10px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '18vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
  },
  content: {
    padding: '0px 5px 5px 5px',
    marginBottom: '8px',
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
    },
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  toolTip: {
    fontSize: '13px'
  },
  customToolTip: {
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 5px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '13px'
  },
  logo: {
    height: 68,
  },
  table: {
    minWidth: 700,
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  buttonLabel: {
    textTransform: "none"
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  websiteUrl: {
    maxWidth: 200
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-all",
    overflow: "hidden"
  }
}));

const limit = 12;

function ManageCustomers(props: any) {
  const navigate = useNavigate()
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageCustomers: any = null;
  const [data, setData] = useState(initialData);
  const [selectedCustomer, setCustomer] = useState<any>(Object.assign({}));
  const [alertMessage, setAlertMessage] = useState<any>();
  const state: any = useSelector(st => st);
  const { currentCustomer } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);
  const [options, setOptions] = useState([]);
  const tableColumns = ['Name', 'Website Url', 'Customer ID', 'Tags', 'Created', 'Status', 'Action']



  useEffect(() => {
    dispatch(getAllTerms('tag')).then((termRes: any) => {
      if (termRes && termRes.status === 200) {
        const termData = termRes.data;
        setOptions(termData.map((item: any) => (
          {
            id: item.id,
            title: item.name,
            metaId: item.metaId
          })))

      }
    })
  }, [dispatch]);

  const fetchData = () => {
    let page = parseInt(queryParams.get("page"));
    const params = {
      ...Object.fromEntries(queryParams),
      offset: (page ? page - 1 : 0) * limit
    }
    setIsLoading(true);
    dispatch(getCustomerList(params)).then((resp: any) => {
      if (resp && resp.status === 200) {
        const customerListData = resp.data;
        if (!customerListData) {
          return;
        }
        setData(customerListData.data);
        setTotalCount(customerListData.total);
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (currentCustomer) {
      dispatch(setStoreData('currentCustomer', null))
    }
    fetchData();
  }, [queryParams, dispatch]);

  const updateQueryParams = (params: any) => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  const handleSearch = (value: string) => {
    updateQueryParams({ search: value, page: 1 });
  }

  const handlePagination = (page: number, lmt: number) => {
    updateQueryParams({ page, limit: lmt });
  };

  const handleDisableCustomer = () => {
    if (selectedCustomer) {
      setShowConfirmDialog(false);
      disableCustomer(selectedCustomer.id).then((resp: any) => {
        if (resp && resp.status === 200) {
          const result = resp.data;
          if (!result.success) {
            setAlertMessage(result.message);
            return;
          }
          setCustomer({});
          setAlertMessage(result.message);
          fetchData();
        } else {
          setAlertMessage('Customer deactivation failed...!');
        }
      });
    }
  };



  const handleCustomerFilters = (filters: any) => {
    const { tags = "", id = "", status = "" } = filters;
    console.log(filters);
    updateQueryParams({ tags, id, status, page: 1 });
  }

  let customerListTable: any[] = [];

  if (data && data.length > 0) {
    customerListTable = data.map((item) => {
      let tags: any = [];
      if (item && item.customerTags) {
        item.customerTags.forEach((items: any) => {
          tags.push(items.terms.name);
        })
      }
      return (
        <TableRow key={item.name}>
          <TableCell className={classes.tDef} align="left">
            <Link to={`/customer/${item.id}`} >
              {item.name}
            </Link>
          </TableCell>
          <TableCell className={`${classes.tDef} ${classes.websiteUrl}`} align="left">
            <Box component="div" className={classes.customBox}>
              <MUILink underline='hover' href={item.website} target="_blank">
                {item.website}
              </MUILink>
            </Box>
          </TableCell>
          <TableCell className={classes.tDef} align="left">{item.customerCode}</TableCell>
          <TableCell className={classes.tDef} align="left">
            {tags && tags.length > 2 ? <>{tags[0]}, {tags[1]} {
              <Tooltip
                interactive={true}
                arrow={true}
                title={<span className={classes.toolTip}>
                  {tags.slice(2).join()}
                </span>}>
                <span className={'w3-text-grey cursor-pointer'}>{` +${tags.length - 2} more `}</span>
              </Tooltip>
            }</> : tags.join()}</TableCell>
          {/* <TableCell className={classes.tDef} align="left">{item.customerUsers?.length}</TableCell> */}
          <TableCell className={classes.tDef} align="left">
            {moment(item.createdAt).format('Do MMM YYYY')}
          </TableCell>
          <TableCell className={classes.tDef} align="left">
            <Chip
              size="small"
              className={item.status === APP_STATUS.ACTIVE ? 'w3-green' : 'w3-red'}
              label={item.status}
            />
          </TableCell>
          <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
            <Can do="manage" on={SUBJECTS.EDIT_CUSTOMERS}>
              <Box component="div" display="flex" alignItems="center" justifyContent="flex-end">
                <Tooltip title="Edit">
                  <IconButton component={Link} aria-label="edit" to={`/customers/edit/${item.id}`}>
                    <EditIcon className="cursor-pointer" fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="View">
                  <IconButton component={Link} aria-label="edit" to={`/customer/${item.id}`}>
                    <ChevronRightIcon className="cursor-pointer" fontSize="small" />
                  </IconButton>
                </Tooltip>

              </Box>
            </Can>
          </TableCell>
        </TableRow>
      )
    })
  }
  if (isLoading || !data) {
    manageCustomers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageCustomers = customerListTable;
  } else if (data && data.length === 0) {
    manageCustomers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Customers Found
          </h5>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div >
      <TitleHeader
        component={
          <Can do="manage" on={SUBJECTS.ADD_CUSTOMERS}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              className={`${classes.buttonLabel}`}
              style={{ marginRight: 10 }}
              onClick={() => navigate('/customers/create')}
            >
              Create Customer
            </Button>
            <HelperButton trigger="create_customer" />
          </Can>
        }
        showSearch={true}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            search={handleSearch}
            value={queryParams.get("search") || ''}
            placeholder="Search Customers..."
          />
        </div>
        <CustomerFilters
          value={existingParams}
          handleCustomerFilters={handleCustomerFilters}
          options={options}
        />
      </TitleHeader>
      <Grid container>
        <TableUI
          scrollable={false}
          stickyHeader={false}
          tableColumns={tableColumns}
          tableContent={manageCustomers}
          borderTop={false}
          classes={classes}
        />

        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={parseInt(queryParams.get("page"))}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>

      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => setAlertMessage(null)}
            message={alertMessage}
          />
        )
        : null
      }
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setShowConfirmDialog(false)
              setCustomer({});
            }}
            handleOk={handleDisableCustomer}
            message={'Are you sure to deactivate the Customer?'}
          />
        ) : null}
    </div>
  );
}

export default ManageCustomers;