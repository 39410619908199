import React from 'react';
import { red, green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const AlertDialog = (props: any) => {
    const { title, message, type, handleClose, handleDialogClose } = props;
    return (
        <Dialog open={true}  >
            <DialogTitle id="alert-dialog-title">{title || ''}</DialogTitle>
            <DialogContent>
                {type === 'fail'
                    ? <ErrorIcon fontSize="large"
                        style={{ marginLeft: '45%' }}
                    />
                    : <CheckCircleOutlineIcon fontSize="large"
                        style={{ marginLeft: '45%' }}
                    />
                }
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {type === 'fail'
                    ? <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                    : <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;
