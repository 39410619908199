import {
    Button, Card, CardActions, CardContent, CardHeader, Grid, Typography,
    FormControl, InputLabel, MenuItem, Breadcrumbs, Tooltip, IconButton, FormControlLabel, Divider, Link as MuiLink,
} from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {useNavigate} from 'react-router';
import {useLocation,Link} from 'react-router-dom';
import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { changeUserName, changeUserPassword, getCurrentUser, postSettingsData, mondayTriggers, getSubCheckBox, makeRequest } from '../../Redux/actions';
import AlertDialog from './AlertDialog';
import { TextInputField } from './HelperInputFields';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { ErrorHelperText } from '../Common/HelperInputFields';
import PublishIcon from '@material-ui/icons/Publish';
import ConfirmDialog from '../Common/ConfirmDialog';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DialogModal from '../Common/DialogModal';
import SubscriptionChannelModal from '../Subscriptions/SubscriptionChannelModal';
import { Can } from '../../Context/Can';
import Loader from '../Common/Loader';
import SubscriptionChannelListing from '../Subscriptions/SubscriptionChannelListing';
import { green } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import HelperButton from '../Common/HelperButton';
import CustomerModal from '../Customers/CustomerModal';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { passwordValidator } from '../../utils';
import { SUBJECTS } from '../../utils/contants';
import { subject } from '@casl/ability';

const MuiCheckbox = withStyles({
    root: {
        color: '#0085ad',
        '&$checked': {
            color: '#0085ad',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles(theme => ({
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px',
    },
    marginTop40: {
        marginTop: '40px',
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        marginLeft: '0px',
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: "none"
    },
    breadCrumb: {
        color: "#0085ad",
        paddingTop: 10,
        paddingBottom: 10,
    },
}));

const panelStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        title: {
            marginTop: 30,
            fontSize: 25
        },
        subTitle: {
            marginTop: 30
        },
        emailTitle: {
            marginLeft: 20,
            marginTop: 10
        },
        label: {
            background: 'white',
            padding: '0px 5px'
        },
        buttonLabel: {
            marginTop: 15,
            textTransform: "none",
            backgroundColor: "#0085ad",
            color: "#fff",
            '&:hover': {
                backgroundColor: '#0085ad',
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        }
    })
);

const Settings = (props: any) => {
    const navigate = useNavigate()
    const store: any = useStore();
    const user = useSelector((state: any) => state.currentUser)
    const { customerId, customer } = props;
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const initForm: any = {
        currentPassword: '',
        password: '',
        confirm: '',
    };
    const initUserName: any = {
        userName: ''
    };

    const [showDlg, setShowDlg] = useState('');

    const [passReg, setPassReg] = useState<any>();
    const panel = panelStyles();
    const path = useLocation().pathname;
    const initErr: any = {};
    const [userNameForm, setUserNameForm] = useState(initUserName);
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState<any>();
    const [flag, setFlag] = useState<any>();
    let valuee: any;
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [msgType, setMsgType] = useState("success");
    const [showLoader, setShowLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [values, setValues] = useState<any>(Object.assign({
        fileLabel: '',
    }))
    const [triggerChannelListing, setTriggerChannnelListing] = useState(false);
    const [subCheckBox, setSubCheckBox] = React.useState<any>({
        maintenanceAlert: false,
        incidentAlert: false,
    });
    const [userName, setUserName] = useState<any>();
    const [confirmData, setConfirmData] = useState(false)

    const handleChange = (e: any) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value.trim();
        setPassReg(false);

        setForm(fieldValue);
    };

    const handleUserNameChange = (e: any) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, userNameForm);
        const errorField = Object.assign({}, errors);
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);

        setUserNameForm(fieldValue);
    };

    const validateData = () => {
        let hasError = false;
        const err = Object.assign({}, errors);
        if (form.password === form.currentPassword) {
            hasError = true;
            err.password = 'Current Password & New Password is Similar';
        }
        if (form.password !== form.confirm) {
            hasError = true;
            err.confirm = 'Password and confirm password must be same.';
        }

        if (!passwordValidator(form.password)) {
            hasError = true;
            setPassReg(true);
            err.password = 'Password does not meet minimum requirements';
        }

        Object.keys(form).forEach((key) => {
            if (!form[key]) {
                hasError = true;
                err[key] = 'This field is required';
            }
        });
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    };
    const handleSubmitUserName = (e: any) => {
        e.preventDefault();
        const data = {
            username: userNameForm.userName === '' ? userName : userNameForm.userName,
            subCheckBox
        };
        if (data) {
            dispatch(changeUserName(data)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    setFlag(0);
                    setAlertMessage('User Data Edited Successfully.');
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        }
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            dispatch(changeUserPassword(form)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    setFlag(1);
                    setAlertMessage('Password reset successfully. Login now...!');
                    localStorage.removeItem('access_token');
                } else if (res && res.data) {
                    setErrors(res.data);
                } else {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        }
    };


    useEffect(() => {
        getSubCheckBox().then((res: any) => { 
            setSubCheckBox({
                incidentAlert: res.data.data.incidentAlert,
                maintenanceAlert: res.data.data.maintenanceAlert
            });
            setUserName(res.data.data.name)
        })
    }, [1])


    const validateForm = () => {
        const regex = new RegExp('(.*?).(csv)$');
        let formIsValid = true;
        let err = Object.assign({}, errors);
        if (!values.fileLabel) {
            formIsValid = false;
            err.fileLabel = 'This field is required';
        }

        if (!uploadFile) {
            formIsValid = false;
            err.uploadFile = 'Please choose a file to upload';
        } else if (uploadFile) {
            if (!regex.test(uploadFile.name)) {
                formIsValid = false;
                err.uploadFile = 'Incompatible File Type. Please Upload a CSV File';
            }

        }
        setErrors(err);
        return formIsValid;
    };

    const handleFileUpload = (e: any) => {
        const err = Object.assign({}, errors);
        if (err.uploadFile) {
            err.uploadFile = null;
        }
        setErrors(err);
        setUploadFile(e.target.files[0]);
    };

    const handleSelect = (e: any) => {
        const { name, value } = e.target;
        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setErrors(err);
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleMondayReminder = () => {
        setShowLoader(true);
        dispatch(mondayTriggers()).then((resp: any) => {
            const res = resp && resp.data;
            if (res && res.success == true) {
                setShowLoader(false);
                setAlertMessage('Notifications triggered');
            }
        });

        setConfirmData(false)
    }

    const handleUploadSubmit = () => {
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setShowLoader(true);
        const type = values.fileLabel === 'ldap_user' ? 'invites' : 'customers'
        let formDta = new FormData();
        formDta.append('name', values.fileLabel);
        formDta.append('file', uploadFile);

        dispatch(postSettingsData(formDta, { type })).then((resp: any) => {
            const res = resp && resp.data;
            let msg = res.message;
            let resType;
            if (res && res.success) {
                resType = "success"

            } else {
                resType = "fail"
            }
            setShowLoader(false);
            setMsgType(resType);
            setAlertMessage(msg);
        });
    };

    const handleCheckBoxChange = (event: any) => {
        setSubCheckBox({
            ...subCheckBox,
            [event.target.name]: event.target.checked
        });
    };
    const handleDownload = (e: any, file: string) => {
        e.preventDefault();
        makeRequest('getSampleFile', [], { file }).then((res) => {
            const { data } = res;    
            const fileUrl = window.URL.createObjectURL(new Blob([data],{type: 'text/csv'}));
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = `${file}.csv`;
            link.click();
        }).catch((err) => {
            console.log('Error while downloading');
        })
    }
    return (
        <div>
            <Grid container spacing={2} className="w3-margin-top">
                <Grid item xs={12} md={9}>
                    {customer && <CustomerBreadcrumbs customer={customer}>
                        <Typography color="textPrimary">Settings</Typography>
                    </CustomerBreadcrumbs>}
                </Grid>
                <Grid item xs={12} md={3}>
                    <Can I="manage" a={SUBJECTS.WEEKLY_REMINDERS}>
                        <Button
                            onClick={() => setConfirmData(true)}
                            color="primary"
                            className={` ${classes.buttonLabel} w3-right`}
                            variant="contained"
                            size="small"
                            component="span"
                            style={{ marginRight: 15 }}
                        >
                            Send Weekly Reminders
                        </Button>
                    </Can>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="w3-margin-top" >
                <Grid item container xs={12} md={5} spacing={2}
                    justifyContent="flex-start" alignContent="flex-start">
                    <Grid item xs={12}  >
                        <Card >
                            <form onSubmit={(e) => {
                                handleSubmit(e);
                            }}>
                                <CardContent >
                                    <Grid container justifyContent="space-between">
                                        <h5>Change User Details</h5>
                                        <HelperButton trigger='change_username' />
                                    </Grid>
                                    <TextInputField
                                        type="text"
                                        name="userName"
                                        placeholder="New Username"
                                        variant="outlined"
                                        margin="dense"
                                        disabled
                                        value={user.data.data.uniqueId}
                                        errors={errors.userName}
                                    />
                                    <TextInputField
                                        type="text"
                                        name="userName"
                                        placeholder="Display Name"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleUserNameChange}
                                        value={userNameForm.userName}
                                        errors={errors.userName}
                                    />
                                    <Can I="manage" a={SUBJECTS.USER_CHANNELS}>
                                        <Grid item container xs={12} direction='column' justifyContent='flex-start' style={{ marginLeft: 5 }}>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <MuiCheckbox
                                                            name="maintenanceAlert"
                                                            checked={subCheckBox.maintenanceAlert}
                                                            onChange={handleCheckBoxChange}
                                                        />
                                                    }
                                                    label="Subscribe to Maintenance alerts"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <MuiCheckbox
                                                            name="incidentAlert"
                                                            checked={subCheckBox.incidentAlert}
                                                            onChange={handleCheckBoxChange}
                                                        />
                                                    }
                                                    label="Subscribe to Incident alerts"
                                                />
                                            </Grid>
                                            <Grid container justifyContent="flex-end">
                                                <HelperButton trigger="alerts_preference_helper" />
                                            </Grid>
                                        </Grid>
                                    </Can>
                                </CardContent>
                                <CardActions className="padding16">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: 'auto', textTransform: 'none' }}
                                        type="submit"
                                        onClick={(e) => {
                                            handleSubmitUserName(e)
                                        }}
                                    >Save
                                    </Button>
                                </CardActions>
                            </form>
                        </Card>
                    </Grid>
                    <Grid item xs={12} >
                        <Card>
                            <form onSubmit={(e) => {
                                handleSubmit(e);
                            }}>
                                <CardContent >
                                    <Grid container justifyContent="space-between">
                                        <h5>Change Password</h5>
                                        <HelperButton trigger='change_password' />
                                    </Grid>

                                    <TextInputField
                                        type="password"
                                        name="currentPassword"
                                        placeholder="Current Password"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleChange}
                                        errors={errors.currentPassword}
                                    />
                                    <TextInputField
                                        type="password"
                                        name="password"
                                        placeholder="New Password"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleChange}
                                        errors={errors.password}
                                    />
                                    {
                                        passReg === true &&
                                        <div className={panel.root}>
                                            <ExpansionPanel>
                                                <ExpansionPanelDetails>
                                                    <Typography className="w3-text-red">
                                                        <li> Minimum password length 8</li>
                                                        <li>Require at least one digit</li>
                                                        <li>Require at least one upper case</li>
                                                        <li> Require at least one lower case letter</li>
                                                        <li>Require at least one symbol i.e.,</li>
                                                        <li style={{listStyle:"none", padding:'0px 20px'}}>{"[{$&+,:;=?@#|'<>.-^*()%!\"}]"}</li>
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    }
                                    <TextInputField
                                        type="password"
                                        name="confirm"
                                        placeholder="Confirm Password"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleChange}
                                        errors={errors.confirm}
                                    />
                                </CardContent>
                                <CardActions className="padding16">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: 'auto', textTransform: "none" }}
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                    >Save
                                    </Button>
                                </CardActions>
                            </form>
                        </Card>
                    </Grid>
                </Grid>

                <Grid item container xs={12} md={7}>
                    <Can I="view" a={SUBJECTS.LOAD_CSV}>
                        <Grid item xs={12} className={classes.marginBottom10}>
                            <Card style={{ marginBottom: 16, minHeight: '190px' }}>
                                <CardContent>
                                    <Grid container justifyContent="space-between">
                                        <h5>Bulk Upload</h5>
                                        <HelperButton trigger='csv_upload' />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.marginBottom10}>
                                            <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                                                <InputLabel className={classes.selectLabel} ref={inputLabel} id="fileLabel">
                                                    Select File Type*
                                            </InputLabel>
                                                <MuiSelect
                                                    labelId="fileLabel"
                                                    id="file-label"
                                                    name="fileLabel"
                                                    value={values.fileLabel}
                                                    onChange={v => handleSelect(v)}
                                                    fullWidth
                                                >
                                                    <MenuItem value="">Select CSV File Type</MenuItem>
                                                    <MenuItem value="ldap_user">LDAP user CSV</MenuItem>
                                                    <MenuItem value="ldap_customer">LDAP customer CSV</MenuItem>
                                                </MuiSelect>
                                            </FormControl>
                                            <ErrorHelperText error={errors.fileLabel} />
                                        </Grid>
                                        <Grid item container xs={12} className={classes.marginBottom10}>
                                            <Grid item xs={8}>
                                                <FormControl className={classes.formControl} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <label htmlFor="csv-file">
                                                        <input 
                                                            id="csv-file"
                                                            name="file"
                                                            type="file"
                                                            accept=".csv"
                                                            onChange={handleFileUpload}
                                                        />
                                                        {/* <Button className={classes.buttonLabel} variant="contained" size="small" component="span"
                                                            startIcon={<PublishIcon style={{ color: '#666' }} />}>
                                                            Choose file
                                                    </Button> */}
                                                    </label>
                                                    {/* {uploadFile && <Typography style={{ marginLeft: 10 }}>{uploadFile.name}</Typography>} */}
                                                </FormControl>
                                                <ErrorHelperText error={errors.uploadFile} />
                                                <Typography style={{ paddingTop: 10 }}>*csv files only</Typography>
                                            </Grid>
                                            <Grid item xs={4} className={classes.marginBottom10}>
                                                <Button
                                                    color="primary"
                                                    onClick={handleUploadSubmit}
                                                    className={`${classes.buttonLabel} w3-right`}
                                                    variant="contained"
                                                    size="small"
                                                    component="span"
                                                >
                                                    Upload
                                                </Button>
                                            </Grid>
                                            {values.fileLabel !== "" && (<Grid item xs={12}>
                                                <Divider style={{marginTop:4, marginBottom:4}}/>
                                                <MuiLink 
                                                 component="button"
                                                 variant="body2"
                                                 onClick={(e:any)=>handleDownload(e,values.fileLabel)}>
                                                    {values.fileLabel === 'ldap_user' && 'Sample User File'}
                                                    {values.fileLabel === 'ldap_customer' && 'Sample Customer File'}
                                                </MuiLink>
                                            </Grid>)}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Can>
                    <Can I="manage" a={SUBJECTS.USER_CHANNEL_HIDDEN}>
                        <SubscriptionChannelListing
                            trigger={triggerChannelListing}
                        ><Button
                            variant="contained"
                            size="medium"

                            style={{ marginLeft: 'auto', textTransform: 'none' }}
                            color="primary"
                            onClick={() => { setOpen(true) }}
                        >Add Subscription Channel</Button>
                        </SubscriptionChannelListing>
                    </Can>
                    {customerId &&
                        <Can I="manage" this={subject(SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,{customerId:`${customer.id}`})} field="customerId">
                            <Grid item xs={12}>
                                <CustomerModal type="adminEdit" customerId={customer.id} />
                            </Grid>
                        </Can>
                    }
                </Grid>
                {open ? (
                    <DialogModal
                        fullWidth
                        isOpen={open}
                        hideTitle={true}
                        hideBtns={true}
                        maxWidth="sm"
                        dialogContent={(
                            <SubscriptionChannelModal
                                customerId={customerId}
                                handleNotification={(someData: any) => {
                                }}
                                handleClose={() => {
                                    setTriggerChannnelListing(true);
                                    setOpen(false);
                                }}
                            />
                        )}
                    />) : null}
                {confirmData ? <ConfirmDialog
                    handleCancel={() => {
                        setConfirmData(false);
                    }}
                    handleOk={() => {
                        handleMondayReminder()
                    }}
                    message={'Do you really want to send the weekly reminder to all the users ?'}
                /> : null}
                {alertMessage
                    ? (
                        <AlertDialog
                            handleClose={() => {
                                setAlertMessage(null);
                                if (flag === 1) {
                                    window.location.reload();
                                    navigate('/login');
                                }
                                // navigate('/dashboard');
                                if (valuee === 1) {
                                    navigate(`/customer/${customerId}/settings`);
                                }else  if (flag === 0) {
                                    dispatch(getCurrentUser())
                                     navigate(customerId ? `/customer/${customerId}/settings`:`/settings`);
                                } else {
                                    navigate('/customers');
                                }

                            }}
                            message={alertMessage}
                            type={msgType}
                            handleDialogClose={() => {
                                setAlertMessage(null)
                                setUploadFile(null)
                                setValues({
                                    fileLabel: ''
                                })
                            }}
                        />
                    ) : null}
                {showLoader && <Loader open={showLoader} />}
            </Grid>
        </div>
    );
};

export default Settings;

