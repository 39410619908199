import React, { useState } from 'react'
import { TableContainer, Table, TableRow, TableHead, TableCell, TableBody, Grid, Box, Button, Tooltip, IconButton, Paper, TextField } from '@material-ui/core';
import DialogModal from '../Common/DialogModal'
import DeleteIcon from '@material-ui/icons/Delete';
import SummaryRemainderEmailModal from './SummaryRemainderEmailModal'
import TableUI from '../Common/TableUI';
import HelperButton from '../Common/HelperButton';
import { useEffect } from 'react';
import { useMemo } from 'react';
function SummaryRemainderArea(props: any) {
    const { onDelete, tableData, value, onChange, onAdd, errorMsg, classes } = props;
    const [summaryOpen, setSummaryOpenModal] = useState(false);
    const [emailSearchValue, setEmailSearchValue] = useState('')
    const [emailList, setEmailList] = useState([]);
    const tableColumns = ['#', 'Email Address', 'Action']
    let manageSummaryEmailsList: any = null;
    let summaryEmailsListTable: any = [];

    useEffect(() => {
        const result: any[] = [];
        const max = emailSearchValue === '' ? 1000000 : 30;
        const searchStr = emailSearchValue.toLowerCase();
        for (const item of tableData) {
            if (item.email.indexOf(searchStr) >= 0) {
                result.push(item)
            }
            if (result.length > max) {
                break;
            }
        }
        setEmailList(result);
    }, [emailSearchValue, tableData])

    const onEmailSubmit = (e: any) => {
        onAdd(e);
        // setSummaryOpenModal(false);
    }
 
    summaryEmailsListTable = emailList
        .map((row: any, index: number) => {
            return (
                <TableRow key={`row_${index}`} >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell className={classes.tDef} align="left">{row.email}</TableCell>
                    <TableCell align="left">
                         <Box component="div" display="flex" alignItems="left" justifyContent="left">
                            <Tooltip title="Delete"> 
                                <IconButton
                                    aria-label="delete"
                                    className="zero-padding"
                                    onClick={(e) => onDelete(e, row.email)}
                                >
                                    <DeleteIcon className="cursor-pointer"
                                        style={{ color: 'red' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box> 
                    </TableCell>
                </TableRow>
            )
        })

    if (tableData && tableData.length > 0) {
        manageSummaryEmailsList = summaryEmailsListTable;
    } else if (tableData && tableData.length == 0) {
        manageSummaryEmailsList = (
            <TableRow>
                <TableCell colSpan={4} align="center">
                    <h5>
                        No Emails Found
                    </h5>
                </TableCell>
            </TableRow>
        );
    }

    const handleSearchChange = (event: any) => {
        setEmailSearchValue(event.target.value)
    } 

    return (
        <div>
            <Grid component={Paper} elevation={0} container justifyContent="flex-end" alignItems="flex-end">
                <div style={{ flexGrow: 1, marginLeft: 5 }}>
                    <TextField
                        margin="dense"
                        size="small"
                        id="search"
                        value={emailSearchValue}
                        InputProps={{ className: classes.input }}
                        onChange={(e: any) => handleSearchChange(e)}
                        label="Search Email IDs"
                        type="search"
                        variant="outlined"
                    />
                </div>
                <div style={{ marginRight: 5 }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={`${classes.button}`}
                        onClick={() => { setSummaryOpenModal(true) }}
                        style={{ margin: 10 }} >
                        Add Emails
                    </Button>
                    <HelperButton trigger="summary_emails_helper" />
                </div>
                <TableUI
                    tableContent={manageSummaryEmailsList}
                    classes={classes}
                    stickyHeader={true}
                    tableColumns={tableColumns}
                    scrollable={true}
                    borderTop={true}
                />
            </Grid>


            {summaryOpen ? (<DialogModal
                fullWidth
                isOpen={summaryOpen}
                hideTitle={true}
                hideBtns={true}
                maxWidth="sm"
                dialogContent={(

                    <SummaryRemainderEmailModal
                        value={value}
                        onChange={onChange}
                        onAdd={onEmailSubmit}
                        onDelete={onDelete}
                        handleClose={() => {
                            setSummaryOpenModal(false)
                        }
                        }
                        errorMsg={errorMsg}
                    />
                )}
            />) : null}

        </div>
    )
}

export default SummaryRemainderArea;