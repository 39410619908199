import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postCreateTeam, putEditTeam } from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';

const useStyles = makeStyles(theme => ({
  modalHeader: {
    padding: '15px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    textAlign: 'left',
    borderBottom:'1px solid black',
    marginBottom: 10
  },
  formGroup: {
    padding: '30px 40px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    marginLeft: '0px',
  },
  label: {
    background: 'white',
    padding: '0px 5px'
  },
  buttonLabel: {
    textTransform: 'none',
  },
}));

const TeamModal = (props: any) => {
  const { handleClose, editData, customerId, triggerList } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [alertMessage, setAlertMessage] = useState<any>();
  const [ showLoader, setShowLoader ] = useState(false);
  const error: any = {};
  const [errors, setErrors] = useState(error);
  let formData: any = {
    teamNo: '',
    name: '',
    department: '',
    location: '',
    teamDate: null,
    users: [],
  };
  if (editData) {
    formData = editData;
  }
  const [form, setForm] = useState(formData);
  const modalTitle: string = editData ? 'Edit Team' : 'Add New Team';
  const [msgType, setMsgType] = useState("success");


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const data: any = Object.assign({}, form);
    data[name] = value;
    const err = Object.assign({}, errors);
    if (err[name]) {
      delete err[name];
    }
    setErrors(err);
    setForm(data);
  };

  const showAlert = (msg: any, type: any) => {
    setAlertMessage(msg);
    setMsgType(type);
  }

  const updateResponse = (resp: any, message: string) => {
    const res = resp && resp.data;
    let msg = res.message;
    let type = "fail";
    if (res && res.success) {
      msg = message;
      type = "success";
    }
    setShowLoader(false);
    triggerList();
    showAlert(msg, type);
  };

  const handleDialogClose = () => {
    setAlertMessage(null);
  };


  const validateData = () => {
    const err: any = {};
    if (!form.name) {
      err['name'] = 'Field is Required';
    }
    if (!form.department) {
      err['department'] = 'Field is Required';
    }
    // if (!form.status) {
    //   err['status'] = 'Field is Required';
    // }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const checkForm = validateData();
    if (checkForm) {
      Object.assign(form, {
        customerId
      });
      setShowLoader(true);
      if (form.id) {
        dispatch(putEditTeam(form, form.id)).then((resp: any) => {
          updateResponse(resp, 'Team edited successfully');
        });
      } else {
        dispatch(postCreateTeam(form))
          .then((resp: any) => {
            updateResponse(resp, 'Team created successfully');
          });
      }
    }
  };


  return (
    <div style={{paddingBottom:'20px'}}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.title}>
              {modalTitle}
          </Grid>
        <Grid item xs={12} className={classes.marginBottom10}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Name"
            margin="dense"
            name="name"
            id="name"
            value={form.name || ''}
            onChange={handleChange}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />
          <ErrorHelperText error={errors.name} />
        </Grid>
        {/* <Grid item xs={12} className={classes.marginBottom10}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusLabel">
              Select Status*
            </InputLabel>
            <MuiSelect
              labelId="statusLabel"
              id="status"
              name="status"
              value={form.status}
              onChange={v => handleChange(v)}
              fullWidth
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
            </MuiSelect>
          </FormControl>
          <ErrorHelperText error={errors.status} />
        </Grid> */}
        <Grid item xs={12} className={classes.marginBottom10}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label="Department"
            margin="dense"
            name="department"
            id="department"
            value={form.department || ''}
            onChange={handleChange}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />
          <ErrorHelperText error={errors.department} />
        </Grid>

        <Grid item xs={12} className={classes.marginTop10}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={handleClose}
              >Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={`${classes.buttonLabel}`}
                onClick={handleSubmit}
              >
                {'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {alertMessage ?
        (
          <AlertDialog
            handleClose={() => {
                setAlertMessage(null);
                handleClose();
            }}
            message={alertMessage}
            type={msgType}
            handleDialogClose={handleDialogClose}
          />
        )
        : null
      }
      { showLoader ? <Loader open={showLoader}/> : null}
    </div>
  );
}

export default TeamModal;