import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import { Can } from '../../Context/Can';
import { userRoles } from '../../Context/userRoles';
import { useNavigate } from 'react-router';
import { SUBJECTS } from '../../utils/contants';

const Dashboard = () => {
    const navigate = useNavigate()
    const state: any = useSelector(state => state);
    const { currentUserRoles } = state;
    if (!currentUserRoles || currentUserRoles.isFetching) {
        return (
            <div className="w3-center">
                <CircularProgress />
            </div>
        );
    } 
    
    if ( currentUserRoles.data && currentUserRoles.data.success && currentUserRoles.data.data) {
        const userRole = currentUserRoles.data.data;
        let customerId;
        const checkCustomerAdmin = userRole.findIndex((check: any) => {
            if (check.roles && check.role.type === userRoles.CUSTOMER_ADMIN) {
                customerId = check.customerId;
                return true;
            }
        });
        const checkTeamLead = userRole.findIndex((check: any) => {
            if (check.roles && check.role.type === userRoles.TEAM_LEAD) {
                customerId = check.customerId;
                return true;
            }
        });
        const checkTeamMember = userRole.findIndex((check: any) => {
            if (check.roles && check.role.type === userRoles.TEAM_MEMBER) {
                customerId = check.customerId;
                return true;
            }
        });
        if ((checkCustomerAdmin !== -1) || (checkTeamLead !== -1) || (checkTeamMember !== -1)) {
            navigate(`/customer/${customerId}`);
        }
    }
    return <Can I="view" a={SUBJECTS.ADMIN_DASHBOARD}>
        <AdminDashboard />
    </Can>
};

export default Dashboard;