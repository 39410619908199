
import React from 'react';
import { IconButton } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
declare global {
    interface Window {
        macawSDK: any
    }
}

export default function HelperButton(props: any) {
    return <span className="w3-hide">
        <IconButton onClick={() => window.macawSDK.triggerHelp(props.trigger)}
            size="small">
            <HelpOutlineIcon />
        </IconButton>
    </span>
}

