import React from 'react';
import { Grid, Stepper, StepLabel, Step, CircularProgress, StepButton, Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { SCHEDULED_EVENT_STATUS_COLOR } from '../../utils/contants';
import AddAlarmRoundedIcon from '@material-ui/icons/AddAlarmRounded';
import AlarmOnRoundedIcon from '@material-ui/icons/AlarmOnRounded';
import AlarmOffRoundedIcon from '@material-ui/icons/AlarmOffRounded';
import SnoozeRoundedIcon from '@material-ui/icons/SnoozeRounded';
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 'auto',
        overflow: 'auto',
        textAlign: 'justify',
    },
    headerText: {
        padding: 3,
        paddingLeft: 15
    }
}));


const StepIcon = ({status}) => {
    let stepIcon = null;
    if (status === 'add') {
        stepIcon = <AddAlarmRoundedIcon className={'w3-text-grey'}/>
    }else if (status === 'modify') {
        stepIcon = <AccessAlarmsRoundedIcon className={'w3-text-grey'}/>
    }else if (status === 'Paused') {
        stepIcon = <SnoozeRoundedIcon className={'w3-text-grey'}/>
    } else if (status === 'Enabled') {
        stepIcon = <AlarmOnRoundedIcon className={'w3-text-grey'}/>
    }else if (status === 'Disabled') {
        stepIcon = <AlarmOffRoundedIcon className={'w3-text-grey'}/>
    }else {
        stepIcon = <FiberManualRecordRoundedIcon className={'w3-text-grey'}/>
    }
    return (<div className='w3-display-container'>
        {stepIcon}
    </div>)
};

const StepperLabel = ({data})=>{
    let stepContent = null;
    if (data.eventAction === 'add') {
        stepContent = (
            <>
                <Tooltip arrow title={data.user.uniqueId} placement="bottom"><span style={{marginRight:5}}>{data.user.name}</span></Tooltip><span className="w3-tag w3-padding-tiny w3-round w3-green">Created</span><span style={{marginLeft:5,marginRight:5}}>this event</span><Tooltip arrow title={moment(data.actionAt).format('MMM Do YYYY, hh:mm a')} placement="bottom"><span>{moment(data.actionAt).fromNow()}</span></Tooltip>
            </>
        )
    }else if (data.eventAction === 'modify') {
        stepContent = (
            <>
                <Tooltip arrow title={data.user.uniqueId} placement="bottom"><span style={{marginRight:5}}>{data.user.name}</span></Tooltip><span className="w3-tag w3-padding-tiny w3-round w3-light-green">Modified</span><span style={{marginLeft:5,marginRight:5}}>this event</span><Tooltip arrow title={moment(data.actionAt).format('MMM Do YYYY, hh:mm a')} placement="bottom"><span>{moment(data.actionAt).fromNow()}</span></Tooltip>
            </>
        )
    }else if ((data.eventAction === 'Paused')||(data.eventAction === 'Enabled') || (data.eventAction === 'Disabled')) {
        stepContent = (
            <>
                <Tooltip arrow title={data.user.uniqueId} placement="bottom"><span style={{marginRight:5}}>{data.user.name}</span></Tooltip><span className={`w3-tag w3-padding-tiny w3-round ${SCHEDULED_EVENT_STATUS_COLOR[data.eventAction]}`}>{data.eventAction}</span><span style={{marginLeft:5,marginRight:5}}>this event</span><Tooltip arrow title={moment(data.actionAt).format('MMM Do YYYY, hh:mm a')} placement="bottom"><span>{moment(data.actionAt).fromNow()}</span></Tooltip>
            </>
        )
    } 
    return stepContent?<StepLabel><Box component="div" display="flex" alignItems="flext-start" alignContent="flex-start" justifyContent="flex-start" flexWrap="wrap">{stepContent}</Box></StepLabel> : null 
}

export default function ActivityLog(props: any) {

    const { topic } = props;
    const classes = useStyles();
    let activityList: any = [];
    let activityData: any = null;
    if (topic.activityLog && topic.activityLog.length) {
        const topicLogs = topic.activityLog.filter((item:any)=>item.eventEntity === 'topic')
                        .sort((a:any, b:any)=> moment(a.actionAt).diff(b.actionAt)).reverse();
        activityList = (
            <Stepper nonLinear orientation="vertical">
                {topicLogs.map((item: any, idx: number) => (
                    <Step key={`activity-${idx}`} completed={idx === 0}>
                        <StepButton
                            icon={<StepIcon status={item.eventAction} />}
                        >
                            <StepperLabel data={item} />
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        )
    }

    if (!topic) {
        activityData = (
            <div className="w3-center">
                <CircularProgress />
            </div>
        )
    } else if (topic.activityLog && topic.activityLog.length) {
        activityData = activityList
    } else if (topic.activityLog && topic.activityLog.length == 0) {
        activityData = (<div className="w3-center">
            <h5>No activities yet</h5>
        </div>)
    }



    return (
        <Grid container className="w3-white w3-margin-bottom">
            <Grid item xs={12} >
                <div className={`${classes.headerText} w3-border-bottom`}>
                    <Grid container justifyContent="space-between">
                        <h5>Activity Log</h5>
                    </Grid>
                </div>
                <div className={classes.root}>
                    {activityData}
                </div>
            </Grid>
        </Grid>
    )
}
