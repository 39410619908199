import { Button, Grid, IconButton, makeStyles, Paper, Box, Chip, Breadcrumbs, Typography, TableRow, CircularProgress, TableCell, Table, TableContainer } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTopicList, changeUserName, getCurrentUser } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import TitleHeader from '../Common/TitleHeader';
import AlertFilters from './AlertFilters';
import moment from 'moment';
import { Can } from '../../Context/Can';
import ConfirmDialog from '../Common/ConfirmDialog';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useSearchParams, useNavigate } from 'react-router-dom';
import DialogModal from '../Common/DialogModal';
import SubscriptionModal from '../Subscriptions/SubscriptionModal';
import Pagination from '../Common/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import HelperButton from '../Common/HelperButton';
import { removeNonValueFilters } from '../../utils';
import { SUBJECTS } from '../../utils/contants';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '8px',
    },
    buttonLabel: {
        marginTop: -35,
        textTransform: "none",
    },
    breadCrumb: {
        color: "#0085ad",
        paddingTop: 10,
    },
    arrowButton: {
        marginTop: -45,
    },
    paginateTopPadding: {
        paddingTop: '50px'
    },

}));

const limit = 10;

function ManageAlerts(props: any) {
    const navigate = useNavigate()
    const { customerId, customer } = props
    const state: any = useSelector(st => st);
    const { currentUser } = state
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const [isLoading, setIsLoading] = useState(false);
    const initialData: any[] = [];
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState(initialData);
    const [openModal, setOpenModal] = useState(false);
    const [creationTrigger, setCreationTrigger] = useState(false);
    const [topicId, setTopicId] = useState<any>();
    const [showConfirmDialog, setShowConfirmDialog] = useState<any>(false);
    const [alertType, setAlertType] = useState<any>(null);
    const [subscriptionTrigger, setSubscriptionTrigger] = useState<any>(false)
    const [topicTitle, setTopicTitle] = useState<any>();
    let manageAlerts: any = null;
    const userId = currentUser.data.data.id
    const incidentAlert = currentUser.data.data?.json?.jSubCheckBox?.incidentAlert || false
    const maintenanceAlert = currentUser.data.data?.json?.jSubCheckBox?.maintenanceAlert || false

    const fetchData = () => {
        let page: number = parseInt(queryParams.get("page"));
        const params = {
            ...Object.fromEntries(queryParams),
            id: customerId,
            offset: (page ? page - 1 : 0) * limit,
        }
        setIsLoading(true);
        getTopicList(params).then((res: any) => {
            const respData = res.data
            setData(respData.data);
            setTotalCount(respData.total);
            setIsLoading(false);
        }).catch((e) => {
            console.error(e);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);



    const handleAlertFilters = (filters: any) => {
        updateQueryParams(Object.assign({}, filters, { page: 1 }));
    }

    const updateQueryParams = (params: any) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleSearch = (value: string) => {
        updateQueryParams({ search: value, page: 1 });
    }

    const triggerSubscription = (topicId: any, topicTitle: any) => {
        setTopicId(topicId)
        setTopicTitle(topicTitle)
        setOpenModal(true)
    }

    const handlePagination = (page: number, limit2: number) => {
        updateQueryParams({ page, limit: limit2 });
    };

    useEffect(() => {
        fetchData();
    }, [queryParams]);


    const handleSubscription = () => {
        const data = {
            username: '',
            subCheckBox: {
                incidentAlert: alertType === 'Alerts' ? true : null,
                maintenanceAlert: alertType === 'Maintenance Windows' ? true : null
            }
        }

        if (data) {
            dispatch(changeUserName(data)).then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    dispatch(getCurrentUser())
                } else if (res && res.data) {
                    // console.log(res.data);
                } else {
                    console.error('Something went wrong. Try again...!');
                }
            });
        }
        setSubscriptionTrigger(true)
        setShowConfirmDialog(false)
    }



    let alertListTable: any[] = [];
    if (data && data.length > 0) {
        alertListTable = data.map((alert: any, index: any) => {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="w3-margin-top" container direction="row" component={Paper}>
                        <Grid container item lg={9} sm={9} md={9} className="w3-padding" spacing={2} direction="row" >
                            <Grid item sm={12}>
                                <h5>{alert.title}
                                    <Chip
                                        className="w3-margin-left"
                                        size="small"
                                        label={alert.eventType == 'scheduledEvent' ? 'Maintenance Window' : 'Alert'} />
                                    <Chip
                                        className="w3-margin-left"
                                        size="small" label={alert.type} />
                                    <Chip
                                        className="w3-margin-left"
                                        size="small" label={alert.environment} />
                                </h5>
                            </Grid>
                            <Grid item sm={12} >
                                {alert.parent && alert.parent.instanceList.map((instance: any) => {
                                    return <div className="w3-small">
                                        <span >Next Event :<b> {moment(instance.eventTime).format('MMM Do YYYY, hh:mm a')}</b>  </span>
                                        {instance.nextReminderAt && (<span className="w3-margin-left w3-inline-block">Next Reminder :
                                            <b> {moment(instance.nextReminderAt).format('MMM Do YYYY, hh:mm a')}</b>
                                            <i> {moment(instance.nextReminderAt).fromNow()}</i>
                                        </span>)}
                                    </div>
                                })}

                            </Grid>
                        </Grid>
                        <Grid lg={3} sm={3} md={3} justifyContent="flex-end" alignItems="flex-end" item container >
                            <Grid justifyContent="flex-end" alignItems="flex-end" item container  >
                                <Can do="manage" on={SUBJECTS.MANAGE_PATCH_WINDOW}>
                                    <IconButton className="w3-margin-bottom" aria-label="Send" onClick={() => {
                                        navigate(`/customer/${customerId}/alerts/${alert.id}/edit`)
                                    }}>
                                        <EditIcon className="cursor-pointer" />
                                    </IconButton>
                                </Can>

                                <IconButton className="w3-margin-bottom" aria-label="Send" onClick={() => {
                                    navigate(`/customer/${customerId}/alerts/${alert.id}`)
                                }}>
                                    <ArrowRightIcon className="cursor-pointer" />
                                </IconButton>

                            </Grid>
                            <Can not do="manage" on={SUBJECTS.MANAGE_PATCH_WINDOW}>
                                {alert.eventType === 'oneTimeEvent' ? <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                        // triggerSubscription(alert.id, alert.title)
                                        setAlertType('Alerts')
                                        setShowConfirmDialog(true)

                                    }}
                                    color='primary'
                                    disabled={incidentAlert ? true : false}
                                >
                                    {incidentAlert ? 'Subscribed' : 'Subscribe'}
                                </Button> :
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            // triggerSubscription(alert.id, alert.title)
                                            setAlertType('Maintenance Windows')
                                            setShowConfirmDialog(true)
                                        }}
                                        color='primary'
                                        disabled={maintenanceAlert ? true : false}
                                    >
                                        {maintenanceAlert ? 'Subscribed' : 'Subscribe'}
                                    </Button>
                                }
                            </Can>
                        </Grid>
                    </Grid>
                </Grid>

            )
        })
    }

    if (isLoading || !data) {
        manageAlerts = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <div className="w3-center">
                        <CircularProgress />
                    </div>
                </TableCell>
            </TableRow>
        );
    } else if (data && data.length) {
        manageAlerts = alertListTable;
    } else if (data && data.length === 0) {
        manageAlerts = (
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            <h5>
                                No Alerts Found
                            </h5>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        );
    }

    return (
        <div>
            <CustomerBreadcrumbs customer={customer} >
                <Typography color="textPrimary">Alerts</Typography>
                <HelperButton trigger="customer_alerts_helper" />

            </CustomerBreadcrumbs>

            <TitleHeader
                title="Alerts"
                showSearch={true}>
                <div style={{ marginRight: '10px' }}>
                    <SearchBox
                        value={queryParams.get("Search") || ''}
                        placeholder={`Search ...`}
                        search={handleSearch}
                    />
                </div>
                <AlertFilters
                    customerId={customerId}
                    filterData={existingParams}
                    handleAlertFilters={handleAlertFilters}
                />
            </TitleHeader>
            {manageAlerts}
            {/* <Grid container spacing={2}>
                {data && data.length > 0 && data.map((alert, index) => {
                    return (
                        <Grid item xs={12} className="w3-margin-top" container direction="row" component={Paper}>
                            <Grid container item lg={9} sm={9} md={9} className="w3-padding" spacing={2} direction="row" >
                                <Grid item sm={12}>
                                    <h5>{alert.title}
                                        <Chip
                                            className="w3-margin-left"
                                            size="small"
                                            label={alert.eventType == 'scheduledEvent' ? 'Maintenance Window' : 'Alert'} />
                                        <Chip
                                            className="w3-margin-left"
                                            size="small" label={alert.type} />
                                        <Chip
                                            className="w3-margin-left"
                                            size="small" label={alert.environment} />
                                    </h5>
                                </Grid>
                                <Grid item sm={12} >
                                    {alert.parent && alert.parent.instanceList.map((instance: any) => {
                                        return <div className="w3-small">
                                            <span >Next Event :<b> {moment(instance.eventTime).format('MMM Do YYYY, hh:mm a')}</b>  </span>
                                            {instance.nextReminderAt && (<span className="w3-margin-left w3-inline-block">Next Reminder :
                                                <b> {moment(instance.nextReminderAt).format('MMM Do YYYY, hh:mm a')}</b>
                                                <i> {moment(instance.nextReminderAt).fromNow()}</i>
                                            </span>)}
                                        </div>
                                    })}

                                </Grid>
                            </Grid>
                            <Grid lg={3} sm={3} md={3} justify="flex-end" alignItems="flex-end" item container >
                                <Grid justify="flex-end" alignItems="flex-end" item container  >
                                    <Can do="manage" on="ManagePatchWindow">
                                        <IconButton className="w3-margin-bottom" aria-label="Send" onClick={() => {
                                            navigate(`/customer/${customerId}/alerts/${alert.id}/edit`)
                                        }}>
                                            <EditIcon className="cursor-pointer" />
                                        </IconButton>
                                    </Can>

                                    <IconButton className="w3-margin-bottom" aria-label="Send" onClick={() => {
                                        navigate(`/customer/${customerId}/alerts/${alert.id}`)
                                    }}>
                                        <ArrowRightIcon className="cursor-pointer" />
                                    </IconButton>

                                </Grid>
                                <Can not do="manage" on="ManagePatchWindow">
                                    {alert.eventType === 'oneTimeEvent' ? <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            triggerSubscription(alert.id, alert.title)
                                        }}
                                        color='primary'
                                        disabled={incidentAlert ? true : false}
                                    >
                                        {incidentAlert ? 'Subscribed' : 'Subscribe'}
                                    </Button> :
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                triggerSubscription(alert.id, alert.title)
                                            }}
                                            color='primary'
                                            disabled={maintenanceAlert ? true : false}
                                        >
                                            {maintenanceAlert ? 'Subscribed' : 'Subscribe'}
                                        </Button>
                                    }
                                </Can>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid> */}


            {(data && data.length > 0 && totalCount > limit) && (
                <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
                    <Pagination
                        cPage={parseInt(queryParams.get("page"))}
                        defaultPerPage={limit}
                        data={{ totalCount }}
                        onChange={handlePagination}
                    />
                </Grid>)}

            {openModal ? (<DialogModal
                fullWidth
                isOpen={openModal}
                hideTitle={true}
                hideBtns={true}
                maxWidth="sm"
                dialogContent={(
                    <SubscriptionModal
                        isDisabled={true}

                        topicId={topicId}
                        topicTitle={topicTitle}
                        customerId={customerId}
                        handleClose={() => {
                            setCreationTrigger(true);
                            setOpenModal(false);
                        }}
                    />
                )}
            />)
                : null}
            {
                showConfirmDialog
                    ? (
                        <ConfirmDialog
                            handleCancel={() => {
                                setShowConfirmDialog(false)
                            }}
                            message={`This will get you subscribed to all ${alertType}. Are you sure ?`}
                            handleOk={handleSubscription}

                        />
                    ) : null
            }

        </div >
    )
}

export default ManageAlerts
