import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability, subject } from '@casl/ability'
import { userRoles } from './userRoles';
import { SUBJECTS } from '../utils/contants';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);


export function updateAbility(role, ability) {
    const { can, rules } = new AbilityBuilder();

    role.forEach(item => {
        console.log('customer: ', item?.customer?.name,'-- Role -->', item?.role?.name);
        let viewSubjects = [];
        let manageSubjects = [];
        let attr = {};
        if (item.role.type === userRoles.XYLEM_ADMIN) {
            viewSubjects = [
                SUBJECTS.ADMIN_DASHBOARD,
                SUBJECTS.ADMIN_SETTINGS,
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.DISTRIBUTION_LIST,
                SUBJECTS.PATCH_WINDOWS,
                SUBJECTS.SETTINGS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
                SUBJECTS.XYLEM_USERS,
            ];
            manageSubjects = [
                SUBJECTS.ADD_CUSTOMERS,
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.EDIT_CUSTOMERS,
                SUBJECTS.EDIT_PATCH_INSTANCE,
                SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,
                SUBJECTS.LOAD_CSV,
                SUBJECTS.MANAGE_PATCH_WINDOW,
                SUBJECTS.MANAGE_TOPIC,
                SUBJECTS.MEMBER,
                SUBJECTS.TEAM,
                SUBJECTS.VIEW_TOPIC,
                SUBJECTS.WEEKLY_REMINDERS,
                SUBJECTS.XYLEM_USERS,
            ];
        } else if (item.role.type === userRoles.XYLEM_PATCH_ADMIN) {
            viewSubjects=[
                SUBJECTS.ADMIN_DASHBOARD,
                SUBJECTS.ADMIN_SETTINGS,
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.DISTRIBUTION_LIST,
                SUBJECTS.PATCH_WINDOWS,
                SUBJECTS.SETTINGS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ];
            manageSubjects= [
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.TEAM,
                SUBJECTS.MEMBER,
                SUBJECTS.MANAGE_TOPIC,
                SUBJECTS.VIEW_TOPIC,
                SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,
                SUBJECTS.MANAGE_PATCH_WINDOW,
                SUBJECTS.EDIT_PATCH_INSTANCE,
                SUBJECTS.WEEKLY_REMINDERS,
            ];
        } else if (item.role.type === userRoles.XYLEM_CUSTOMER_ADMIN) {
            viewSubjects=[
                SUBJECTS.ADMIN_DASHBOARD,
                SUBJECTS.ADMIN_SETTINGS,
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.DISTRIBUTION_LIST,
                SUBJECTS.SETTINGS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ];
            manageSubjects= [
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.TEAM,
                SUBJECTS.MEMBER,
                SUBJECTS.MANAGE_TOPIC,
                SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,
                SUBJECTS.VIEW_TOPIC,
            ];
        } else if (item.role.type === userRoles.XYLEM_SERVICE_USER) {
            viewSubjects=[
                SUBJECTS.ADMIN_DASHBOARD,
                SUBJECTS.ADMIN_SETTINGS,
                SUBJECTS.ALL_CUSTOMERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.SETTINGS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ];
        } else if (item.role.type === userRoles.CUSTOMER_ADMIN) {
            viewSubjects=[
                SUBJECTS.ALERTS,
                SUBJECTS.BREADCRUMB,
                SUBJECTS.CHANNEL_SETTINGS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.DISTRIBUTION_LIST,
                SUBJECTS.SETTINGS,
                SUBJECTS.SUBSCRIPTION_BUTTON,
                SUBJECTS.SUBSCRIPTIONS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ];
            
            manageSubjects= [
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.DISTRIBUTION_LIST,
                SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,
                SUBJECTS.MEMBER,
                SUBJECTS.TEAM,
                SUBJECTS.USER_CHANNELS,
                SUBJECTS.VIEW_TOPIC,
            ]
            attr = {
                customerId:item.customerId
            };
        } else if (item.role.type === userRoles.TEAM_LEAD) {
            viewSubjects=[
                SUBJECTS.ALERTS,
                SUBJECTS.BREADCRUMB,
                SUBJECTS.CHANNEL_SETTINGS,
                SUBJECTS.CUSTOMER_USERS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.SETTINGS,
                SUBJECTS.SUBSCRIPTION_BUTTON,
                SUBJECTS.SUBSCRIPTIONS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ]
            
            manageSubjects= [
                SUBJECTS.MEMBER,
                SUBJECTS.VIEW_TOPIC,
                SUBJECTS.USER_CHANNELS,
            ]
            attr = {
                customerId:item.customerId
            };
        } else if (item.role.type === userRoles.TEAM_MEMBER) {
            viewSubjects=[
                SUBJECTS.ALERTS,
                SUBJECTS.BREADCRUMB,
                SUBJECTS.CHANNEL_SETTINGS,
                SUBJECTS.DASHBOARD,
                SUBJECTS.SETTINGS,
                SUBJECTS.SUBSCRIPTION_BUTTON,
                SUBJECTS.SUBSCRIPTIONS,
                SUBJECTS.TEAMS,
                SUBJECTS.TOPICS,
            ];
            
            manageSubjects = [
                SUBJECTS.USER_CHANNELS,
                SUBJECTS.VIEW_TOPIC
            ];
            attr = {
                customerId:item.customerId
            };
        }
        can('view',viewSubjects, attr);
        can('manage',manageSubjects, attr);
    });
    console.log('rules: ', rules);
    ability.update(rules);
}

export const getAbility = () => {
    return new Ability([]);
}
