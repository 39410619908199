import { Button, FormControl, Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';
import { acceptOnlyNumbersInput } from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom:'1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        marginLeft: '0px',
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: 'none',
    },
}));

const CreateReminderModal = (props: any) => {
  const { handleClose, editData, handleReminder, topicId } = props;
  const classes = useStyles();
  const [alertMessage, setAlertMessage] = useState<any>();
  const showLoader=false;
  const navigate=useNavigate()
  const [errors, setErrors] = useState<any>({
    offset: '',
    duration: '',
    type: '',
  });
  const [form, setForm] = useState<any>(Object.assign({
    offset: '',
    duration: '',
    type: '',
    description: '',
}, editData));
  const modalTitle: string = editData ? 'Edit Reminder' : 'Create Reminder';
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const msgType="success"

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const data: any = Object.assign({}, form);
    if(name === 'offset'){
        if(value === '' || acceptOnlyNumbersInput(value)){
          data[name] = value;
        }
    }else{
      data[name] = value;
    }
    const err = Object.assign({}, errors);
    if (err[name]) {
      delete err[name];
    }
    setErrors(err);
    setForm(data);
  };



  const handleDialogClose = () => {
    setAlertMessage(null);
  };


  const validateData = () => {
    const err: any = {};
    if (!form.offset) {
      err['offset'] = 'Field is Required';
    }
    if (!form.duration) {
      err['duration'] = 'Field is Required';
    }
    if (!form.type) {
        err['type'] = 'Field is Required';
      }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const checkForm = validateData(); 
    if (checkForm) {
      Object.assign(form, {
        json: {
          status: 'Enabled'
        },
        topicId
      });
      handleReminder(form);
      handleClose();
    }
  };


  return (
    <div style={{paddingBottom:'20px'}}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.title}>
              {modalTitle}
          </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            required
            id="offset"
            fullWidth
            variant="outlined"
            label="Time Offset"
            margin="dense"
            name="offset"
            value={form.offset || ''}
            onChange={(v)=>handleChange(v)}
            InputLabelProps={{
              classes: {
                root: classes.label
              }
            }}
          />
          <ErrorHelperText error={errors.offset} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="durationLabel">
              Duration*
            </InputLabel>
            <MuiSelect
              labelId="durationLabel"
              id="duration"
              name="duration"
              value={form.duration}
              onChange={v => handleChange(v)}
              fullWidth
            >
              <MenuItem value="minutes">Mins</MenuItem>
              <MenuItem value="hours">Hours</MenuItem>
              <MenuItem value="days">Days</MenuItem>
              <MenuItem value="weeks">Weeks</MenuItem>
            </MuiSelect>
          </FormControl>
          <ErrorHelperText error={errors.duration} />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="type">
              Notification Time*
            </InputLabel>
            <MuiSelect
              labelId="notificationTimeLabel"
              id="type"
              name="type"
              value={form.type}
              onChange={v => handleChange(v)}
              fullWidth
            >
              <MenuItem value="before">Before the Event</MenuItem>
              <MenuItem value="after">After the Event</MenuItem>
            </MuiSelect>
          </FormControl>
          <ErrorHelperText error={errors.type} />
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                multiline
                rows={5}
                id="description"
                name="description"
                margin="dense"
                label="Custom Message"
                value={form.description || ''}
                onChange={(e) => handleChange(e)}
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.label
                    }
                }}
            />
            <ErrorHelperText error={errors.description} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={handleClose}
                className={`${classes.buttonLabel}`}
              >Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={`${classes.buttonLabel}`}
                onClick={handleSubmit}
              >
                {editData ?'Save':'Add'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {alertMessage ?
        (
          <AlertDialog
            handleClose={() => {
                setAlertMessage(null);
                navigate(`/alerts`)
            }}
            message={alertMessage}
            type={msgType}
            handleDialogClose={handleDialogClose}
          />
        )
        : null
      }
      { showLoader ? <Loader open={showLoader}/> : null}
    </div>
  );
}

export default CreateReminderModal;