import React, { useState, useEffect } from 'react';
import { Button, Table, Grid, TableBody, TableHead, TableCell, TableRow, Paper, Box, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { destroyTopicReminder } from '../../Redux/actions';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';
import CreateReminderModal from './CreateReminderModal';
import TableUI from '../Common/TableUI';
import HelperButton from '../Common/HelperButton';
import ToastMessage from '../Common/ToastMessage';

const ReminderArea = (props: any) => {
  const { reminderData, topicId, classes, setAlertMessage, setMsgType, handleSetReminderData, handleTrigger } = props;
  const dispatch: any = useDispatch();
  let manageReminderList: any = null;
  const [data, setData] = useState<any>(null);
  const isLoading = false
  const [open, setOpenModal] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    open:false,
    msg:'',
    key: new Date().getTime()
  });
  const [selectedReminder, setSelectedReminder] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const tableColumns = ['Name', 'Type', 'Description', 'Action ']

  useEffect(() => {
    let modifiedData = reminderData.map((item: any) => {
      if (item.topics) {
        delete item.topics
      }
      return item;
    })

    setData(modifiedData)

  }, [reminderData]);

  const handleToastMsg = (toastMsgData:any) =>{
    setToastMsg({
      open:toastMsgData?.open,
      msg:toastMsgData?.msg,
      key: new Date().getTime(),
    });

  }


  const handleReminder = (someData: any) => {
    const newData = [...data, { ...someData }];
    handleSetReminderData(newData)
    setOpenModal(false);
    handleToastMsg({
        open:true,
        msg:`"${someData.offset} ${someData.duration} ${someData.type}" reminder added to the list`,
    })
  }

  const handleDeleteReminder = () => {
    if (selectedReminder) {
      setShowConfirmDialog(false);
      dispatch(destroyTopicReminder(selectedReminder.id)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage("Reminder has been deleted successfully");
          setSelectedReminder({});
          handleTrigger(true);
        } else if (res && !res.success) {
          setAlertMessage(res.message);
          setMsgType("fail");
          handleTrigger(true);
        } else {
          setAlertMessage(res.message);
          setMsgType("fail");
          handleTrigger(true);
        }
      });
    }
  };

  const triggerDelete = (reminder: any) => {
    setSelectedReminder(reminder);
    setShowConfirmDialog(true);
  }

  const triggerDeleteByIndex = (reminder: object, idx: number) => {
    const newData = [...data];
    const removedData = newData.splice(idx, 1)[0];
    handleSetReminderData(newData);
    handleToastMsg({
      open:true,
      msg:`"${removedData.offset} ${removedData.duration} ${removedData.type}" reminder removed from the list`,
    });
  }

  let reminderListTable: any = [];
  if (data && data.length > 0) {

    reminderListTable = data.map((item: any, idx: number) => {
      return (
        <TableRow key={item.id ? item.id : idx}>
          <TableCell className={classes.tDef} align="left">{`${item.offset} ${item.duration} ${item.type}`}</TableCell>
          <TableCell className={classes.tDef} align="left">{item.type}</TableCell>
          <TableCell className={classes.tDef} align="left">{item.description}</TableCell>
          <TableCell className={classes.tDef} align="center">
            <Box component="div" display="flex" alignItems="center" justifyContent="center">
              <Tooltip title="Delete">
                <IconButton aria-label="delete" onClick={item.id ? () => triggerDelete(item) : () => triggerDeleteByIndex(item, idx)}
                  className="zero-padding">
                  <DeleteIcon className="cursor-pointer" />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      )
    })
  }


  reminderListTable.push(<TableRow key="at-time-of-start">
    <TableCell className={classes.tDef} align="left">At Start</TableCell>
    <TableCell className={classes.tDef} align="left">Start</TableCell>
    <TableCell className={classes.tDef} align="left">When the event starts</TableCell>
    <TableCell className={classes.tDef} align="center">
    </TableCell>
  </TableRow>)
  reminderListTable.push(<TableRow key="at-time-of-end">
    <TableCell className={classes.tDef} align="left">At End</TableCell>
    <TableCell className={classes.tDef} align="left">End</TableCell>
    <TableCell className={classes.tDef} align="left">When the event ends</TableCell>
    <TableCell className={classes.tDef} align="center">
    </TableCell>
  </TableRow>)

  if (isLoading || !data) {
    manageReminderList = (
      <TableRow>
        <TableCell colSpan={4} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else {
    manageReminderList = reminderListTable;
  }
  return (
    < div >
      <Grid component={Paper} elevation={0} container justifyContent="flex-end" alignItems="flex-end">
        <div style={{marginRight:5}}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={`${classes.button}`}
            onClick={() => { setOpenModal(true) }}
            style={{ margin: 10 }}
          >
            Create Reminders
        </Button>
          <HelperButton trigger="reminders_helper" />
        </div>
        <TableUI
          tableContent={manageReminderList}
          classes={classes}
          stickyHeader={true}
          tableColumns={tableColumns}
          scrollable={false}
          borderTop={true}
        />

      </Grid>
      {open ? (<DialogModal
        fullWidth
        isOpen={open}
        hideTitle={true}
        hideBtns={true}
        maxWidth="sm"
        dialogContent={(
          <CreateReminderModal
            topicId={topicId}
            handleReminder={handleReminder}
            handleClose={() => setOpenModal(false)}
          />
        )}
      />) : null}

      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setSelectedReminder({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteReminder}
            message={'Are you sure to delete the reminder?'}
          />
        ) : null}
      {toastMsg?.open && <ToastMessage
        key={toastMsg?.key}
        open={toastMsg?.open}
        message={toastMsg?.msg || ''}
        handleClose={
          () => {
            handleToastMsg({
              open: false,
              msg: '',
            })
          }
        } />}
    </div>
  )

}

export default ReminderArea;