import React from 'react';

const WorkInProgress = () => {
    return (
        <div className="w3-center">
            <h1>Work In Progress</h1>
        </div>
    )
};

export default WorkInProgress;