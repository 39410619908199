import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Chip
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getSubscribersList, getSubscriptionListByTopicId } from '../../Redux/actions';
import TableUI from '../Common/TableUI';
const useStyles = makeStyles((theme: Theme) => ({
  marginTop10: {
    marginTop: '10px'
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
}));

const TopicSubscriptionListing = (props: any) => {
  const { id, trigger, creationTrigger, customerId, eventType } = props
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  const [data, setData] = useState(initialData);
  const state: any = useSelector(st => st);
  const { subscriptionListByTopicId } = state;
  const [subData, setSubData] = useState<any>(null);

  let manageSubscriptions: any = null;
  const tableColumns = ['User', 'Channel'];
  useEffect(() => {
    getSubscribersList(customerId).then((res: any) => {
      let allUsers = res.data.data
      const filtered = allUsers.filter((user: any) => {
        if (eventType === 'oneTimeEvent') {
          return user.json?.jSubCheckBox?.incidentAlert
        } else if (eventType === 'scheduledEvent') {
          return user.json?.jSubCheckBox?.maintenanceAlert;
        } else {
          return false;
        }
      });
      setSubData(filtered)
    }).catch((err: any) => {
      console.error("Error....",err)
    })


  }, [])



  useEffect(() => {
    const respData = subscriptionListByTopicId && subscriptionListByTopicId.data
    if (respData && respData.success) {
      setData(respData.data);
    }
  }, [subscriptionListByTopicId]);

  let subscriptionTable: any[] = [];
  if (subData && subData.length > 0) {
    subscriptionTable = subData.map((item: any) => {
      return (
        <TableRow key={item.name}>
          <TableCell className={classes.tDef}>{item.name}</TableCell>
          <TableCell className={classes.tDef}>{item.email}</TableCell>
          {/* <TableCell className={classes.tDef}>
            <Chip
              size="small"
              className={item.status == 'ACTIVE' ? 'w3-green' : 'w3-red'}
              label={item.status}
            />
          </TableCell> */}
        </TableRow>
      )
    })
  }
  if (subData == null) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (subData && subData.length) {
    manageSubscriptions = subscriptionTable;
  }
  else if (subData && subData.length === 0) {
    manageSubscriptions = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Subscriptions Found
            </h5>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className="w3-white">
      <div className="w3-padding w3-border-bottom" >
        <h4 style={{ margin: '0px' }}>Subscribers </h4>
      </div>
      <TableUI
        tableContent={manageSubscriptions}
        tableColumns={tableColumns}
        stickyHeader={false}
        scollable={false}
        classes={classes}
        borderTop={false}
      />
    </div>
  );

};

export default TopicSubscriptionListing;