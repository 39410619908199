import axios from 'axios';
import { reject } from 'lodash';
import api from './api';
declare let window:any;
const requestMap: any = api;
export const actions = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
    FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
    SET_DATA: 'SET_DATA',
    SET_ALERT: 'SET_ALERT',
    SET_CONFIRM_MESSAGE:'SET_CONFIRM_MESSAGE'
};

axios.interceptors.request.use(function (config) {
    config.headers['Authorization'] = 'bearer ' + localStorage.getItem('access_token');
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
axios.interceptors.response.use(
    (response) => {
      if (response.headers && response.headers.token) {
        localStorage.setItem('access_token', response.headers.token);        
        return response;
      } 
        return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'network error',
          status: 500,
        };
      }
      if (error.response.status === 401) {
        if(localStorage.getItem('access_token')){
            // window?.gStore?.dispatch({
            //     type : actions.SET_ALERT,
            //     key:'alertMessage',
            //     message:'Session expired, Please try login again',
            //     msgType:'fail',
            //     callback:()=>{
            //             localStorage.removeItem('access_token');
            //             // window.location.href ='/login';
            //     }
            // });
            window.alert('Session expired, Please try login again');
            localStorage.removeItem('access_token');
            window.location.href ='/login';
        }
      }
      return Promise.reject(error);
    },
  );



export const setStoreData = (key: string, value: any) => {
    return {
        type: actions.SET_DATA,
        key,
        value
    };
};

export const fetchDataRequest = (key: string) => {
    return {
        type: actions.FETCH_REQUEST,
        key
    };
};

export const fetchDataRequestError = (key: string, error: any) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error
    };
};


export const fetchResponseSuccess = (key: string, data: any) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data
    };
};

export const setAlert = (key: string, message: string, msgType: string, callback: Function) => {
    return {
        type : actions.SET_ALERT,
        key,
        message,
        msgType,
        callback
    }
}
export const makeRequest = (
    key: string, path: any = [], params: any = {}, pathParam?: any
) => {
    const request = Object.assign({}, requestMap[key]);
    if (path.length > 0) {
        request.url += '/' + path.join('/');
    }

    if (request.method === undefined || request.method == 'GET') {
        request.method = 'GET';
        request.params = params
    }
    else {
        request.data = params
    }

    if (pathParam) {
        Object.keys(pathParam).forEach((param: any) => {
            request.url = request.url.replace(`{${param}}`, pathParam[param])
        })
    }


    return axios(request)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
};

export const fireRequest = (
    key: string, path: any = [], params: any = {}, pathParam?: any
) => {
    return (dispatch: any) => {
        const request = Object.assign({}, requestMap[key]);
        if (path.length > 0) {
            request.url += '/' + path.join('/');
        }

        if (request.method === undefined || request.method == 'GET') {
            request.method = 'GET';
            request.params = params
        }
        else {
            request.data = params
        }

        if (pathParam) {
            Object.keys(pathParam).forEach((param: any) => {
                request.url = request.url.replace(`{${param}}`, pathParam[param])
            })
        }


        dispatch(fetchDataRequest(key));
        return axios(request)
            .then((response: any) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error: any) => {
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};

// auth
export const postCreateUser = (form: object) => {
    return fireRequest('createUser', [], form);
};

export const getCurrentUser = () => {
    return fireRequest('currentUser');
};

export const changeAdminStatus = (userId: number, type: string) => {
    return fireRequest('updateAdminStatus', [], {
        userId,
        type
    });
};

export const getRoleUsers = (roleName: string) => {
    return fireRequest('getRoleUsers', [], {
        role: roleName,
        type: 'nothing'
    });
};


export const getCurrentUserRoles = () => {
    return fireRequest('currentUserRoles');
};

export const postRegister = (form: object) => {
    return fireRequest('register', [], form);
};

export const postLogin = (form: object) => {
    return fireRequest('login', [], form);
};

export const postForgotPassword = (form: object) => {
    return fireRequest('forgotPassword', [], form);
};

export const postResetPassword = (form: object) => {
    return fireRequest('resetPassword', [], form);
};

export const getAllUsers = () => {
    return fireRequest('usersList');
};

export const getAllPocLevelUsers = () => {
    return fireRequest('pocUsersList');
};

export const changeUserPassword = (form: object) => {
    return fireRequest('changeUserPassword', [], form);
};
export const changeUserName = (form: object) => {
    return fireRequest('changeUserName', [], form);
};

export const getFirstUser = () => {
    return fireRequest('firstUser', [], {});
};

export const logout = () => {
    return fireRequest('logout', [], {});
}

//user

export const findUser = (searchData: object) => {
    return fireRequest('findUser', [], searchData);
};

//invite

export const getInvitedUserList = (paginate: object) => {
    return fireRequest('invitedUserList', [], paginate);
};

export const getSubCheckBox = () => {
    return makeRequest('getSubCheckBox', [], {});
};

export const getAllInvitedUsersWithRoles = () => {
    return fireRequest('invitedUserListWithRoles');
};

export const getInvitedMemberList = (paginate: object, customerId: number) => {
    return fireRequest('invitedMemberList', [customerId], paginate);
};

export const getInvitedTeamMemberList = (paginate: object, customerId: number, teamId: number) => {
    return fireRequest('invitedTeamMemberList', [customerId, teamId], paginate);
};

export const postInviteUser = (form: object) => {
    return fireRequest('inviteUser', [], form);
};

export const postAcceptInvite = (form: object) => {
    return fireRequest('acceptInvite', [], form);
};
export const postAcceptOrRejectInvite = (form: object) => {
    return fireRequest('acceptOrReject', [], form);
};

export const updateInviteUser = (form: object, id: number) => {
    return fireRequest('editInviteUser', [id], form);
};

export const deleteInviteUser = (id: number) => {
    return fireRequest('deleteInviteUser', [id], {});
};

export const getValidateTokenStatus = (token: string) => {
    return fireRequest('validateToken', [token], {});
};



//Dashboard

export const getDashboardStats = () => {
    return fireRequest('dashboardStats');
};

export const getRoles = () => {
    return fireRequest('getRole');
};

// Customer
export const getCustomerDashStats = (id: number) => {
    return fireRequest('customerDashStats', [id], {});
};

export const postCreateCustomer = (form: object) => {
    return makeRequest('createCustomer', [], form);
};
export const postUpdateCustomer = (form: object) => {
    return makeRequest('editCustomer', [], form);
};

export const disableCustomer = (id: number) => {
    return makeRequest('deleteCustomer', [id], {});
};

export const getCustomerList = (paginate: object = {}) => {
    return fireRequest('customerList', [], paginate);
};


export const getFilterCustomerList = (filterData: object) => {
    return fireRequest('filterCustomers', [], filterData);
};

export const getCustomerDetailsById = (customerId: number) => {
    return makeRequest('customerDetails', [customerId], {});
};

export const getAllCustomers = () => {
    return fireRequest('allCustomers');
};

export const postConfirmCustomers = (form: object) => {
    return fireRequest('confirmCustomers', [], form);
};


// Teams

export const getTeamList = (paginate: object) => {
    return fireRequest('teamList', [], paginate);
};

export const getFilterTeamList = (filterData: object) => {
    return fireRequest('find-teams', [], filterData);
};

export const getTeamListAll = () => {
    return fireRequest('teamListAll', [], {});
};

export const postCreateTeam = (form: object) => {
    return fireRequest('createTeam', [], form);
};

export const putEditTeam = (form: object, id: number) => {
    return fireRequest('updateTeam', [id], form);
};

export const destroyTeam = (id: number, customerId: number) => {
    return fireRequest('deleteTeam', [id, customerId]);
};

export const findTeam = (searchData: object) => {
    return fireRequest('findTeam', [], searchData);
};

export const getTeamInfo = (id: number) => {
    return fireRequest('teamInfo', [id], {});
};

export const getAllTeamsList = (paginate: object = {}) => {
    return fireRequest('getAllTeamList', [], paginate);
};

export const postInviteTeamMember = (form: object) => {
    return fireRequest('inviteTeamMember', [], form);
};

export const getTeamListing = (customerId: number) => {
    return fireRequest('teamListing', [customerId], {});
};

// Topics

export const postCreateTopic = (form: object) => {
    return makeRequest('createTopic', [], form);
};

export const postCreateTopicReminders = (form: object) => {
    return fireRequest('createTopicReminders', [], form);
};

export const getTopicList = (paginate: object = {}) => {
    return makeRequest('topicList', [], paginate);
};

export const getTopicReminderList = (id: number) => {
    return fireRequest('topicReminderList', [id], {});
};

export const getPatchWindowList = (paginate: object = {}) => {
    return makeRequest('patchWindowList', [], paginate);
};

export const postUpdateTopic = (form: object) => {
    return makeRequest('editTopic', [], form);
};

export const postUpdateTopicInstance = (form: object) => {
    return makeRequest('editTopicInstance', [], form);
};

export const getTopicListById = (id: number, refId: number) => {
    return fireRequest('topicListById', [refId, id], {});
};

export const getTopicRemindersListByTopicId = (topicId: number) => {
    return fireRequest('topicRemindersListByTopicId', [topicId], {});
};

export const getTopicInstanceByTopicId = (topicId: number) => {
    return fireRequest('topicInstanceByTopicId', [topicId], {});
};

export const getTopicById = (id: number) => {
    return fireRequest('topicById', [id], {});
};

export const destroyTopic = (id: number, refId: number) => {
    return fireRequest('deleteTopic', [id, refId], {});
};

export const destroyTopicReminder = (id: number) => {
    return fireRequest('deleteTopicReminder', [id], {});
};

export const findTopic = (searchData: object) => {
    return fireRequest('findTopic', [], searchData);
};

export const getTopicCount = () => {
    return fireRequest('topicCount');
};

export const postSettingsData = (form: object, pathParams: object) => {
    return fireRequest('settingsData', [], form, pathParams);
};

export const mondayTriggers = () => {
    return fireRequest('mondayTriggers', []);
};


export const getMetaByName = (name: string) => {
    return fireRequest('metaByName', [], { name });
} 
export const getAllTerms = (metaName: string) => {
    return fireRequest('getAllTerms', [metaName], {});
};

export const addTerm = (form: object) => {
    return fireRequest('addTerm', [], { form });
};


export const findTerm = (search: string) => {
    return fireRequest('findTerm', [], { search });
};

export const postPauseOrEnableAllTopics = (form: object) => {
    return makeRequest('pauseOrEnableAllTopics', [], form);
};

export const postPauseOrEnableSelectedTopics = (form: object) => {
    return makeRequest('pauseOrEnableSelectedTopics', [], form);
};
 
export const getActivityLogByTopicId = (id: number) => {
    return fireRequest('activityLog', [id], {});
};


// Subscriptions

export const postCreateSubscription = (form: object) => {
    return fireRequest('createSubscription', [], form);
};

export const getSubscriptionList = (paginate: object = {}) => {
    return fireRequest('subscriptionList', [], paginate);
};

export const destroySubscription = (id: number) => {
    return fireRequest('deleteSubscription', [id], {});
};

export const postUpdateSubscription = (form: object, id: number) => {
    return fireRequest('editSubscription', [id], form);
};

export const postUnsubscribeSubscription = (form: object, listId: number) => {
    return fireRequest('unsubscribeSubscription', [listId], form);
};

export const getSubscriptionListByTopicId = (topicId: number) => {
    return fireRequest('subscriptionListByTopicId', [topicId], {});
};

//Notification Channel

export const postCreateNotificationChannel = (form: object) => {
    return fireRequest('createNotificationChannel', [], form);
};


export const updateAcceptChannelInvite = (verificationToken: string) => {
    return makeRequest('acceptChannelInvite', [verificationToken], {});
};

export const getChannelList = (paginate: object = {}) => {
    return fireRequest('channelList', [], paginate);
};

export const destroyChannel = (id: number, status: string) => {
    return makeRequest('deleteChannel', [id, status], {});
};

export const getDistributionList = (paginate: object = {}) => {
    return fireRequest('distributionList', [], paginate);
};

export const postCreateDistributionList = (form: object) => {
    return fireRequest('createDistributionList', [], form);
};

export const updateDistributionList = (form: object) => {
    return fireRequest('updateDistributionList', [], form);
};

export const deactivateDistributionList = (id:number, customerId:number, status:string) => {
    return makeRequest('deactivateDistributionList', [id, customerId, status], {});
};

export const destroyDistributionList = (id:number, customerId:number) => {
    return makeRequest('deleteDistributionList', [id, customerId], {});
};

//Notifications
export const postCreateNotification = (form: object) => {
    return fireRequest('createNotification', [], form);
};

export const postConfirmNotification = (form: object) => {
    return fireRequest('confirmNotification', [], form);
};

export const getAllNotifications = (topicParams: any) => {
    return fireRequest('allNotifications', [], topicParams);
}

export const getSubscribersList = (id: number) => {
    return makeRequest('getSubscribers', [id], {});
};

//Alerts
export const setAlertDialogMessage = (message: string, msgType: string, callback: Function = undefined) => {
    return (dispatch: any) => {
        dispatch(setAlert('alertMessage', message, msgType, callback));
    }
}

export const sendHeartbeat = () => {
    return (dispatch,getState) => {
        const {heartBeat} = getState();               
        if (heartBeat  > Date.now() - 1 * 60000) {
            return;
        }
        makeRequest('heartBeat',[],{});       
        return dispatch(setStoreData("heartBeat",Date.now()));
    }
}

const showConfirm = (title:string, message:String,handleOk: Function = undefined,handleCancel: Function = undefined)=>{    
    return {
        type:actions.SET_CONFIRM_MESSAGE,
        key:"confirmMessage",
        title,
        message,
        handleOk,
        handleCancel
    }
}

export const setConfirmDialogMessage = (title:string, message: String, handleOk: Function = undefined, handleCancel: Function = undefined) => {
    return (dispatch: any) => {
        dispatch(showConfirm(title, message, handleOk, handleCancel))
    }
}
