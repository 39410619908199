export const userRoles = {
    XYLEM_ADMIN: 'admin',
    XYLEM_CUSTOMER_ADMIN: 'xylem_customer_admin',
    XYLEM_PATCH_ADMIN : 'xylem_patch_admin',
    XYLEM_SERVICE_USER: 'xylem_service_user',
    CUSTOMER_ADMIN: 'customer_admin',
    TEAM_LEAD: 'team_lead',
    TEAM_MEMBER: 'team_member',
    PRIMARY_POC: 'primary_poc',
    SECONDARY_POC: 'secondary_poc'
}

export const ROLE_NAMES = {
    [userRoles.XYLEM_ADMIN]: 'Xylem Admin',
    [userRoles.XYLEM_CUSTOMER_ADMIN]: 'Xylem Customer Admin',
    [userRoles.XYLEM_PATCH_ADMIN]: 'Xylem Patch Admin',
    [userRoles.XYLEM_SERVICE_USER]: 'Xylem Service User',

    [userRoles.CUSTOMER_ADMIN]: 'Customer Admin',
    [userRoles.TEAM_LEAD]: 'Team Lead',
    [userRoles.TEAM_MEMBER]: 'Team Member',

    [userRoles.PRIMARY_POC]: 'Primary POC',
    [userRoles.SECONDARY_POC]: 'Secondary POC',
}
