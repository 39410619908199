import React, { useState, useEffect } from 'react';
import {
  Grid, Drawer, Divider, List, Button, InputLabel, MenuItem, FormControl
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
import HelperButton from '../Common/HelperButton';

const useStyles = makeStyles({
  list: {
    paddingTop: 50,
    maxWidth: 380,
    padding: 20,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    minWidth: 300,
  },
  filterIcon: {
    marginTop: '4px',
    marginRight: '20px',
  },
  reactSelect: {
    zIndex: 5
  },
  scrollMenuList: {
    maxHeight: 200,
    overflow: 'auto'
  },
  buttonLabel: {
    textTransform: "none",
  },
});

const UserFilters = ({ filterData, handleUserFilters }: any) => {

  const classes = useStyles();
  const [filter, setFilter] = useState(filterData);
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [drawerState, setDrawerState] = React.useState({
    right: false,
  });

  const handleSelectFilter = (event: any) => {
    const { name, value } = event.target;
    filter[name] = value;
    setFilter(Object.assign({}, filter));
  }

  const triggerFilter = () => {
    handleUserFilters(Object.assign({}, filter));
    setDrawerState({
      right: false,
    });
  }

  type DrawerSide = 'right';
  const toggleDrawer = (side: DrawerSide, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    setDrawerState({ ...drawerState, [side]: open });
  };

  const handleClearFilter = () => {
    setDrawerState({
      right: false,
    });
    setFilter({
      status: '',
      type: ''
    });
    handleUserFilters(Object.assign({
      status: '',
      type: '',
    }));
  }

  const sideList = (side: DrawerSide) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <Grid container justifyContent="space-between">
        <h3>Filters</h3>
        <HelperButton trigger="user_filter" />
      </Grid>
      <List>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
              Role
                </InputLabel>
            <MuiSelect
              labelId="roleLabel"
              id="type"
              name="type"
              value={filter.type || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              <MenuItem value="admin">Xylem Admin</MenuItem>
              <MenuItem value="xylem_customer_admin">Xylem Customer Admin</MenuItem>
              <MenuItem value="xylem_patch_admin">Xylem Patch Admin</MenuItem>
              <MenuItem value="xylem_service_user">Xylem Service Account User</MenuItem>
            </MuiSelect>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusFilterLabel">
              Status
                </InputLabel>
            <MuiSelect
              labelId="statusFilterLabel"
              id="status"
              name="status"
              value={filter.status || ''}
              onChange={handleSelectFilter}
              fullWidth
            >
              <MenuItem value="Inviting">Inviting</MenuItem>
              <MenuItem value="Invited">Invited</MenuItem>
              <MenuItem value="Joined">Joined</MenuItem>
              <MenuItem value="Invite-Pending">Pending</MenuItem>
              <MenuItem value="Invite-Rejected">Rejected</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </MuiSelect>
          </FormControl>
        </Grid>
      </List>
      <Divider />
      <List>
        <Grid container justifyContent="space-between" alignItems="center">
          <Button variant="contained"
            size="small"
            onClick={handleClearFilter}
          >
            Clear
              </Button>
          <Button
            variant="contained"
            size="small"
            onClick={triggerFilter}
            color="primary"
            className={`w3-center ${classes.buttonLabel}`}>
            Filter
              </Button>
        </Grid>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
        {sideList('right')}
      </Drawer>
    </div>
  )
}

export default UserFilters;