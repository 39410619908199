import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, InputLabel, Box, Paper, FormControl, MenuItem, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllPocLevelUsers, postCreateCustomer, postUpdateCustomer, disableCustomer,
    getCustomerDetailsById, getMetaByName, getAllTerms
} from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Select from 'react-select';
import Loader from '../Common/Loader';
import CancelIcon from '@material-ui/icons/Cancel';
import defaultLogo from "../../img/checks.png";
import AlertDialog from '../Common/AlertDialog';
import ConfirmDialog from '../Common/ConfirmDialog';
import MuiSelectField from '../Common/MuiSelectField';
import { urlValidator, emailValidator, textValidator, nameValidator } from '../../utils';
import {  useParams } from 'react-router-dom';
import {useNavigate} from 'react-router';
import HelperButton from '../Common/HelperButton';
import { userRoles } from '../../Context/userRoles';
import { APP_STATUS, SUBJECTS } from '../../utils/contants';
import { Can } from '../../Context/Can';
import { subject } from '@casl/ability';

const customerRoles = [
    userRoles.CUSTOMER_ADMIN,
    userRoles.TEAM_LEAD,
    userRoles.TEAM_MEMBER,
];

const useStyles = makeStyles((theme: Theme) => ({
    modalHeader: {
        padding: '15px',
        alignItems: 'center'
    },
    modalFooterContent:{
        marginTop:4
    },
    modalFooter: {
        textAlign: 'right'
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '15px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px'
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: "none"
    },
    logoPreviewContainer: {
        // width: 165,
        // height: 165,
        border: '1px solid #ccc',
        borderRadius: 6,
        padding: 6,
        position: 'relative',
    },
    logoPreView: {
        width: '100%',
        height: 158,
        maxWidth: '100%',
        objectFit: 'cover',
        objectPosition:'center'
    },
    defaultLogoContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        height: 150,
        overflow: 'hidden'
    },
    defaultLogo: {
        width: 50,
        height: 50,
        backgroundColor: 'gray',
        '&:nth-child(even)': {
            backgroundColor: 'white',
        }
    },
    cancelLogo: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    reSelectLabel: {
        marginBottom: 8,
        fontSize: 12
    }
}));

const CustomerModal = (props: any) => {
    // const { customerId, type } = props;
    const { customerId, type } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState<any>();
    const [editFlag, setEditFlag] = useState(false);
    const [editData, setEditData] = useState<any>();
    const [editCustomerData, setEditCustomerData] = useState<any>();
    const [errors, setErrors] = useState<any>({
        name: '',
        address: '',
        website: '',
        logoFile: '',
        customerAdminName: '',
        customerAdminEmail: '',
        customerCode: '',
    });
    const [values, setValues] = useState<any>(Object.assign({
        id: '',
        name: '',
        address: '',
        website: '',
        logoFile: '',
        customerAdminName: '',
        customerAdminEmail: '',
        customerCode: '',
        status:{
            label: 'Enable',
            value: 'Active'
        },
    }, editData, editCustomerData));
    const [logo, setLogo] = useState({ preview: "", raw: "" });
    const [selectedPocs, setPoc] = useState([]);
    const [selectedOtherPocs, setOtherPocs] = useState([]);

    const dispatch: any = useDispatch();
    const store: any = useSelector(stre => stre);
    const { pocUsersList, currentUserRoles } = store;
    let userItems: any[] = [];
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [openAlert, setOpen] = useState(false);
    const [msgType, setMsgType] = useState("success");
    const [showLoader, setShowLoader] = useState(false);
    let otherPocs: any[] = [];
    const [optionValue, setOptionValue] = useState<any>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    let tagOptions: any[] = [];
    const [options, setOptions] = useState([]);
    const [tagSelection, setTagSelection] = useState(false);

    const getAllTags = () => {
        dispatch(getAllTerms('tag')).then((termRes: any) => {
            if (termRes && termRes.status === 200) {
                const termData = termRes.data;
                setOptions(termData.map((item: any) => ( {
                        id: item.id,
                        title: item.name,
                        metaId: item.metaId
                    })))
            }
        }) 
    }

    useEffect(() => {
        getAllTags();

        dispatch(getAllPocLevelUsers());
        if (!customerId) {
            return
        }
        getCustomerDetailsById(parseInt(customerId))
            .then((res: any) => {
                const respData = res.data.data;
                const customerDetails = {
                    id: respData.id,
                    name: respData.name,
                    address: respData.address,
                    website: respData.website,
                    customerCode: respData.customerCode,
                   status:   {
                        label: ( respData.status == 'InActive') ? 'Disable':'Enable',
                        value:   respData.status 
                    } 
                }
                type == 'adminEdit' ?
                    setEditCustomerData(respData) :
                    setEditData(respData);

                setValues(customerDetails);
                setOptionValue(respData.customerTags.map((item: any) => (
                    {
                        id: item.termId,
                        title: item.terms.name,
                        metaId: item.metaId
                    }
                )));
                setPoc(respData.customerUsers
                    .filter((item: any) => item.role.type === 'primary_poc')
                    .map((item: any) => (
                        {
                            value: item.user.id,
                            label: item.user.name
                        }
                    )));
                setOtherPocs(respData.customerUsers
                    .filter((item: any) => item.role.type === 'other_poc')
                    .map((item: any) => (
                        {
                            value: item.user.id,
                            label: item.user.name
                        }
                    )));
            });

    }, [1]);

    useEffect(() => {
        if (!currentUserRoles || !currentUserRoles?.data?.data) return;
        const cRole = currentUserRoles?.data?.data.find((item: any) => customerRoles.indexOf(item.type) >= 0,);
        if (cRole) {
            setTagSelection(true);
        }
    }, [currentUserRoles]);

    if (pocUsersList && pocUsersList.data && pocUsersList.data.length > 0) {
        userItems = pocUsersList.data.map((item: any) => ({ label: item.name, value: item.id }));
    }

    if (userItems && userItems.length > 0 && selectedOtherPocs && selectedOtherPocs.length > 0) {
        userItems = userItems.filter((user: any) => !selectedOtherPocs.find((selectedUser: any) => selectedUser.value === user.value))
    }

    if (userItems && userItems.length > 0) {
        otherPocs = userItems;
        if (selectedPocs && selectedPocs.length > 0) {
            otherPocs = userItems.filter((user: any) => !selectedPocs.find((selectedUser: any) => selectedUser.value === user.value))
        }
    }

    const validateForm = () => {
        let formIsValid = true;
        let err = Object.assign({}, errors);

        if (!values.name) {
            formIsValid = false;
            err.name = 'This field is required';
        } else if (!nameValidator(values.name)) {
            formIsValid = false;
            err.name = 'Only letters,numbers and characters[.,-_\'] are allowed';
        }
        // if (!values.address) {
        //     formIsValid = false;
        //     err.address = 'This field is required';
        // }
        if (values.website) {
            if (!urlValidator(values.website)) {
                formIsValid = false;
                err.website = "Please enter valid website.";
            }
        }
        if (!values.customerAdminName) {
            formIsValid = false;
            err.customerAdminName = 'This field is required';
        } else if (!textValidator(values.customerAdminName)) {
            formIsValid = false;
            err.customerAdminName = 'Only letters,numbers and characters[.,-_] are allowed';
        }
        if (!values.customerCode) {
            formIsValid = false;
            err.customerCode = 'This field is required';
        } else if (!textValidator(values.customerCode)) {
            formIsValid = false;
            err.customerCode = 'Only letters,numbers and characters[.,-_] are allowed';
        }
        if (!values.customerAdminEmail) {
            formIsValid = false;
            err.customerAdminEmail = 'This field is required';
        } else if (!emailValidator(values.customerAdminEmail)) {
            formIsValid = false;
            err.customerAdminEmail = "Please enter valid email address.";
        }
        if(!values.status)
        {
            formIsValid = false;
            err.status = 'This field is required';
        }
        setErrors(err);
        return formIsValid;
    }

    const handleFileChange = (evt: any) => {
        setEditFlag(true);
        if (evt.target.files.length) {
            const customerLogo = evt.target.files[0];
            setLogo({
                preview: URL.createObjectURL(customerLogo),
                raw: customerLogo
            });
            setValues({
                ...values,
                logoFile: customerLogo
            });

            const err = Object.assign({}, errors);
            if (err['logoFile']) {
                err['logoFile'] = null;
            }
            setErrors(err);
        }
    };

    const handleChange = (event: any) => {
        setEditFlag(true);
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });

        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setErrors(err);
    }

    const showAlert = (msg: any, type: any) => {
        setAlertMessage(msg);
        setMsgType(type);
        setOpen(true);
    }

    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        setShowLoader(false);
        showAlert(msg, type);
    };

    const handleSelect = (selected: any) => {
        setPoc(selected);
    }

    const handleSelectOtherPoc = (selected: any) => {
        setOtherPocs(selected);
    }

    const handleDialogClose = () => {
        setAlertMessage(null);
        setOpen(false);
    };

    const handleOptionOnChange = (event: any, newValue: any) => {
        if (typeof newValue === "string") {
            setOptionValue({
                title: newValue
            });
        } else if (newValue && newValue.inputValue) {
            setOptionValue({
                title: newValue.inputValue
            });
        } else {
            setOptionValue(newValue);
        }
    }

    const formatPostData = (status:string = '') =>{
        const json = {
            address: values.address,
            customerAdminName: values.customerAdminName,
            customerAdminEmail: values.customerAdminEmail,
            currentStatus: status !== ''? status : values.status.value,
        };
        const customerData: any = {
            id: values.id,
            name: values.name,
            status: status !== ''? status : values.status.value,
            customerCode: values.customerCode,
            website: values.website,
            primaryPocs: editCustomerData ? '' : !selectedPocs?[]:selectedPocs,
            otherPocs: editCustomerData ? '' : !selectedOtherPocs?[]:selectedOtherPocs,
            selectedTags: optionValue,
            json
        };

        let postData = new FormData();
        if (values.logoFile) {
            postData.append('logoFile', values.logoFile);
        }
        postData.append('json', JSON.stringify(customerData));
        return postData;
    }

    const handleSubmit = () => {
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setShowLoader(true);
        const postData = formatPostData();
        if ((editData && editData.id) || (editCustomerData && editCustomerData.id)) {
            postUpdateCustomer(postData).then((resp: any) => {
                updateResponse(resp, 'Customer Updated Successfully') 
            });
            getAllTags();
        } else {
            postCreateCustomer(postData).then((resp: any) => {
                updateResponse(resp, 'Customer Created Successfully'); 
            });
        }
    }

    const handleDeactivate = () =>{ setShowConfirmDialog(true) };

    const handleCustomerDeactivate = () =>{
        setShowConfirmDialog(false);
        const status = editData && editData.status.toLowerCase() === APP_STATUS.ACTIVE.toLowerCase() ? APP_STATUS.INACTIVE : APP_STATUS.ACTIVE;
        setShowLoader(true);
        const postData = formatPostData(status);
        if ((editData && editData.id) || (editCustomerData && editCustomerData.id)) {
            postUpdateCustomer(postData).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    let respMsg = '';
                    if(status === APP_STATUS.ACTIVE){
                        respMsg = 'Customer activated successfully...!';
                    }else if(status === APP_STATUS.INACTIVE){
                        respMsg = 'Customer deactivated successfully...!';
                    }
                    updateResponse(resp, respMsg);
                } else if (resp.status === 400) {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
            getAllTags();
        }

    }

    let editJson = null;

    if (editData && editData.id && !editFlag) {

        if (editData.json) {
            editJson = editData.json;
            const { address, customerAdminName, customerAdminEmail } = editJson;
            values.address = address;
            values.customerAdminName = customerAdminName;
            values.customerAdminEmail = customerAdminEmail;

        }
        logo.preview = editData.logo;
        values.logoFile = editData.logo;
    }

    if (editCustomerData && editCustomerData.id && !editFlag) {
        if (editCustomerData.json) {
            editJson = editCustomerData.json;
            const { address, customerAdminName, customerAdminEmail } = editJson;
            values.address = address;
            values.customerAdminName = customerAdminName;
            values.customerAdminEmail = customerAdminEmail;

        }
        logo.preview = editCustomerData.logo;
        values.logoFile = editCustomerData.logo;
    }

    if (options && options.length > 0) {
        tagOptions = options;
    }

    if (!editCustomerData && optionValue && optionValue.length > 0) {
        tagOptions = options.filter((optionItem: any) => !optionValue.find((selectedTag: any) => selectedTag.id === optionItem.id))
    }

    let adminEmail = null;
    if (values.customerAdminEmail) {
        adminEmail = values.customerAdminEmail.toLowerCase().trim();
    }
 
    return (
        <>
            <Paper elevation={1} style={{ padding: 16 }}>
                <Grid container>
                    <h5>{customerId ? 'Edit Customer' : 'Create Customer'}</h5>
                    <HelperButton trigger="create_customer_helper" />
                </Grid>
                <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="name"
                                name="name"
                                margin="dense"
                                label="Customer Name"
                                value={values.name || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                                required
                            />
                            <ErrorHelperText error={errors.name} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="customerCode"
                                name="customerCode"
                                margin="dense"
                                label="Customer ID"
                                value={values.customerCode || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                                required
                                disabled={editCustomerData ? true : false}
                            />
                            <ErrorHelperText error={errors.customerCode} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={5}
                                id="address"
                                name="address"
                                margin="dense"
                                label="Customer Address"
                                value={values.address || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                            />
                            <ErrorHelperText error={errors.address} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField fullWidth
                                id="website"
                                name="website"
                                margin="dense"
                                label="Website"
                                value={values.website || ''}
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                // required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.label
                                    }
                                }}
                            />
                            <ErrorHelperText error={errors.website} />
                        </Grid>

                        <Grid item container xs={12} spacing={2} alignItems="center">
                            <Grid item xs={12} md={5} className={classes.marginTop10}>
                                <div className={`${classes.logoPreviewContainer}`}>
                                    {logo.preview && logo.raw && <CancelIcon className={`cursor-pointer ${classes.cancelLogo}`}
                                        onClick={() => setLogo({ preview: "", raw: "" })} />}
                                    {logo.preview ? (
                                        <img src={logo.preview} alt="logo" className={classes.logoPreView} />
                                    ) : (
                                            <div className={classes.defaultLogoContainer}>
                                                <img src={defaultLogo} alt="default logo" className={classes.logoPreView} style={{objectFit:'fill'}}/>
                                            </div>
                                        )}
                                </div>

                            </Grid>
                            <Grid item xs={12} md={7}>
                                <label htmlFor="logo-file">
                                    <input
                                        style={{ display: 'none' }}
                                        id="logo-file"
                                        name="logoFile"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />

                                    <Button className={classes.buttonLabel} variant="contained"
                                        size="small" component="span">
                                        Upload Logo
                                </Button>
                                </label>
                            </Grid>
                            <div style={{ paddingLeft: 10 }}>
                                <ErrorHelperText error={errors.logoFile} />
                            </div>
                        </Grid>

                    </Grid>


                    <Grid item container xs={12} md={6}>
                        <Box component="div" display="flex" flex={1} flexDirection="column">
                            <div style={{ marginTop: -8 }}>
                                <MuiSelectField
                                    size="small"
                                    placeholder="Add Tag"
                                    label="Tags"
                                    limitTags={3}
                                    value={optionValue}
                                    options={tagOptions}
                                    onChange={handleOptionOnChange}
                                    allowNew={true}
                                    disabled={(editCustomerData || tagSelection) ? true : false}
                                />
                            </div>
                            <div>
                                <TextField fullWidth
                                    id="customer-admin-name"
                                    name="customerAdminName"
                                    margin="dense"
                                    label="Customer Admin Name"
                                    value={values.customerAdminName || ''}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label
                                        }
                                    }}
                                    disabled={(editData && editData.json && editData.json.customerAdminName) || (editCustomerData) ? true : false}
                                />
                                <ErrorHelperText error={errors.customerAdminName} />
                            </div>
                            <div className={classes.marginBottom10}>
                                <TextField fullWidth
                                    id="customer-admin-email"
                                    name="customerAdminEmail"
                                    margin="dense"
                                    label="Customer Admin Email"
                                    value={adminEmail || ''}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    required
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label
                                        }
                                    }}
                                    disabled={(editCustomerData || (editData && editData.json && editData.json.customerAdminEmail)) ? true : false}
                                />
                                <ErrorHelperText error={errors.customerAdminEmail} />
                            </div>
                            <div className={classes.marginBottom10}>
                                {selectedPocs && selectedPocs.length > 0 && <InputLabel className={classes.reSelectLabel} ref={inputLabel} id="primaryPocLabel">
                                    Primary POC
                            </InputLabel>}
                                <Select
                                    isMulti
                                    name="primaryPoc"
                                    label="Xylem Primary POC/ASE"
                                    options={userItems}
                                    placeholder="Xylem Primary POC / ASE"
                                    value={selectedPocs}
                                    onChange={(e) => handleSelect(e)}
                                    isDisabled={editCustomerData ? true : false}
                                />
                            </div>
                            <div className={`${classes.marginBottom10} ${classes.marginTop10}`}>
                                {selectedOtherPocs && selectedOtherPocs.length > 0 && <InputLabel className={classes.reSelectLabel} ref={inputLabel} id="otherPocLabel">
                                    Other POC
                            </InputLabel>}
                                <Select
                                    isMulti
                                    name="otherPoc"
                                    label="Xylem Other POC / ASE"
                                    options={otherPocs}
                                    placeholder="Xylem Other POC"
                                    value={selectedOtherPocs}
                                    onChange={(e) => handleSelectOtherPoc(e)}
                                    isDisabled={editCustomerData ? true : false}
                                />
                            </div>
                        </Box>   
                    </Grid>




                    <Grid item xs={12} className={classes.modalFooter}>
                        <Divider />
                        <Grid container spacing={2} justifyContent="space-between" className={classes.modalFooterContent}>
                            <Grid item md={6} container justifyContent="flex-start">
                                {editData && <Can do="manage" on={SUBJECTS.ADD_CUSTOMERS}>
                                    <Button
                                        color="secondary"
                                        variant='contained'
                                        size='small'
                                        className={classes.buttonLabel}
                                        onClick={handleDeactivate}
                                    >
                                        {editData.status.toLowerCase() === APP_STATUS.ACTIVE.toLowerCase() && 'Deactivate Customer'}
                                        {editData.status.toLowerCase() === APP_STATUS.INACTIVE.toLowerCase() && 'Activate Customer'}
                                    </Button>
                                </Can>}
                            </Grid>
                            <Grid item md={6} container justifyContent='flex-end'>
                                <Can I="manage" this={subject(SUBJECTS.EDIT_SPECIFIC_CUSTOMER_FIELDS,{customerId:`${customerId}`})} field="customerId">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={`w3-margin-right ${classes.buttonLabel}`}
                                        onClick={() => type == 'adminEdit' ? navigate(`/customer/${customerId}/settings`) : navigate('/customers')}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        className={`${classes.buttonLabel}`}
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                </Can>
                            </Grid>
                        </Grid>
                    </Grid>

                    {showConfirmDialog && (
                        <ConfirmDialog
                            handleCancel={() => {
                                setShowConfirmDialog(false)
                            }}
                            handleOk={handleCustomerDeactivate}
                            message={`Are you sure to ${editData && editData.status === APP_STATUS.ACTIVE ? 'deactivate' : 'activate'} the customer?`}
                        />
                    )}

                    {alertMessage ?
                        (
                            <AlertDialog
                                handleClose={() => {
                                    setAlertMessage(null);
                                    type == 'adminEdit' ? navigate(`/customer/${customerId}/settings`) : navigate('/customers')
                                }}
                                message={alertMessage}
                                open={openAlert}
                                type={msgType}
                                handleDialogClose={handleDialogClose}
                            />
                        )
                        : null
                    }
                    {showLoader ? <Loader open={showLoader} /> : null}
                </Grid>
            </Paper>
        </>
    );
}

export default CustomerModal;