import React, { useState, useEffect } from 'react';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import { Grid, Typography, Button, TableCell, TableRow, Box, Tooltip, CircularProgress, Chip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TitleHeader from '../Common/TitleHeader';
import TableUI from '../Common/TableUI';
import { destroyDistributionList, getDistributionList } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useSearchParams, Link } from 'react-router-dom';
import DistributionListModal from './DistributionListModal';
import DialogModal from '../Common/DialogModal';
import Pagination from '../Common/Pagination';
import { removeNonValueFilters } from '../../utils';
import { Can } from '../../Context/Can';
import SearchBox from '../Common/SearchBox';
import DistributionListFilters from './DistributionListFilters';
import { APP_STATUS, SUBJECTS } from '../../utils/contants';
import ConfirmDialog from '../Common/ConfirmDialog';
import AlertDialog from '../Common/AlertDialog';
import { subject } from '@casl/ability';


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    tHead: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    tDef: {
        fontSize: 15,
    },
    tDefName: {
        fontSize: 15,
        '&:hover': {
            color: '#0085ad',
        },
    },
    breadCrumb: {
        color: "#0085ad",
        paddingTop: 10,
    },
    buttonLabel: {
        textTransform: "none",
        backgroundColor: "#0085ad",
        color: "#fff",
        '&:hover': {
            backgroundColor: '#0085ad',
        },
    },
    actionCell: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paginateTopPadding: {
        paddingTop: '50px'
    },
}))


const ManageDistributionList = (props: any) => {
    const { customerId, customer } = props;
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const limit = 12;
    const initialData: any[] = [];
    let manageDistributionList: any = null;
    const [data, setData] = useState(initialData);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [showConfirmDialog, setShowConfirmDialog] = useState<any>(null);
    const [alertMessage, setAlertMessage] = useState<any>();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const tableColumns = ['Title', 'Email', 'Status', 'Action'];
    let distributionListTable: any[] = [];


    const fetchData = () => {
        let page: number = parseInt(queryParams.get('page'));
        const params = {
            ...Object.fromEntries(queryParams),
            customerId,
            offset: (page ? page - 1 : 0) * limit
        }
        setIsLoading(true);
        dispatch(getDistributionList(params)).then((res: any) => {
            if (res && res.status === 200) {
                const respData = res.data
                if (!respData) {
                    return;
                }
                setData(respData.data);
                setTotalCount(respData.total);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [queryParams, dispatch]);

    const updateQueryParams = (params: any) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleSearch = (value: string) => {
        updateQueryParams({ search: value, page: 1 });
      }

    const handlePagination = (page: number, lmt: number) => {
        updateQueryParams({ page, limit: lmt });
    };

    const handleDLFilters = (filters: any) => {
        const { status = '' } = filters;
        updateQueryParams({ status, page: 1 });
    }


    const handleDeleteDistributionList = (dLitem: any) => {
        setShowConfirmDialog(null);
        destroyDistributionList(dLitem.id,customerId)
            .then((resp: any) => {
                const res = resp && resp.data;
                if (res && res.success) {
                    setAlertMessage({
                        message: res.message,
                        type: 'success'
                    });
                    fetchData();
                } else {
                    setAlertMessage({
                        message: 'Deleting distribution list failed...!',
                        type: 'fail'
                    });
                }
            });
    };

    const triggerDelete = (dLItem: any) => {
        setShowConfirmDialog({
          message: `Are you sure to delete the email?`,
          confirm: () => handleDeleteDistributionList(dLItem)
        })
      }
    

    if (data && data.length > 0) {
        distributionListTable = data.map((item) => (
            <TableRow key={item.channelId}>
                <TableCell className={classes.tDefName} align="left">
                    {item?.json?.title}
                </TableCell>
                <TableCell className={classes.tDef} align="left">{item.channelId}</TableCell>
                <TableCell className={classes.tDef} align="left">
                    <Chip
                        size="small"
                        className={item.status === APP_STATUS.VERIFIED ? 'w3-green' : 'w3-red'}
                        label={item.status}
                    />
                </TableCell>
                <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
                    <Can I="manage" this={subject(SUBJECTS.DISTRIBUTION_LIST,{customerId:`${customer.id}`})} field="customerId">
                        <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" onClick={() => {
                                    setEditData(item)
                                    setOpen(true)
                                }}>
                                    <EditIcon className="cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => triggerDelete(item)}>
                                    <DeleteIcon className="cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Can>
                </TableCell>
            </TableRow>
        ))
    }
    if (isLoading || !data) {
        manageDistributionList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <div className="w3-center">
                        <CircularProgress />
                    </div>
                </TableCell>
            </TableRow>
        );
    } else if (data && data.length) {
        manageDistributionList = distributionListTable;
    } else if (data && data.length === 0) {
        manageDistributionList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5>
                        No Records Found
                    </h5>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <div>
            {customer && <CustomerBreadcrumbs customer={customer}> 
                <Typography color="textPrimary">Distribution List</Typography>
            </CustomerBreadcrumbs>}
            <TitleHeader
                component={
                    <Can I="manage" this={subject(SUBJECTS.DISTRIBUTION_LIST,{customerId:`${customer.id}`})} field="customerId">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            className={` ${classes.buttonLabel}`}
                            onClick={() => {
                                setEditData(null);
                                setOpen(true);
                            }}
                        >
                            Add Distribution List
                        </Button>
                    </Can>
                } 
                showSearch={true}>
                <div style={{ marginRight: '10px' }}>
                  <SearchBox
                    search={handleSearch}
                    value={queryParams.get("search") || ''}
                    placeholder="Search Email..."
                  />
                </div>
                <DistributionListFilters
                    customerId={customerId}
                    filterData={existingParams}
                    handleDLFilters={handleDLFilters}
                />                
            </TitleHeader>

            <Grid container>
                <TableUI
                    tableColumns={tableColumns}
                    tableContent={manageDistributionList}
                    scrollable={false}
                    stickyHeader={false}
                    borderTop={false}
                    classes={classes}
                />
                {(data && data.length > 0 && totalCount > limit) && (
                    <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
                        <Pagination
                            cPage={parseInt(queryParams.get('page'))}
                            defaultPerPage={limit}
                            data={{ totalCount }}
                            onChange={handlePagination}
                        />
                    </Grid>)}
            </Grid>

            {open && (<DialogModal
                fullWidth
                isOpen={open}
                hideTitle={true}
                hideBtns={true}
                maxWidth="sm"
                dialogContent={(
                    <DistributionListModal
                        triggerList={() => {
                            fetchData()
                        }}
                        customerId={customerId}
                        open={open}
                        editData={editData}
                        handleClose={() => {
                            setOpen(false);
                            setEditData(null);
                        }}
                    />
                )}
            />)}
            {alertMessage && <AlertDialog
                handleClose={() => setAlertMessage(null)}
                type={alertMessage.type}
                message={alertMessage.message}
                handleDialogClose={() => setAlertMessage(null)}
            />}
            {showConfirmDialog && (
                <ConfirmDialog
                    handleCancel={() => setShowConfirmDialog(null)}
                    handleOk={showConfirmDialog.confirm}
                    message={showConfirmDialog.message}
                />
            )}
        </div>
    )
}

export default ManageDistributionList;