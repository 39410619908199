import { Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import React from 'react'

function EmailContentModal(props: any) {
    const { handleClose, emailContent,classes } = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={9} className={classes.title}>
            Email Content
            </Grid>
            <Grid item container justifyContent="flex-end" xs={3}>
                <IconButton
                    
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon className="cursor-pointer"
                        style={{ color: 'black' }}
                    />
                </IconButton>
            </Grid>

            <Grid className="w3-margin-bottom" item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: emailContent }} 
                className="w3-light-grey  w3-padding"
                style={{overflow:'scroll', height:'80vh'}}>

                </div>
            </Grid>
        </Grid>
    )
}

export default EmailContentModal
