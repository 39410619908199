import React, { useEffect, useRef } from 'react';
import {
  Route,
  useLocation,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import Header,{RouteCan} from '../Components/Common/Header';
import Dashboard from '../Components/Dashboards';
import Channel from '../Components/Subscriptions/Channel';
import CustomerDashboard from '../Components/Dashboards/CustomerDashboard';
import ManageUsers from '../Components/Users/ManageUsers';
import Settings from '../Components/Common/Settings';
import Invite from '../Components/Auth/Invite';
import ManageCustomers from '../Components/Customers/ManageCustomers';
import ManageTeams from '../Components/Teams/ManageTeams';
import NotificationForm from '../Components/Notifications/NotificationForm';
import ManagePatchWindows from '../Components/PatchWindows/ManagePatchWindows';
import PatchWindowsForm from '../Components/PatchWindows/PatchWindowsForm';
import ManageTeamUsers from '../Components/TeamUsers/ManageTeamUsers';
import WorkInProgress from '../Components/Common/WorkInProgress';
import ParentTopicDetail from '../Components/PatchWindows/ParentTopicDetail';
import { AdminMenu } from '../Components/Common/Header';
import CustomerModal from '../Components/Customers/CustomerModal';
import ManageAlerts from '../Components/Alerts/ManageAlerts';
import { getChannelList, setConfirmDialogMessage, setStoreData } from '../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { lightBlue, indigo, cyan } from '@material-ui/core/colors';
import { useMediaQuery, createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { CustomerContainer } from '../Components/Customers/Container';
import { userRoles } from '../Context/userRoles';
import AlertDialog from '../Components/Common/AlertDialog';
import ConfirmDialog from '../Components/Common/ConfirmDialog';
import { setAlertDialogMessage } from '../Redux/actions';
import ManageDistributionList from '../Components/DistributionList/ManageDistributionList';
import { SUBJECTS } from '../utils/contants';
const img = 'https://cdn.ceegees.in/xylem/xylem-wave.png';

const AppRoutes = (props: any) => {
  const { currentUser, roles } = props;
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const state: any = useSelector(st => st);
  const { alertMessage, confirmMessage } = state;
  const location = useLocation();
  const adminRoles = [
    userRoles.XYLEM_CUSTOMER_ADMIN,
    userRoles.XYLEM_PATCH_ADMIN,
    userRoles.XYLEM_SERVICE_USER,
    userRoles.XYLEM_ADMIN,
  ];

  const customerRoles = [
    userRoles.CUSTOMER_ADMIN,
    userRoles.TEAM_LEAD,
    userRoles.TEAM_MEMBER,
  ];

  const menuContainer = useRef();

  useEffect(() => {
    if (roles && roles.length === 0) {
      dispatch(setAlertDialogMessage('Your username is deactivated, please contact your system admin', 'fail', () => {
        localStorage.removeItem('access_token');
        dispatch(setStoreData('currentUserRoles',null));
        navigate('/login')
      }));
    }
  }, [roles]);

  useEffect(() => {
    const aRole = props.roles.find(
      (item: any) => adminRoles.indexOf(item.type) >= 0,
      );
      const cRole = props.roles.find(
        (item: any) => customerRoles.indexOf(item.type) >= 0,
        );
    if (!localStorage.getItem('access_token')) {
      navigate('/')
    }
    if (aRole || !cRole) {
      return;
    }
    if (
      location.pathname == '/dashboard' ||
      location.pathname == '/customers'
    ) {
      navigate(`/customer/${cRole.customerId}`);
    } else if (
      location.pathname == '/alerts' ||
      location.pathname == '/events'
    ) {
      navigate(`/customer/${cRole.customerId}/alerts`);
    } else if (location.pathname == '/settings') {
      navigate(`/customer/${cRole.customerId}/settings`);
    }
  }, [location.pathname]);
  useEffect(() => {
    dispatch(getChannelList());
  }, [1]);
  // useRedirect('/', '/dashboard');

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
            main: lightBlue[800],
            contrastText: '#FFF',
          },
          secondary: {
            main: indigo[500],
          },
          type: 'light',
        },
      }),
    [prefersDarkMode],
  );

  const routesArray = [
    { path: '/channel/:verificationToken', element: <Channel /> },
    {
      path: '/',
      element: (
        <RouteCan I="view" a={SUBJECTS.DASHBOARD}>
          <AdminMenu container={menuContainer} />
          <Dashboard />
        </RouteCan>
      ),
    },

    {
      path: '/dashboard',
      element: (
        <RouteCan I="view" a={SUBJECTS.DASHBOARD}>
          <AdminMenu container={menuContainer} />
          <Dashboard />
        </RouteCan>
      ),
    },

    {
      path: '/users',
      element: (
        <RouteCan I="view" a={SUBJECTS.XYLEM_USERS}>
          <AdminMenu container={menuContainer} />
          <ManageUsers />
        </RouteCan>
      ),
    },
    { path: '/invite/:token', element: <Invite /> },
    {
      path: '/customers',
      element: (
        <RouteCan I="view" a={SUBJECTS.ALL_CUSTOMERS}>
          <AdminMenu container={menuContainer} />
          <ManageCustomers />
        </RouteCan>
      ),
    },
    {
      path: '/customers/create',
      element: (
        <RouteCan I="view" a={SUBJECTS.ALL_CUSTOMERS}>
          <AdminMenu container={menuContainer} />
          <CustomerModal />
        </RouteCan>
      ),
    },
    {
      path: '/customers/edit/:customerId',
      element: (
        <RouteCan I="view" a={SUBJECTS.ALL_CUSTOMERS}>
          <AdminMenu container={menuContainer} />
          <CustomerModal />
        </RouteCan>
      ),
    },
    {
      path: '/alerts',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <ManagePatchWindows eventType="oneTimeEvent" key="compTopic" />
        </RouteCan>
      ),
    },
    {
      path: '/events',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <ManagePatchWindows eventType="scheduledEvent" key="keyPatch" />
        </RouteCan>
      ),
    },
    {
      path: '/events/create',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <PatchWindowsForm eventType="scheduledEvent" />
        </RouteCan>
      ),
    },
    {
      path: '/alerts/create',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <PatchWindowsForm eventType="oneTimeEvent" />
        </RouteCan>
      ),
    },
    {
      path: '/events/:id/edit',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <PatchWindowsForm key="events" eventType="scheduledEvent" />
        </RouteCan>
      ),
    },
    {
      path: '/alerts/:id/edit',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <PatchWindowsForm key="alerts" eventType="oneTimeEvent" />
        </RouteCan>
      ),
    },
    {
      path: '/alerts/:id/view',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <ParentTopicDetail />
        </RouteCan>
      ),
    },
    {
      path: '/alerts/:topicId/send',
      element: (
        <RouteCan I="view" a={SUBJECTS.PATCH_WINDOWS}>
          <AdminMenu container={menuContainer} />
          <NotificationForm formType="patchWindow" />
        </RouteCan>
      ),
    },
    {
      path: '/settings',
      element: (
        <RouteCan I="view" a={SUBJECTS.ADMIN_SETTINGS}>
          <AdminMenu container={menuContainer} />
          <Settings />
        </RouteCan>
      ),
    },
    {
      path: '/customer/:customerId',
      element: (

        <RouteCan I="view" a={SUBJECTS.TOPICS}>
          <CustomerContainer
            container={menuContainer}
            component={CustomerDashboard}
          ></CustomerContainer>
        </RouteCan >
      ),
    },
    {
      path: '/customer/:customerId/alerts',
      element: (
        <RouteCan I="view" a={SUBJECTS.TOPICS}>
          <CustomerContainer
            container={menuContainer}
            component={ManageAlerts}
          />
        </RouteCan>
      ),
    },
    {
      path: '/customer/:customerId/users',
      element: (
        <RouteCan I="view" a={SUBJECTS.CUSTOMER_USERS}>
          <CustomerContainer
            container={menuContainer}
            component={ManageTeamUsers}
          />
        </RouteCan>
      ),
    },
    {
      path: '/customer/:customerId/alerts/:id/edit',
      element: (
        <RouteCan I="view" a={SUBJECTS.TOPICS}>
          <CustomerContainer
            component={PatchWindowsForm}
            container={menuContainer}
          />
        </RouteCan>
      ),
    },
    {
      path: '/customer/:customerId/alerts/:id',
      element: (
        <RouteCan I="view" a={SUBJECTS.TOPICS}>
          <CustomerContainer
            container={menuContainer}
            component={ParentTopicDetail}
          />
        </RouteCan>
      ),
    },
    {
      path: 'customer/:customerId/distribution_list',
      element: (
        <RouteCan I="view" a={SUBJECTS.DISTRIBUTION_LIST}>
          <CustomerContainer
            container={menuContainer}
            component={ManageDistributionList}
          />
        </RouteCan>
      )
    },
    {
      path: '/customer/:customerId/teams',
      element: (
        <RouteCan I="view" a={SUBJECTS.TEAMS}>
          <CustomerContainer
            container={menuContainer}
            component={ManageTeams}
          />
        </RouteCan>
      ),
    },
    {
      path: '/customer/:customerId/settings',
      element: (
        <RouteCan I="view" a={SUBJECTS.SETTINGS}>
          <CustomerContainer container={menuContainer} component={Settings} />
        </RouteCan>
      ),
    },
    { path: '/wip', element: <WorkInProgress /> },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div className="w3-light-grey">
        <Header />
        <div ref={menuContainer} className="secondary-menu" />
        <div className="main-content w3-padding">
          {confirmMessage && (confirmMessage.title !== '' || confirmMessage.message !== '') &&
            <ConfirmDialog
              message={confirmMessage.message}
              handleOk={()=>{
                confirmMessage.handleOk()
                dispatch(setConfirmDialogMessage('','',()=>{},()=>{}))
              }}
              title={confirmMessage.title}
              handleCancel={()=>{
                confirmMessage.handleCancel()
                dispatch(setConfirmDialogMessage('','',()=>{},()=>{}))
              }}
            />
          }
          {alertMessage && alertMessage.message !== '' &&
            <div className="textMarginCenter">
              <AlertDialog
                message={alertMessage.message}
                type={alertMessage.msgType}
                handleDialogClose={()=>{
                  dispatch(setAlertDialogMessage('', ''))
                }}
                handleClose={() => {
                  dispatch(setAlertDialogMessage('', ''))
                  if (alertMessage.callback) {
                    alertMessage.callback();
                  }
                }
                }
              />
            </div>
          }
          <Routes>
            {routesArray.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              >
                {/* {route.nestedRoute && route.nestedRoute.map(nested=><Route path={nested.path} element={nested.element} />)} */}
              </Route>
            ))}
          </Routes>
        </div>
        <div className="app-footer">
          <img src={img} alt="footer-border" />
          <div className="copy-right">
            Copyright © 2022 Xylem Inc. All rights reserved.
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AppRoutes;
