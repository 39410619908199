import { Button, FormControl, Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postUpdateTopic, postCreateNotificationChannel } from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';
import { emailValidator } from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
        margin: theme.spacing(0.5),
        },
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom:'1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        marginLeft: '0px',
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: 'none',
    },
}));

const SubscriptionChannelModal = (props: any) => {
  const { handleClose, editData, customerId } = props;
  const navigate=useNavigate()
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser } = state;
  const [alertMessage, setAlertMessage] = useState<any>();
  const [ showLoader, setShowLoader ] = useState(false);
  const error: any = {};
  const [errors, setErrors] = useState(error);
  let formData: any = {
    channelType: '',
    emailId: '',
    phoneNumber: '',
  };
  if (editData) {
    formData = editData;
  }
  const [form, setForm] = useState(formData);
  const modalTitle: string = editData ? 'Edit Team' : 'Add Subscription Channel';
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [msgType, setMsgType] = useState("success");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const data: any = Object.assign({}, form);
    data[name] = value;
    const err = Object.assign({}, errors);
    if (err[name]) {
      err[name] = null;
      delete err[name];
    }
    setErrors(err);
    setForm(data);
  };

  const showAlert = (msg: any, type: any) => {
    setAlertMessage(msg);
    setMsgType(type);
  }

  const updateResponse = (resp: any, message: string) => {
    const res = resp && resp.data;
    let msg = res.message;
    let type = "fail";
    if (res && res.success) {
      msg = message;
      type = "success";
    }
    setShowLoader(false);
    // triggerList();
    showAlert(msg, type);
  };

  const handleDialogClose = () => {
    setAlertMessage(null);
  };


  const validateData = () => {
    const err: any = {};
    if (!form.channelType) {
        err['channelType'] = 'Field is Required';
    }
    if (form.channelType === 'email' && !emailValidator(form.emailId)) {
        err['emailId'] = 'Enter a valid email';
    }
    if (form.channelType === 'phoneNumber' && !form.phoneNumber) {
        err['phoneNumber'] = 'Field is Required';
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }
    return true;
  };
 


  const handleSubmit = () => {
    const checkForm = validateData();
    if (checkForm) {
      Object.assign(form, {
          channelId: form.emailId.toLowerCase(),
          channelType: form.channelType,
          verifiedAt: '',
          verificationToken: 'c',
          userId: currentUser.data.data.id,
          status: 'Invited',
      });
      setShowLoader(true);
      if (form.id) {
        // FIXIT - Check flow dispatch(postUpdateTopic(form)).then((resp: any) => {
        //     updateResponse(resp, 'User Notification Channel updated successfully');
        // });
      } else {
        dispatch(postCreateNotificationChannel(form))
          .then((resp: any) => {
            updateResponse(resp, 'User Notification Channel created successfully');
          });
      }
    }
  };

  return (
    <div style={{paddingBottom:'20px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className={classes.title}>
            {modalTitle}
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
            <InputLabel className={classes.selectLabel} ref={inputLabel} id="type">
              Select Invite Channel*
            </InputLabel>
            <MuiSelect
              labelId="notificationTimeLabel"
              id="channelType"
              name="channelType"
              value={form.channelType}
              onChange={v => handleChange(v)}
              fullWidth
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phoneNumber">Phone Number</MenuItem>
              {/* <MenuItem value="slack">Slack</MenuItem> */}
            </MuiSelect>
          </FormControl>
          <ErrorHelperText error={errors.channelType} />
        </Grid>

        <Grid item xs={12} className={` ${form.channelType === 'email'  ? "w3-show" : "w3-hide"}`}>
            <TextField
                fullWidth
                id="emailId"
                name="emailId"
                margin="dense"
                label="Enter Email Id here"
                value={form.emailId.toLowerCase().trim() || ''}
                onChange={(e) => handleChange(e)}
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.label
                    }
                }}
            />
            <ErrorHelperText error={errors.emailId} />
        </Grid>

        <Grid item xs={12} className={` ${form.channelType === 'phoneNumber'  ? "w3-show" : "w3-hide"}`}>
            <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                margin="dense"
                label="Enter Mobile Number here"
                value={form.phoneNumber|| ''}
                onChange={(e) => handleChange(e)}
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.label
                    }
                }}
            />
            <ErrorHelperText error={errors.phoneNumber} />
        </Grid>

        <Grid item xs={12} className={` ${form.channelType === 'slack'  ? "w3-show" : "w3-hide"}`}>
            <TextField
                fullWidth
                id="slack"
                name="slack"
                margin="dense"
                label="Enter Slack Id here"
                value={form.slack|| ''}
                onChange={(e) => handleChange(e)}
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.label
                    }
                }}
            />
            <ErrorHelperText error={errors.slack} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={handleClose}
              >Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                className={`${classes.buttonLabel}`}
                onClick={handleSubmit}
              >
                {'Save'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {alertMessage ?
        (
          <AlertDialog
            handleClose={() => {
                setAlertMessage(null);
                navigate(`/customer/${customerId}/settings`);
                handleClose();
            }}
            message={alertMessage}
            type={msgType}
            handleDialogClose={handleDialogClose}
          />
        )
        : null
      }
      { showLoader ? <Loader open={showLoader}/> : null}
    </div>
  );
}

export default SubscriptionChannelModal;