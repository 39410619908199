import React, { useEffect } from 'react';
import Login from '../Components/Auth/Login';
import Register from '../Components/Auth/Register';
import ForgotPassword from '../Components/Auth/ForgotPassword';
import ResetPassword from '../Components/Auth/ResetPassword';
import { Routes, Route } from 'react-router-dom';
import Invite from '../Components/Auth/Invite';
import Channel from '../Components/Subscriptions/Channel';
import TopBar from '../Components/Common/TopBar';
import { lightBlue, indigo, cyan } from '@material-ui/core/colors'; 
import { useMediaQuery, createTheme } from '@material-ui/core';
import {  ThemeProvider } from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom'
const img = 'https://cdn.ceegees.in/xylem/xylem-wave.png';


const LayoutRoutes = () => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
    const navigate =  useNavigate()
    const currentPath = window.location.pathname 
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        // Purple and green play nicely together.
                        main: lightBlue[800],
                        contrastText: "#FFF"
                    },
                    secondary: {
                        main: indigo[500]
                    },
                    type: 'light',
                },
            }),
        [prefersDarkMode],
    );
    useEffect(()=>{
        if(currentPath.includes("register") ||
            currentPath.includes("forgot-password") ||
            currentPath.includes("reset-password") ||
            currentPath.includes("invite") ||  
            currentPath.includes("channel")
        ){
            return 
        }
        navigate('/')
    },[currentPath])
    return (
        <ThemeProvider theme={theme}>
            <div className="app-auth-form w3-padding-32" >
                <div className="w3-padding">
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:token" element={<ResetPassword />} />
                        <Route path="/invite/:token" element={<Invite />} />
                        <Route path="/channel/:verificationToken" element={<Channel />} />
                        
                     </Routes>
                     
                </div>
            </div>
        </ThemeProvider>)
}

export default LayoutRoutes;
