export default {

    // auth
    currentUser: {
        url: '/api/v1/auth/user',
    },
    logout: {
        url: '/api/v1/auth/logout',
    },
    currentUserRoles: {
        url: '/api/v1/roles/current-user-roles',
    },
    firstUser: {
        url: '/api/v1/auth/first-user'
    },
    updateAdminStatus: {
        url: '/api/v1/roles/update-user-role',
        method: 'POST'
    },
    getRoleUsers: {
        url: '/api/v1/roles/get-role-users',
        method: 'GET'
    },
    register: {
        url: '/api/v1/auth/register',
        method: 'POST',
    },
    login: {
        url: '/api/v1/auth/login',
        method: 'POST',
    },
    forgotPassword: {
        url: '/api/v1/auth/forgot-password',
        method: 'POST',
    },
    resetPassword: {
        url: '/api/v1/auth/reset-password',
        method: 'POST',
    },

    //users

    usersList: {
        url: '/api/v1/auth/all-users',
        method: 'GET',
    },
    pocUsersList: {
        url: '/api/v1/roles/get-all-poc',
    },
    findUser: {
        url: '/api/v1/invites/find-user',
    },

    //invite
    invitedUserList: {
        url: '/api/v1/invites/all-invites',
    },
    invitedUserListWithRoles: {
        url: '/api/v1/invites/all-invites-roles',
    },
    invitedMemberList: {
        url: '/api/v1/invites/member-invites',
    },
    inviteUser: {
        url: '/api/v1/invites/create',
        method: 'POST',
    },
    editInviteUser: {
        url: '/api/v1/invites/update',
        method: 'PUT',
    },
    deleteInviteUser: {
        url: '/api/v1/invites/delete',
        method: 'DELETE',
    },
    changeUserPassword: {
        url: '/api/v1/auth/change-password',
        method: 'PUT',
    },
    changeUserName: {
        url: '/api/v1/auth/change-username',
        method: 'PUT',
    },
    acceptInvite: {
        url: '/api/v1/invites/accept',
        method: 'POST',
    },
    validateToken: {
        url: '/api/v1/invites/validate-token',
    },
    invitedTeamMemberList: {
        url: '/api/v1/invites/member-team-invites',
    },
    getSubCheckBox: {
        url: '/api/v1/invites/get-sub-checkbox',
    },
    acceptOrReject: {
        url: '/api/v1/invites/accept-or-reject',
        method: 'POST',
    },

    //DashBoard
    dashboardStats: {
        url: '/api/v1/customers/dashboard-stats',
    },
    getRole: {
        url: '/api/v1/roles/get-role',
    },

    //Customers

    customerDashStats: {
        url: '/api/v1/customers/customer-dash-stats'
    },
    customerList: {
        url: '/api/v1/customers/list-customers',
    },
    deleteCustomer: {
        url: '/api/v1/customers/delete',
        method: 'DELETE',
    },
    createCustomer: {
        url: '/api/v1/customers/create',
        method: 'POST',
    },
    editCustomer: {
        url: '/api/v1/customers/edit',
        method: 'PUT',
    },
    filterCustomers: {
        url: '/api/v1/customers/filter-customers',
    },
    customerDetails: {
        url: '/api/v1/customers/details'
    },
    allCustomers: {
        url: '/api/v1/customers/all-customers'
    },
    confirmCustomers: {
        url: '/api/v1/customers/confirm',
        method: 'POST',
    },

    //Teams
    teamList: {
        url: '/api/v1/teams/all-teams',
    },
    teamListAll: {
        url: '/api/v1/teams/all-teams',
    },
    teamListing: {
        url: '/api/v1/teams/teams',
    },
    teamInfo: {
        url: '/api/v1/teams/get-team',
    },
    createTeam: {
        url: '/api/v1/teams/create',
        method: 'POST',
    },
    updateTeam: {
        url: '/api/v1/teams/update',
        method: 'PUT',
    },
    deleteTeam: {
        url: '/api/v1/teams/delete',
        method: 'DELETE',
    },
    findTeam: {
        url: '/api/v1/teams/find-teams',
    },

    getAllTeamList: {
        url: '/api/v1/teams/team-list',
    },

    inviteTeamMember: {
        url: '/api/v1/teams/invite-member',
        method: 'POST'
    },

    // Topics

    createTopic: {
        url: '/api/v1/topics/create',
        method: 'POST',
    },

    createTopicReminders: {
        url: '/api/v1/topics/create-reminders',
        method: 'POST',
    },

    topicList: {
        url: '/api/v1/topics/list-topics',
    },

    topicReminderList: {
        url: '/api/v1/topics/list-topic-reminders',
    },

    patchWindowList: {
        url: '/api/v1/topics/list-patch-windows',
    },

    editTopic: {
        url: '/api/v1/topics/edit',
        method: 'PUT',
    },

    editTopicInstance: {
        url: '/api/v1/topics/edit-instance',
        method: 'PUT',
    },

    topicListById: {
        url: '/api/v1/topics/details',
    },

    topicRemindersListByTopicId: {
        url: '/api/v1/topics/reminder-list-details',
    },

    topicInstanceByTopicId: {
        url: '/api/v1/topics/instance-details',
    },

    topicById: {
        url: '/api/v1/topics/detail',
    },

    deleteTopic: {
        url: '/api/v1/topics/delete',
        method: 'DELETE',
    },

    deleteTopicReminder: {
        url: '/api/v1/topics/delete-reminder',
        method: 'DELETE',
    },

    findTopic: {
        url: '/api/v1/topics/filter-topics',
    },

    topicCount: {
        url: '/api/v1/topics/get-topic-count',
    },

    settingsData: {
        url: '/api/v1/{type}/settings-data',
        method: 'POST',
    },

    mondayTriggers: {
        url: '/api/v1/topics/monday-triggers',
        method: 'POST',
    },

    pauseOrEnableAllTopics:{
        url: '/api/v1/topics/pause-or-enable-all-topics',
        method: 'POST',
    },

    pauseOrEnableSelectedTopics:{
        url: '/api/v1/topics/pause-or-enable-selected-topics',
        method: 'POST',
    },

    activityLog:{
        url:'/api/v1/topics/activity-log'
    },

    //subscription
    createSubscription: {
        url: '/api/v1/subscription/create',
        method: 'POST',
    },

    subscriptionList: {
        url: '/api/v1/subscription/list-subscriptions',
    },

    editSubscription: {
        url: '/api/v1/subscription/edit',
        method: 'PUT',
    },

    unsubscribeSubscription: {
        url: '/api/v1/subscription/unsubscribe',
        method: 'PUT',
    },

    deleteSubscription: {
        url: '/api/v1/subscription/delete',
        method: 'DELETE',
    },

    subscriptionListByTopicId: {
        url: '/api/v1/subscription/details',
    },

    //notificationChannels
    createNotificationChannel: {
        url: '/api/v1/notification-channel/create',
        method: 'POST',
    },


    acceptChannelInvite: {
        url: '/api/v1/notification-channel/accept',
        method: 'PUT',
    },

    channelList: {
        url: '/api/v1/notification-channel/list-channels',
    },

    deleteChannel: {
        url: '/api/v1/notification-channel/delete',
        method: 'DELETE',
    },

    distributionList: {
        url: '/api/v1/notification-channel/distribution-list',
    },

    createDistributionList: {
        url: '/api/v1/notification-channel/create-distribution-list',
        method: 'POST',
    },

    updateDistributionList: {
        url: '/api/v1/notification-channel/update-distribution-list',
        method: 'PUT',
    },
    deactivateDistributionList: {
        url: '/api/v1/notification-channel/deactivate-distribution-list',
        method: 'PUT',
    },
    deleteDistributionList: {
        url: '/api/v1/notification-channel/delete-distribution-list',
        method: 'DELETE',
    },

    //notifications
    createNotification: {
        url: '/api/v1/notifications/create',
        method: 'POST',
    },

    confirmNotification: {
        url: '/api/v1/notifications/confirm',
        method: 'POST',
    },

    allNotifications: {
        url: '/api/v1/notifications/get-notifications',
        method: 'GET'
    },

    getSubscribers: {
        url: '/api/v1/notifications/get-subscribers',
        method: 'GET'
    },

    metaByName: {
        url: '/api/v1/meta/get-meta-byname',
    },

    versionNumber: {
        url: '/api/v1/meta/version',
    },

    // Terms
    getAllTerms: {
        url: '/api/v1/meta/all-terms',
    },

    // Hertbeat
    heartBeat: {
        url: '/api/v1/auth/heart-beat',
    },

    //file download
    getSampleFile: {
        url: '/api/v1/invites/file',
    }
};
