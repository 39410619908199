import React, { useState, useEffect } from 'react';
import { Grid, Drawer, Divider, List, FormControl, Button, MenuItem, InputLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import MuiSelect from '@material-ui/core/Select';

const useStyles = makeStyles({
    label: {
        background: 'white',
        padding: '0px 5px',
    },
    list: {
        paddingTop: 50,
        maxWidth: 380,
        padding: 20,
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        minWidth: 300,
    },
    filterIcon: {
        marginTop: '4px',
        marginRight: '20px',
    },
    reactSelect: {
        zIndex: 5
    },
    scrollMenuList: {
        maxHeight: 200,
        overflow: 'auto'
    },
    buttonLabel: {
        textTransform: "none",
    },
});

const DistributionListFilters = ({ filterData, handleDLFilters }: any) => {
    const classes = useStyles();
    const [drawerState, setDrawerState] = React.useState({
        right: false,
    });
    const [filter, setFilter] = useState<any>(Object.assign({
        status: '',
    }));
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    type DrawerSide = 'right';
    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        setDrawerState({ ...drawerState, [side]: open });
    };

    useEffect(() => {
        let status = '';
        if (filterData?.status) {
            status = filterData.status;
        }
        setFilter({
            status
        });
    }, [filterData]);

    const handleSelectFilter = (event: any) => {
        const { name, value } = event.target;
        filter[name] = value;
        setFilter({
            [name]: value
        });
    }

    const triggerFilter = () => {
        handleDLFilters({
            status: filter.status
        });
        setDrawerState({
            right: false,
        });
    }

    const handleClearFilter = () => {
        setFilter({
            status: ''
        })
        handleDLFilters({
            status: ''
        });
        setDrawerState({
            right: false,
        });
    }


    const sideList = (side: DrawerSide) => (
        <div
            className={classes.list}
            role="presentation"
        >
            <h3>Filters</h3>
            <List>
                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel} ref={inputLabel} id="statusFilterLabel">
                        Status
                    </InputLabel>
                    <MuiSelect
                        labelId="statusFilterLabel"
                        id="status"
                        name="status"
                        value={filter.status || ''}
                        onChange={handleSelectFilter}
                        fullWidth
                    >
                        <MenuItem value="Verified">Verified</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                    </MuiSelect>
                </FormControl>
            </List>
            <Divider />
            <List>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Button variant="contained"
                        size="small"
                        onClick={handleClearFilter}>
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={triggerFilter}
                        className={`w3-center ${classes.buttonLabel}`}>
                        Filter
                    </Button>
                </Grid>
            </List>

        </div>
    );

    return (
        <div>
            <IconButton className={`w3-right cursor-pointer ${classes.filterIcon}`} onClick={toggleDrawer('right', true)} >
                <FilterListIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
                {/* ModalProps={{ onBackdropClick: ()=> clearFilterState() }}> */}
                {sideList('right')}
            </Drawer>
        </div>
    )


}

export default DistributionListFilters;