import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Grid,
  Paper,
  CircularProgress,
  Box,
  Button,
  Tooltip,
  Chip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { red, green } from '@material-ui/core/colors';
import AlertDialog from '../Common/AlertDialog';
import UserModal from './UserModal';
import {
  deleteInviteUser,
  getInvitedUserList,
  updateInviteUser,
} from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import TitleHeader from '../Common/TitleHeader';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSearchParams } from 'react-router-dom';
import UserFilters from './UserFilters';
import TableUI from '../Common/TableUI';
import { ROLE_NAMES } from '../../Context/userRoles';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS } from '../../utils/contants';
import { removeNonValueFilters } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '8px'
  },
  card: {
    height: 120,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    //padding: '10px',
    //fontSize: '14px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '12vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '65vw'
    }
  },
  content: {
    padding: '5px 10px'
  },
  cardHeader: {
    padding: '10px'
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '10vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw'
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1)
  },
  addUserCard: {
    marginTop: '50px'
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  userCardSideTitle: {
    fontSize: '13px'
  },
  toolTip: {
    fontSize: '13px'
  },
  table: {
    minWidth: 700,
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  buttonLabel: {
    textTransform: "none"
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default function ManageUsers(props: any) {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser } = state;
  const initialData: any[] = [];
  let manageUsers: any = null;
  const [data, setData] = useState(initialData);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [alertMessage, setAlertMessage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(Object.assign({}));
  const [msgType, setMsgType] = useState("success");
  const tableColumns = ['Name', 'Email', 'Role', 'Updated On', 'Status', 'Action']

  const limit = 12;
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);

  const fetchData = () => {
    let page: number = parseInt(queryParams.get('page'));
    const params = {
      ...Object.fromEntries(queryParams),
      offset: (page ? page - 1 : 0) * limit
    }
    setIsLoading(true);
    dispatch(getInvitedUserList(params))
      .then((resp: any) => {
        if (resp && resp.status === 200) {
          const userListData = resp.data;
          if (!userListData) {
            return;
          }
          const respData = getUsersListWithoutCurrentUser(resp.data.data)
          setData(respData); 
          setTotalCount(userListData.total);
          setIsLoading(false);
        }
      }).catch(() => setIsLoading(false));
  };

  const getUsersListWithoutCurrentUser = (dataList: any) => {
    if (!dataList) {
      return;
    }
    let userDataList = dataList;
    return userDataList;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [queryParams, dispatch]);

  const updateQueryParams = (params: any) => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  const handleSearch = (value: string) => {
    updateQueryParams({ search: value, page: 1 });
  }

  const handlePagination = (page: number, lmt: number) => {
    updateQueryParams({ page, limit: lmt });
  };


  const handleDeleteInvite = () => {

    if (selectedUser) {
      setShowConfirmDialog(false);
      dispatch(deleteInviteUser(selectedUser.id)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage('User Invite Deleted Successfully...!');
          setSelectedUser({});
          fetchData();
        } else if (res && res.success === false) {
          setAlertMessage(res.message);
          setMsgType("fail");
        } else {
          setAlertMessage('User Invite Deletion failed...!');
          setMsgType("fail");
        }
      })
    }
  }

  const triggerDelete = (user: any) => {
    setSelectedUser(user);
    setShowConfirmDialog(true);
  }

  const handleUserFilters = (filters: any) => {
    const { type="", status="" } = filters;
    updateQueryParams({ type, status, page: 1 });
  }

  const handleDialogClose = () => {
    setAlertMessage(null);
    setOpen(false);
  };

  const userList = data && data.map((item,idx) =>(<TableRow key={`user-item-${idx}`}>
    <TableCell className={classes.tDef} align="left">{item.user ? item.user.name : item.name}</TableCell>
    <TableCell className={classes.tDef} align="left">{item.user ? item.user.uniqueId : item.email}</TableCell>
    <TableCell className={classes.tDef} align="left">{ROLE_NAMES[item.type]}
    </TableCell>
    <TableCell className={classes.tDef} align="left">
      {moment(item.updatedAt).format('Do MMM YYYY')}
    </TableCell>
    <TableCell className={classes.tDef} align="left">
      <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
        <Chip
          size="small"
          className={`w3-margin-right ${item.status === APP_STATUS.JOINED ? 'w3-green' : item.status === APP_STATUS.INVITED ? 'w3-yellow' : 'w3-red'}`}
          label={item.status}
        />
        {item.status === APP_STATUS.JOINED &&
          item?.user?.json?.lastActiveTime &&
            <Chip
              size="small"
              className={`w3-margin-right ${moment().isSameOrAfter(moment(item?.user?.json?.lastActiveTime)
                .add(20, 'minutes')) || item?.user?.json?.loginStatus === 'logged-out' ? 'w3-blue-grey': 'w3-blue'}`}
              label={moment().isSameOrAfter(moment(item?.user?.json?.lastActiveTime)
                .add(20, 'minutes')) || item?.user?.json?.loginStatus === 'logged-out' ?'Offline':'Online'}
            /> }
  
          {item.status === APP_STATUS.JOINED && <Box component="div" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {item?.user?.json?.lastActiveTime && <div className="w3-tiny">
              <span className="w3-opacity">Last Active:</span> {moment(item?.user?.json?.lastActiveTime).format('MMM Do YYYY, hh:mm a')}
            </div>}
            {item?.user?.lastLogin && <div className="w3-tiny">
              <span className="w3-opacity">Last Login:</span> {moment(item?.user?.lastLogin).format('MMM Do YYYY, hh:mm a')}
            </div>}
          </Box>}
      </Box>
    </TableCell>
    <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
      <Box component="div" display="flex" alignItems="center" justifyContent="flex-end">
        {item && ((item.status === APP_STATUS.JOINED) || (item.status === APP_STATUS.INACTIVE)) ? '' :
          <Tooltip title="Delete">
            <IconButton aria-label="deactivate" onClick={() => triggerDelete(item)}>
              <DeleteIcon className="cursor-pointer" />
            </IconButton>
          </Tooltip>
        }
        <Tooltip title="Edit">
          <IconButton aria-label="edit" onClick={() => {
            setEditData(item)
            setOpen(true)
          }}>
            <EditIcon className="Frsor-pointer" />
          </IconButton>
        </Tooltip>

      </Box>
    </TableCell>
  </TableRow>
  )
  );

  if (isLoading || !data) {
    manageUsers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageUsers = userList;
  } else if (data && data.length === 0) {
    manageUsers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Users Found
              </h5>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <div>
      <TitleHeader
        component={
          <div>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        className={` ${classes.buttonLabel}`}
        style={{ marginRight: 10 }}
        onClick={() => {
          setEditData(null);
          setOpen(true);
        }}
      >
        Invite User
        </Button>
      <HelperButton trigger="invite_user" />
      </div>
        }
        showSearch={false}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            value={queryParams.get('search') || ''}
            placeholder={'Search Users...'}
            search={handleSearch}
          />
        </div>
        <UserFilters
          filterData={existingParams}
          handleUserFilters={handleUserFilters}
        />
      </TitleHeader>
      <Grid container>
        <TableUI
          tableColumns={tableColumns}
          tableContent={manageUsers}
          scrollable={false}
          stickyHeader={false}
          borderTop={false}
          classes={classes}
        />
        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={parseInt(queryParams.get('page'))}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>

      {open ?
        (<DialogModal
          fullWidth
          isOpen={open}
          hideTitle={true}
          hideBtns={true}
          maxWidth="sm"
          dialogContent={(
            <UserModal
              triggerList={() => {
                fetchData()
              }}
              open={open}
              editData={editData}
              handleClose={() => {
                setOpen(false);
                setEditData(null);
              }}
            />
          )}
        />)
        : null}
      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
          type={msgType}
          handleDialogClose={handleDialogClose}
        />) : null}
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setSelectedUser({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteInvite}
            message={'Are you sure to delete the user invitation?'}
          />
        ) : null}
    </div>
  );

}
