import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TableCell, TableRow, Tooltip, IconButton, Grid } from '@material-ui/core';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { blue } from '@material-ui/core/colors';
import EmailContentModal from './EmailContentModal';
import DialogModal from '../Common/DialogModal';
import TableUI from '../Common/TableUI';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Axios from 'axios';
import HelperButton from '../Common/HelperButton';
import { useDispatch } from 'react-redux';
import { setAlertDialogMessage } from '../../Redux/actions';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 500,
        fontSize: 17,
        textAlign: 'left',
    },
    marginBottom10: {
        marginBottom: '10px',
    },
    marginTop10: {
        marginTop: '10px',
    },
    tHead: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    tDef: {
        fontSize: 15,
        padding: 8
    },
    tDefName: {
        fontSize: 15,
        '&:hover': {
            color: '#0085ad',
        },
    },
    buttonLabel: {
        textTransform: 'none',
    },
    breadCrumb: {
        color: "#0085ad",
        paddingBottom: 10,
    },
    emTable: {
        maxHeight: 350,
        overflowY: 'auto',
    },
    actionCell: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));




function NotificationsListing(props: any) {
    const { notificationsData } = props
    const navigate=useNavigate()
    const classes = useStyles();
    const dispatch: any = useDispatch();
    const [viewEmailContent, setViewEmailContent] = useState(false);
    const [viewEmailList, setViewEmailList] = useState(null);
    const [emailContent, setEmailContent] = useState<any>('');
    let manageNotifications: any = null;
    let notificationsListTable: any[] = [];
    let emailListTable: any[] = [];
    let manageEmailLists: any = null;
    let emailColumns = ['User', `Status`]

    const handleEmailListFetch = (item: any) => {
        Axios.get('/api/v1/notifications/notificaiton-emails', {
            params: {
                id: item.id,
                customerId: item.customerId,
                groupId: item.groupId
            }
        }).then(resp => {
            setViewEmailList(resp.data.data);
        }).catch(ex => {
            dispatch(setAlertDialogMessage("Error while fetching data", "fail"));
            console.error(ex);
        });
    }

    const tableColumns = ['Email Subject', 'Notification Type', 'Sent On', 'View']
    if (notificationsData && notificationsData.length > 0) {
        notificationsListTable = notificationsData.map((item: any) => (
            <TableRow key={item.id} >
                <TableCell className={classes.tDef} align="left">{item.expandedSubject}</TableCell>
                <TableCell className={classes.tDef} align="left">{item.type}</TableCell>
                <TableCell className={classes.tDef} align="left">{
                    item.sentAt === null ? 'Queued' : moment(item.sentAt).format('Do MMM YYYY hh:mm a')
                }</TableCell>
                <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
                    <Tooltip title="View Email Content">
                        <IconButton aria-label="view" onClick={() => {
                            setEmailContent(item.expandedContent)
                            setViewEmailContent(true)
                        }
                        }>
                            <VisibilityIcon className="cursor-pointer" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Email List">
                        <IconButton aria-label="view" onClick={() => {
                            handleEmailListFetch(item);
                        }
                        }>
                            <MailOutlineIcon className="cursor-pointer" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        ))
    }

    if (!notificationsData) {
        manageNotifications = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <div className="w3-center">
                        <CircularProgress />
                    </div>
                </TableCell>
            </TableRow>
        );
    } else if (notificationsData && notificationsData.length) {
        manageNotifications = notificationsListTable;
    } else if (notificationsData && notificationsData.length === 0) {
        manageNotifications = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        );
    }


    if (viewEmailList && viewEmailList.length > 0) {
        let hasCust = false;
        let sent = 0;
        emailListTable = viewEmailList.map((item: any, idx: number) => {
            if (item.notification) {
                hasCust = true;
            }
            if (item.status == 'Sent') {
                sent++;
            }
            return <TableRow>
                <TableCell className={classes.tDef} align="left">{idx + 1}) {item.channelInfo ? item.channelInfo.channelId : item.channelEndpoint}</TableCell>
                {item.notification && <TableCell className={classes.tDef} align="left">
                    {item.notification.customer ? item.notification.customer.name : 'NA'}
                </TableCell>}
                <TableCell className={classes.tDef} align="left">{item.status}
                    <span className="w3-text-grey w3-margin-left w3-tiny">{moment(item.updatedAt).fromNow()}
                    </span></TableCell>
            </TableRow>
        });
        if (hasCust) {
            emailColumns = ['User', 'Customer', 'Status'];
        } else {
            emailColumns = ['User', 'Status ']
        }
    }

    if (!viewEmailList) {
        manageEmailLists = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <div className="w3-center">
                        <CircularProgress />
                    </div>
                </TableCell>
            </TableRow>
        );
    } else if (viewEmailList && viewEmailList.length) {
        manageEmailLists = emailListTable;
    } else if (viewEmailList && viewEmailList.length === 0) {
        manageEmailLists = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        );
    }


    return (
        <div className="w3-white"  >
            <div className="w3-padding-small w3-border-bottom">
                <Grid container justifyContent="space-between">
                    <h4>Notification Timeline</h4>
                    <HelperButton trigger="notification_timeline_helper" />
                </Grid>
            </div>
            <TableUI
                tableContent={manageNotifications}
                tableColumns={tableColumns}
                stickyHeader={true}
                scrollable={false}
                classes={classes}
                borderTop={false}
            />
            {viewEmailContent && (<DialogModal
                fullWidth
                isOpen={viewEmailContent}
                hideTitle={true}
                hideBtns={true}
                maxWidth="md"
                dialogContent={(
                    <EmailContentModal
                        emailContent={emailContent}
                        classes={classes}
                        handleClose={() => {
                            setViewEmailContent(false)
                            setEmailContent('')
                        }}
                    />
                )}
            />)}

            {viewEmailList && (<DialogModal
                fullWidth
                showClose={true}
                isOpen={true}
                dialogTitle="Email List"
                hideBtns={true}
                onClose={() => {
                    setViewEmailList(null);
                }}
                maxWidth="md"
                dialogContent={(
                    <div style={{ minHeight: "300px" }}>
                        <TableUI
                            tableContent={manageEmailLists}
                            tableColumns={emailColumns}
                            stickyHeader={true}
                            scrollable={true}
                            classes={classes}
                            borderTop={true}
                        />
                    </div>
                )}
            />)}
        </div>
    )
}

export default NotificationsListing
