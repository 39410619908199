import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link , useNavigate } from 'react-router-dom'; 
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postForgotPassword} from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { TextInputField } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import { emailValidator } from '../../utils';
import { XylemLogo, XylemCopyright} from '../Common/XylemBranding';

const useStyles = makeStyles(theme => ({
  formTop: {
    marginTop: '100px',
  },
  buttonLabel: {
    textTransform: 'none',
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const initForm: any = {
    email: '',
  };
  const initErr: any = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [disableBtn, setDisableBtn] = useState(false);
  const [ showLoader, setShowLoader ] = useState(false);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
      errorField[name] = null;
      setErrors(errorField);
    }
    fieldValue[name] = value;
    setForm(fieldValue);
  }; 

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (!emailValidator(form.email)) {
      hasError = true;
      err.email = 'Enter a valid email.';
    }
    Object.keys(form).forEach((key) => {
      if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
        if (!form[key].match(/\w/)) {
          hasError = true;
          err[key] = 'This field is required';
        }
      }
      if (!form[key]) {
        hasError = true;
        err[key] = 'This field is required';
      }
    });
    if (hasError) {
      setErrors(err);
      return false;
    }
    return form;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      setShowLoader(true)
      setDisableBtn(true);
      dispatch(postForgotPassword(valid)).then((resp: any) => {
        setShowLoader(false)
        const res = resp && resp.data;
        if (res && res.success) {
          setAlertMessage(res.message);
        } else if (res && res.data) {
          setErrors(res.data);
        } else {
          setAlertMessage('Something went wrong. Try again...!');
        }
        setDisableBtn(false);
      });
    }
  };
  return (
    <div>
      <Grid justifyContent="center" container className={classes.formTop}>
        <Grid item xs={12} sm={4} md={3} lg={3}  >
          <Card className="w3-white">
            <XylemLogo/>
            <form onSubmit={(e) => {
              handleSubmit(e);
            }}>
              <CardHeader title="Forgot Password"/>
              <CardContent>
                Enter your email address and we will send you a link to reset your password.
                <TextInputField
                  id="email"
                  name="email"
                  placeholder="Email"
                  variant="outlined"
                  margin="dense"
                  value={form.email.toLowerCase().trim()}
                  onChange={handleChange}
                  errors={errors.email}
                />
              </CardContent>

              <CardActions style={{ justifyContent: 'center' }}>
                <Button
                  id="submit-button"
                  color="primary"
                  disabled={disableBtn}
                  className={`${classes.buttonLabel}`}
                  variant="contained"
                  onClick={handleSubmit}
                >Send Reset Link
                </Button>
              </CardActions>
              <CardContent className="alignCenter">
                Back to <Link to="/login">Login</Link> 
              </CardContent>
            </form>
            <XylemCopyright />
          </Card>
        </Grid>
      </Grid>
      {alertMessage
        ? (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
              navigate('/login');
            }}
            message={alertMessage}
          />
        ) : null}
        { showLoader ? <Loader open={showLoader}/> : null}
    </div>
  );
};

export default ForgotPassword;
