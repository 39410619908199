import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { postUpdateTopicInstance } from '../../Redux/actions';
import { Grid, Tooltip, Chip, TableRow, TableCell, Box } from '@material-ui/core';
import ConfirmDialog from '../Common/ConfirmDialog';
import { Can } from '../../Context/Can';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/Done';
import AlertDialog from '../Common/AlertDialog';
import TableUI from '../Common/TableUI';

import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import HelperButton from '../Common/HelperButton';
import { SUBJECTS } from '../../utils/contants';
const StatusColorMap = {
    // 'Enabled': 'w3-green',
    'Disabled': 'w3-orange',
    'Canceled': 'w3-khaki',
    'Completed': 'w3-green',
    'Finished': 'w3-green'
}
export const TopicInstance = (props: any) => {

    const { topicId, setTrigger, topic, classes } = props;
    const [pickerConfig, setPickerConfig] = useState(null);

    const [confirmData, setConfirmData] = useState(null);
    const [alertMessage, setShowAlertMessage] = useState(null)
    const [msgType, setMsgType] = useState("success");
    const [instanceId, setInstanceId] = useState(null);

    topic.instanceList.sort((a: any, b: any) => b.eventSec - a.eventSec);

    const updateInstance = (data: any) => {
        postUpdateTopicInstance(Object.assign({
            topicId,
            instanceId
        }, data)).then((resp: any) => {
            setConfirmData(null)
            if (resp.status === 200 || resp.status === 201) {
                setTrigger(true);
            } else {
                setMsgType('fail')
                setShowAlertMessage(resp.data.message);
            }
        });
    };

    const instanceList = topic.instanceList.map((item: any, idx: number) => {
        const eventTime = moment(item.eventTime).format('MMM Do YYYY, hh:mm a');
        const status = item.status;
        return (<TableRow key={`instance_${idx}`}>
            <TableCell className={classes.tDef}>
                <Grid container alignItems="center" xs={12} justifyContent="flex-start" alignContent="flex-start" >
                    <Grid item md={7} xs={7}>
                        {status !== 'Completed' ? <><b>{eventTime} </b>
                            {item.completedAt && <div className="w3-tiny">
                                ETA for completion : {moment(item.completedAt).format('MMM Do YYYY, hh:mm a')}
                            </div>}
                        </> :
                            <span>{eventTime} <br />
                                {item.completedAt && <span className="w3-tiny">
                                    Completed At : {moment(item.completedAt).format('MMM Do YYYY, hh:mm a')}</span>}
                                    <br />
                            </span>
                        }
                        {item.nextReminderAt &&
                            <span className="w3-tiny">
                                Next Reminder : {moment(item.nextReminderAt).format('MMM Do YYYY, hh:mm a')}</span>}
                    </Grid>
                    <Grid item md={5} xs={5} alignContent="flex-end" justifyContent="flex-end">
                        {StatusColorMap[status] && <Chip
                            size="small"
                            className={StatusColorMap[status]}
                            label={status}
                        />
                        }
                        {status === 'Enabled' &&
                            <Can do="manage" on={SUBJECTS.EDIT_PATCH_INSTANCE}>
                                <Tooltip title="Edit">
                                    <IconButton aria-label="edit" onClick={() => {
                                        setInstanceId(item.id);
                                        setPickerConfig({
                                            type: 're-schedule',
                                            okLabel: 'Change Start Time',
                                            date: moment(item.eventTime).toDate()
                                        })
                                    }}>
                                        <EditIcon className="cursor-pointer" />
                                    </IconButton>
                                </Tooltip>
                            </Can>
                        }
                        {status === 'Enabled' &&
                            <Can do="manage" on={SUBJECTS.EDIT_PATCH_INSTANCE}>
                                <Tooltip title="  Mark  Completion Time">
                                    <IconButton aria-label="mark complete" onClick={e => {
                                        setInstanceId(item.id);
                                        setPickerConfig({
                                            okLabel: 'Change End Time',
                                            type: 'mark-end-time',
                                            date: new Date()
                                        })
                                    }} >
                                        <DoneIcon className="cursor-pointer" />
                                    </IconButton>
                                </Tooltip>
                            </Can>
                        }
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>)
    });

    return (
        <Grid container className="w3-white w3-margin-bottom">
            <Grid item xs={12} >
                <div className={`${classes.headerText} w3-border-bottom`}>
                    <Grid container justifyContent="space-between">
                        <h4>Patch Events</h4>
                        <HelperButton trigger="topic_instance" />
                    </Grid>
                </div>
            </Grid>
            <TableUI
                tableContent={instanceList}
                stickyHeader={true}
                scrollable={true}
                classes={classes}
                borderTop={false}
            />
            {pickerConfig && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    value={pickerConfig.date}
                    open={true}
                    TextFieldComponent={() => null}
                    okLabel={pickerConfig.okLabel}
                    onClose={() => {
                        setPickerConfig(null);
                    }}
                    onChange={e => {
                        if (pickerConfig.type == 're-schedule') {
                            setConfirmData({
                                message: <div>Do you want to Re-schedule the event to {moment(e).format('YYYY-MM-DD hh:mm a')}?'</div>,
                                data: {
                                    date: e
                                },
                            })
                        } else if (pickerConfig.type == 'mark-end-time') {
                            setConfirmData({
                                message: <div>Maintenance completion will be scheduled at {moment(e).format('YYYY-MM-DD hh:mm a')} <br />The reminder emails will get triggered after this.</div>,
                                data: {
                                    completedAt: e,
                                    status: 'Completed'
                                }
                            });
                        }
                    }}
                />
            </MuiPickersUtilsProvider>}
            {confirmData && <ConfirmDialog
                handleCancel={() => {
                    setConfirmData(null);
                }}
                handleOk={() => {
                    updateInstance(confirmData.data);
                }}
                message={confirmData.message}
            />}

            {alertMessage && <AlertDialog
                handleDialogClose={() => setShowAlertMessage(null)}
                type={msgType}
                message={alertMessage}
            />}
        </Grid>
    );
}