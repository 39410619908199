import React,{useState} from 'react';
import { Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, 
TableRow, Tooltip, TextField, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme)=>({
    marginTop10:{
        marginTop:'10px',
        maxHeight: 400,
        overflowY: 'auto',
    },
    tHead:{
        fontSize:16, 
        fontWeight:'bold'
    },
    tDef:{
    fontSize:15,
    },
    tDefName:{
    fontSize:15,
    '&:hover': {
        color: '#0085ad',
    },
    },
    actionCell:{
      paddingTop:0,
      paddingBottom:0
    },
    buttonLabel: { 
        textTransform: "none" 
    },
}));

const ViewTaggedCustomersModal = (props: any) => {
    const { handleClose, customerData } = props;
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState<any>("");
    let custData : any = customerData;
    let manageCustomers: any = null;

    const handleChange = (event: any) => {
      setSearchTerm(event.target.value);
    };
  
    if (searchTerm && searchTerm.length >= 3) {
      const results = custData.filter((customer: any) => customer.customerName.toLowerCase().includes(searchTerm.toLowerCase())); 
      custData = results;
    } else {
      custData = customerData;
    }

    let customersTable: any[] = [];
    if (custData && custData.length > 0) {
        customersTable = custData.map((item: any) => {
          let selectedTag: any = [];
          if(item && item.tags.length > 0) {
            item.tags.map((items: any) => {
              selectedTag.push(items);
            })
          }
      return (
        <TableRow key={item.customerName}>
          <TableCell className={classes.tDef}>{item.customerName}</TableCell>
          <TableCell className={classes.tDef}>{selectedTag.join(', ')}</TableCell>
        </TableRow>
      )
    })
  }
  if (!customerData) {
    manageCustomers = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (custData && custData.length) {
    manageCustomers = customersTable;
  } else if (custData && custData.length === 0) {
    manageCustomers = (
      <TableRow>
            <TableCell colSpan={6} align="center">
              <h5>
                No Customers Found
              </h5>
            </TableCell>
      </TableRow>
    );
  }

    return (
        <div>
          <Grid container alignItems='center'> 
            <Grid item xs={6} md={5} container justifyContent="flex-start">
              <TextField fullWidth
                  className='w3-margin-left'
                  margin="dense"
                  label="Search Customer"
                  value={searchTerm}
                  onChange={(e) => handleChange(e)}
                  variant="outlined"
              />
            </Grid>
            <Grid item xs={6} md={7} container justifyContent="flex-end">
              <Tooltip title="Close">
                <div className='w3-margin-right'>
                  <IconButton 
                      onClick={handleClose}
                      className={`${classes.buttonLabel}`}
                    >
                    <CloseIcon className="cursor-pointer"
                      style={{ color: 'black' }}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <TableContainer className={`w3-border-top w3-margin-bottom ${classes.marginTop10}`}>
              <Table stickyHeader>
                  <TableHead>
                  <TableRow>
                      <TableCell className={classes.tHead}>Customer</TableCell>
                      <TableCell className={classes.tHead}>Tags</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                      {manageCustomers}
                  </TableBody>
              </Table>
          </TableContainer>
        </div>
      );
};

export default ViewTaggedCustomersModal;