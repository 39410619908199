import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Chip, CircularProgress, Box, Button, TableCell, TableRow, Tooltip, Select, MenuItem, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {useNavigate} from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import AlertDialog from '../Common/AlertDialog';
import { getPatchWindowList, destroyTopic, postPauseOrEnableAllTopics, setAlertDialogMessage, setConfirmDialogMessage, postPauseOrEnableSelectedTopics } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import ConfirmDialog from '../Common/ConfirmDialog';
import TitleHeader from '../Common/TitleHeader';
import EditIcon from '@material-ui/icons/Edit';
import { Can } from '../../Context/Can';
import PatchFilters from './PatchFilters';
import TelegramIcon from '@material-ui/icons/Telegram';
import TableUI from '../Common/TableUI';
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CheckIcon from '@material-ui/icons/Check';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PauseIcon from '@material-ui/icons/Pause';
import HelperButton from '../Common/HelperButton';
import { removeNonValueFilters } from '../../utils';
import { SCHEDULED_EVENT_STATUS, SCHEDULED_EVENT_STATUS_COLOR, SUBJECTS } from '../../utils/contants';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  addPaper: {
    padding: '25px',
    height: 180,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    // padding: '10px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '18vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
  },
  content: {
    padding: '0px 5px 5px 5px',
    marginBottom: '8px',
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
    },
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  toolTip: {
    fontSize: '13px'
  },
  customToolTip: {
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 5px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '13px'
  },
  table: {
    minWidth: 700,
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
  buttonLabel: {
    textTransform: "none",
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  selectTop:{
    borderRadius: 16,
  },
  selectControl:{
    padding: '4px 8px',
    color: 'white',
    fontSize: 12,
  },
  arrowDownIcon:{
    color:'white',
    margin: 1,
  },
}));

const limit = 12;

export default function ManagePatchWindows(props: any) {
  const { customerId, eventType } = props;
  const navigate=useNavigate()
  const store = useSelector(state => state);
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageTopics: any = null;
  const [data, setData] = useState(initialData);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<any>(Object.assign({}));
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams: any = Object.fromEntries([...queryParams]);
  const [selected, setSelected]= useState([]);
  
  let tableColumns = ['ID', 'Title', 'Event Type', 'Type', 'Environment', 'Status', 'Action'];

  const sideTitle = eventType === 'scheduledEvent' ? 'Maintenance Windows' : 'Alerts';
  if (eventType == 'scheduledEvent') {
    tableColumns = ['ID', 'Title', 'Event Type', 'Type', 'Environment', "Event", 'Status', 'Action'];
  }
  

  const fetchData = () => {
    let page: number = parseInt(queryParams.get("page"));
    const params = {
      ...Object.fromEntries(queryParams),
      eventType,
      offset: (page ? page - 1 : 0) * limit
    }
    setIsLoading(true);

    getPatchWindowList(params).then((res: any) => {
      if (res && res.status === 200) {
        const respData = res.data
        setData(respData.data);
        setTotalCount(respData.total);
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    if(eventType == 'scheduledEvent'){
      setSelected([])
    }
  }, [queryParams, eventType]);


  const updateQueryParams = (params: any) => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  const handleSearch = (value: string) => {
    updateQueryParams({ search: value, page: 1 });
  }

  const handlePagination = (page: number, limit2: number) => {
    updateQueryParams({ page, limit: limit2 });
  };

  const handleTopicFilters = (filters: any) => {
    const { type="", status="", environment="" } = filters;
    updateQueryParams({ type, status, environment, page: 1 });
  }

  const handleDeleteTopic = () => {
    const refId = customerId
    if (selectedTopic) {
      setShowConfirmDialog(false);
      dispatch(destroyTopic(selectedTopic.id, refId)).then((resp: any) => {
        if (resp && resp.status === 200) {
          const result = resp.data;
          if (!result.success) {
            setAlertMessage(result.message);
            return;
          }
          setSelectedTopic({});
          setAlertMessage(result.message);
          fetchData();
        } else {
          setAlertMessage('Alert deletion failed');
        }
      });
    }
  };

  const handleChangeStatus = (e: any, eItem:any) => {
    setIsLoading(true);
    postPauseOrEnableAllTopics({
      topicId: eItem.id,
      prevStatus:eItem.status,
      status: e.target.value,
    }).then((res: any) => {
      setIsLoading(false);
      const resp = res && res.data;
      if (resp && resp.success) {
        fetchData();
      }else if(resp && !resp.success){
        dispatch(setAlertDialogMessage(resp.message, 'fail'));
      }
    }).catch(() => {
      setIsLoading(false);
      dispatch(setAlertDialogMessage(`Something went wrong, Please try again`, 'fail',() =>{
        fetchData();
      }));
    });
  }

  const handlePauseOrEnableTopics = (status:string) =>{
    const splitValue = status.split('-');
    const selection = splitValue[1];
    let oldStatus = null;
    let newStatus = null;
    let selectedEvents = selected;
    if(splitValue[0] === 'pause'){
      oldStatus = status === `pause-${selection}` ? 'enabled' : 'paused'
      newStatus = status === `pause-${selection}` ? 'paused' : 'enabled'
      if(selected.length > 0){
        selectedEvents = data.filter((fItem:any)=>selected.find((sItem:any)=>(fItem.id == sItem && fItem.status == SCHEDULED_EVENT_STATUS.ENABLED))).map(mItem=>mItem.id);
      }
    } else if(splitValue[0] === 'enable'){
      oldStatus = status === `enable-${selection}` ? 'paused' : 'enabled'
      newStatus = status === `enable-${selection}` ? 'enabled' : 'paused'
      if(selected.length > 0){
        selectedEvents = data.filter((fItem:any)=>selected.find((sItem:any)=>(fItem.id == sItem && fItem.status == SCHEDULED_EVENT_STATUS.PAUSED))).map(mItem=>mItem.id);
      }
    }

    const postPauseOrEnable = selection == 'selected' ? postPauseOrEnableSelectedTopics({ status, selectedEvents }) : postPauseOrEnableAllTopics({status});
    setIsLoading(true);
    postPauseOrEnable.then((res:any)=>{
      setIsLoading(false);
      const resp = res && res.data;
      if (resp && resp.success) {
        dispatch(setAlertDialogMessage(`${selection == 'selected'? 'All selected':'All'} ${oldStatus} events are ${newStatus} successfully`, 'success',() =>{
          document.location.reload();
        }));
      }else if(resp && !resp.success){
        dispatch(setAlertDialogMessage(resp.message, 'fail'));
      }
    }).catch((err:any)=>{
      setIsLoading(false);
      dispatch(setAlertDialogMessage(`Something went wrong, Please try again`, 'fail', () =>{
        fetchData();
      }));
    })
  }

  const confirmPauseOrEnableTopics = (status: string) => {
    const splitValue = status.split('-');
    const selection = splitValue[1];
    let oldStatus = null;
    let newStatus = null;
    if(splitValue[0] === 'pause'){
      oldStatus = status === `pause-${selection}` ? 'enabled' : 'paused'
      newStatus = status === `pause-${selection}` ? 'pause' : 'enable'
    } else if(splitValue[0] === 'enable'){
      oldStatus = status === `enable-${selection}` ? 'paused' : 'enabled'
      newStatus = status === `enable-${selection}` ? 'enable' : 'pause'
    }      

    dispatch(setConfirmDialogMessage('', `Are you sure you want to ${newStatus} ${selection} ${oldStatus} events?`,
      () => handlePauseOrEnableTopics(status), 
      () => {}));
  }

  const handleSelectAllClick = (event:any) => {
    if (event.target.checked) {
      setSelected(data.map((item: any) =>item.id));
      return;
    }
    setSelected([]);
  };


  const handleCheckboxClick = (evt:any, id:number) => {
    evt.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id:number) => selected.indexOf(id) !== -1;

  let topicListTable: any[] = [];
  if (data && data.length > 0) {
    topicListTable = data.map((item: any, idx:number) => {
      return (
        <TableRow 
          key={`topic-item-${idx}`}
          role="checkbox"
          aria-checked={isSelected(item.id)}
          tabIndex={-1}
          selected={isSelected(item.id)}
        >
          {eventType == 'scheduledEvent' && <TableCell padding="checkbox">
            <Checkbox
              onClick={(evt:any) => handleCheckboxClick(evt, item.id)}
              checked={isSelected(item.id)}
            />
          </TableCell>}
          <TableCell className={classes.tDef} align="left">{item.id}</TableCell>
          <TableCell className={classes.tDef} align="left">
            <Link to={item.eventType == 'scheduledEvent' ? `/alerts/${item.id}/view` : `/alerts/${item.id}/view`}>
              {item.title}
            </Link>
          </TableCell>
          <TableCell className={classes.tDef} align="left">{item.eventType == 'scheduledEvent' ? 'Maintenance Window' : 'Alert'}</TableCell>
          <TableCell className={classes.tDef} align="left">{item.eventType == 'scheduledEvent' ? item.scheduleType : item.type}</TableCell>
          <TableCell className={classes.tDef} align="left">{item.environment}</TableCell>
          {(eventType == 'scheduledEvent') &&
            <TableCell className={classes.tDef} align="left">{
              item.instanceList.map((instance: any, idx:number) => {
                return <div key={`intance-item-${idx}`} className="w3-small">
                  <span className="w3-margin-right " style={{ position: "relative", top: "6px" }}>
                    {(instance.status === 'Enabled' && moment(instance.eventTime).isAfter(Date.now())) &&
                      <span className="w3-text-yellow"><ShowChartIcon fontSize="small" /></span>
                    }
                    {(instance.status === 'Enabled' && moment(instance.eventTime).isBefore(Date.now())) &&
                      <span className="w3-text-red"><FlashOnIcon fontSize="small" /></span>
                    }
                    {(instance.status === 'Paused') && <span className="w3-text-teal"><PauseIcon fontSize="small" /></span>}
                    {(instance.status === 'Completed') && <span className="w3-text-green"><CheckIcon fontSize="small" /></span>}
                  </span>
                  at
                  <Tooltip title="Event Time">
                    <b> {moment(instance.eventTime).format('YYYY-MM-DD hh:mm a')}
                    </b></Tooltip>
                  {instance.nextReminderAt && <i className="w3-text-grey w3-small">
                    , <Tooltip title="Next reminder for the event"><span>
                      Reminder : {moment(instance.nextReminderAt).format('YYYY-MM-DD hh:mm a')}</span>
                    </Tooltip>
                  </i>
                  }
                </div>
              })}
            </TableCell>}
          <TableCell className={classes.tDef} align="left">
            {eventType == 'scheduledEvent' ?
              <Select
                value={item.status}
                onChange={(e) => handleChangeStatus(e, item)}
                inputProps={{ 'aria-label': 'Without label' }}
                displayEmpty
                disableUnderline
                className={`${classes.selectTop} ${SCHEDULED_EVENT_STATUS_COLOR[item.status]}`}
                classes={{
                  root: classes.selectControl,
                  icon: classes.arrowDownIcon,
                }}
              >
                {
                  Object.entries(SCHEDULED_EVENT_STATUS).map(([objkey, objValue]) => <MenuItem key={objkey} value={objValue}>{objValue}</MenuItem>)
                }
              </Select>
              :
              <Chip
                size="small"
                className={item.status === 'Enabled' ? 'w3-green' : 'w3-red'}
                label={item.status}
              />}
          </TableCell>
          <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="right">
            <Box component="div" display="flex" alignItems="right" justifyContent="flex-end">
              <Tooltip title="Edit">
                <IconButton aria-label="edit"
                  component={Link}
                  to={item.eventType == 'scheduledEvent' ? `/events/${item.id}/edit` : `/alerts/${item.id}/edit`}
                >
                  <EditIcon className="cursor-pointer" />
                </IconButton>
              </Tooltip>
              {(item.customerType === 'customer_topic' || item.customerType === 'global_topic' || item.customerType === 'tag_topic') && item.status === 'Enabled' &&
                <Tooltip title="Send">
                  <IconButton aria-label="Send" component={Link} to={`/alerts/${item.id}/send`}>
                    <TelegramIcon className="cursor-pointer" />
                  </IconButton>
                </Tooltip>}

              <Tooltip title="View">
                <IconButton component={Link} aria-label="view" to={`/alerts/${item.id}/view`}>
                  <ChevronRightIcon className="cursor-pointer" />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      )
    })
  }
  if (isLoading || !data) {
    manageTopics = (
      <TableRow>
        <TableCell colSpan={8} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageTopics = topicListTable;
  } else if (data && data.length === 0) {
    manageTopics = (
      <TableRow>
        <TableCell colSpan={8} align="center">
          <h5>
            No {sideTitle} Found
              </h5>
        </TableCell>
      </TableRow>
    );
  }

  let hasPausedInSelection = data.some((fItem:any)=>selected.find((sItem:any)=>(fItem.id == sItem && fItem.status == SCHEDULED_EVENT_STATUS.PAUSED))) 
  let hasEnabledInSelection = data.some((fItem:any)=>selected.find((sItem:any)=>(fItem.id == sItem && fItem.status == SCHEDULED_EVENT_STATUS.ENABLED)));

  if (existingParams.status == SCHEDULED_EVENT_STATUS.ENABLED) {
    hasEnabledInSelection = true
  }
  if (existingParams.status == SCHEDULED_EVENT_STATUS.PAUSED) {
    hasPausedInSelection = true
  }

  if(!existingParams.status && selected.length == 0){
    hasEnabledInSelection = true
    hasPausedInSelection = true
  }

  return (
    <div >
      <TitleHeader
        component={
          <Can do="manage" on={SUBJECTS.MANAGE_PATCH_WINDOW}>
            {eventType === "scheduledEvent" ? <div><Button
              variant="contained"
              size="medium"
              color="primary"
              className={`${classes.buttonLabel}`}
              // component={A}
              // href="/events/create"
              onClick={() => navigate('/events/create')}
              style={{ marginRight: 10 }}
            >
              Create Maintenance Window
            </Button>
              <HelperButton trigger="create_maintenance" />
            </div> :
              <div>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  // component={A}
                  className={`${classes.buttonLabel}`}
                  // href="/alerts/create"
                  onClick={() => navigate('/alerts/create')}
                  style={{ marginRight: 10 }}
                >
                  Create Alert
            </Button>
                <HelperButton trigger="create_alert" />
              </div>
            }
          </Can>
        }
        showSearch={true}>
        <Can do="manage" on={SUBJECTS.MANAGE_PATCH_WINDOW}>
        {eventType == 'scheduledEvent' && <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
           <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => confirmPauseOrEnableTopics(selected.length>0?'pause-selected':'pause-all')}
            className={`${classes.buttonLabel}  w3-margin-right`}
            disabled={!hasEnabledInSelection}
          >
            {`Pause ${hasEnabledInSelection && selected.length >0?'Selected':'All'} Events`}
          </Button>
           <Button 
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => confirmPauseOrEnableTopics(selected.length>0?'enable-selected':'enable-all')}
            className={`${classes.buttonLabel}  w3-margin-right`}
            disabled={!hasPausedInSelection}
          >
            {`Enable ${hasPausedInSelection && selected.length >0?'Selected':'All'} Events`}
          </Button>
          </Box>}
          </Can>
        <div style={{ marginRight: '10px'}}>
          <SearchBox
            value={queryParams.get("search") || ''}
            placeholder={`Search ${sideTitle}...`}
            search={handleSearch}
          />
        </div>
        <PatchFilters
          filterData={existingParams}
          handleTopicFilters={handleTopicFilters}
          eventType={eventType}
        />
      </TitleHeader>
      <Grid container component={Paper} elevation={0}>
        <TableUI
          scrollable={false}
          stickyHeader={false}
          classes={classes}
          tableColumns={tableColumns}
          tableContent={manageTopics}
          borderTop={false}
          selectAll={eventType == 'scheduledEvent'}
          numSelected={selected.length}
          rowCount={data.length}
          onSelectAllClick={handleSelectAllClick}
        />

      </Grid>
      {( totalCount > limit) && (
        <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
          <Pagination
            cPage={parseInt(queryParams.get("page"))}
            defaultPerPage={limit}
            data={{ totalCount }}
            onChange={handlePagination}
          />
        </Grid>)}

      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
        />) : null}
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setSelectedTopic({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteTopic}
            message={'Are you sure to delete the Alert?'}
          />
        ) : null}
    </div>
  );
}
