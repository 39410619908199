import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Portal,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router';
import { useLocation, Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WorkIcon from '@material-ui/icons/Work';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PersonIcon from '@material-ui/icons/Person';
import OpenInBrowserRoundedIcon from '@material-ui/icons/OpenInBrowserRounded';
import { logout, setConfirmDialogMessage, setStoreData } from '../../Redux/actions';
import { Can } from '../../Context/Can';
import EventIcon from '@material-ui/icons/Event';
import defaultLogo from '../../img/logo_placeholder.png'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { userRoles } from '../../Context/userRoles';
import { subject } from '@casl/ability';
import { SUBJECTS } from '../../utils/contants';

const img = 'https://cdn.ceegees.in/xylem/logo.png';
const drawerWidth = 240;

const useStyles = makeStyles({
  flexGrow: {
    flexGrow: 1
  },
  rightAlign: {
    float: 'right'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0

  },
  drawerPaper: {
    width: drawerWidth
  },
  noDecoration: {
    textDecoration: 'none'
  },
  logoContainer: {
    textAlign: 'center',
    padding: 6,
    paddingTop: 80,
    position: 'relative',
  },
  logo: {
    width: '60%',
    height: 65,
    maxWidth: '100%',
    objectFit: 'contain',
  },
  defaultLogo: {
    width: '60%',
    height: 65,
    maxWidth: '100%',
    objectFit: 'cover',
  },
  padding0: {
    paddingTop: '0px !important'
  },
  appBar: {
    zIndex: 1202
  },
  tabs: {
    borderWidth: '4px',
    borderBottom: 'solid 2px rgb(60, 105, 201)',
    borderRadius: 0
  },
  noPermissionContainer:{
    marginTop:150,
    display:'flex',
    justifyContent:'center'
  }
});

const useNavigateX = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (nextPath) => {
    const currentPath = window.location.pathname
    if (
      currentPath === "/events/create" ||
      currentPath.includes("events") && currentPath.includes("edit") ||
      currentPath === "/customers/create" ||
      currentPath.includes("customers") && currentPath.includes("edit") ||
      // currentPath==="/alerts/create" || 
      currentPath.includes("alerts") && currentPath.includes("edit")
    ) {
      return dispatch(setConfirmDialogMessage("","do you really want to navigate?", () => navigate(nextPath),()=>{}))
    }
    return navigate(nextPath)
  }
}
const MenuItemText = (props: any) => {
  const navigate = useNavigateX()
  return <Button onClick={() => navigate(`${props.href}`)} className={`
  ${props.path == props.href ? `${props.injectClass}` : ''} 
  w3-text-grey w3-padding main-menu`}>
    <props.icon fontSize="small" style={{ marginRight: "6px" }} />
    <span >{props.primary}</span>
  </Button>
}

export const RouteCan = (props:any) => {
  const { children, ...extra } = props;
  const classes = useStyles();
  return <>
      <Can not {...extra}>
          <div className={`${classes.noPermissionContainer}`}>
            <div className='w3-white w3-padding-64 w3-padding-large w3-round-large'>
              <h3>You don't have the permission to view this section</h3>
            </div>
          </div>
      </Can>
      <Can {...extra}>
          {children}
      </Can>
  </>
};

export const AdminMenu = (props: any) => {
  const navigate = useNavigate()
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { drawer } = state;
  const path = useLocation().pathname;

  return (<Portal container={props.container.current}>
    <div className="w3-bar"  >
      <MenuItemText
        injectClass={classes.tabs}
        path={path}
        href='/dashboard'
        icon={DashboardIcon}
        primary={'Dashboard'} />
      <Can I="view" a={SUBJECTS.PATCH_WINDOWS}>
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          href='/alerts'
          icon={NotificationsIcon}
          primary={'Alerts'} />
      </Can>
      <Can I="view" a={SUBJECTS.PATCH_WINDOWS}>
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          icon={EventIcon}
          href='/events'
          primary={'Maintenance Windows'} />
      </Can>
      <Can I="view" a={SUBJECTS.ALL_CUSTOMERS}>
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          href='/customers'
          icon={WorkIcon}
          primary={'Customers'} />
      </Can>
      <Can I="view" a={SUBJECTS.XYLEM_USERS}>
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          icon={PersonIcon}
          href='/users'
          primary={'Xylem Users'} />
      </Can>
      <MenuItemText
        injectClass={classes.tabs}
        icon={SettingsIcon}
        href='/settings'
        path={path}
        primary={'Settings'} />
    </div>
  </Portal>)
}

export const CustomerMenu = (props: any) => {
  const navigate = useNavigate()
  const { customerId:cid, customer } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentCustomer, drawer } = state;
  const path = useLocation().pathname;
  const customerId = `${cid}`;


  return (<Portal container={props.container.current}>
    <div className="w3-bar">
      <Can I="view" this={subject(SUBJECTS.ALL_CUSTOMERS,{customerId})} field="customerId">
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          icon={ArrowBackIosIcon}
          href={`/customers`}
          primary={'Back to Admin'} />
      </Can>
      <Can I="view" this={subject(SUBJECTS.DASHBOARD,{customerId})} field="customerId">
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          icon={DashboardIcon}
          href={`/customer/${customerId}`}
          primary={'Dashboard'} />
      </Can>
      
      <Can I="view" this={subject(SUBJECTS.TOPICS,{customerId})} field="customerId">
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          primary={'Alerts'}
          href={`/customer/${customerId}/alerts`}
          icon={NotificationsIcon}
        />
      </Can>
      <Can I="view" this={subject(SUBJECTS.CUSTOMER_USERS,{customerId})} field="customerId">
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          primary={'Users'}
          href={`/customer/${customerId}/users`}
          icon={PersonIcon} />
      </Can>
      <Can I="view" this={subject(SUBJECTS.DISTRIBUTION_LIST,{customerId})} field="customerId">
        <MenuItemText
          injectClass={classes.tabs}
          path={path}
          primary={'Distribution List'}
          href={`/customer/${customerId}/distribution_list`}
          icon={PlaylistAddCheckIcon} />
      </Can>
      <MenuItemText
        injectClass={classes.tabs}
        path={path}
        icon={SettingsIcon}
        href={`/customer/${customerId}/settings`}
        primary={'Settings'} />

    </div>
  </Portal>)
}

const Header = () => {
  const navigate = useNavigate()

  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser, drawer, currentCustomer, currentUserRoles } = state;

  const toggleDrawer = () => {
    dispatch(setStoreData('drawer', !drawer))
  };

  let loginUser = '';
  if (currentUser && currentUser.data) {
    loginUser = currentUser.data.data.name;
  }


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const customerRoles = [
    userRoles.CUSTOMER_ADMIN,
    userRoles.TEAM_LEAD,
    userRoles.TEAM_MEMBER
  ];

  const customerList = currentUserRoles.data.data.reduce((agg: any, role: any) => {
    if (customerRoles.indexOf(role.type) >= 0 && !agg.some((item: any) => item.customerId == role.customerId)) {
      agg.push(role);
    }
    return agg;
  }, []);

  return (
    <div className="header-section">
      <AppBar position="fixed" className={'appBar'} style={{ zIndex: 1210 }}>
        <Toolbar variant="dense" >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className="menuButton"
          >
            <MenuIcon />
          </IconButton>
          <Link to="/"  >
            <img src={img} style={{ height: '36px' }} alt="logo" />
          </Link>
          <div className={classes.flexGrow}>
            {currentCustomer && <span style={{ color: 'lightgrey', display: 'inline-block', padding: "10px 20px" }} >
              <b>|&nbsp;&nbsp;&nbsp;&nbsp;{currentCustomer.name}</b>
            </span>}
            <Button className={classes.rightAlign} color="inherit" aria-haspopup="true" onClick={handleClick}
              endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}>
              {loginUser}
            </Button>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}

              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 190
                }
              }}
            >

              {customerList.map((item: any) => {
                return <MenuItem key={item.customerId} onClick={() => {
                  navigate(`/customer/${item.customerId}`);
                }}>{item.customer.name}</MenuItem>
              })}
              <MenuItem onClick={() => {
                dispatch(logout()).then(() => {
                  localStorage.removeItem('access_token');
                  navigate('/login');
                  window.location.reload();
                })
              }}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
