import { Grid, Typography, Button, Breadcrumbs, Paper, TableContainer, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DialogModal from '../Common/DialogModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicById, getAllNotifications, getSubscriptionListByTopicId, postUnsubscribeSubscription, getSubscribersList, changeUserName, getCurrentUser } from '../../Redux/actions';
import TopicRemainderListing from '../Topics/TopicRemainderListing';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { Can } from '../../Context/Can';
import NotificationListing from '../Topics/NotificationsListing';
import SubscriptionModal from '../Subscriptions/SubscriptionModal';
import TopicSubscriptionListing from '../Topics/TopicSubscriptionListing';
import AlertDialog from '../Common/AlertDialog';
import ConfirmDialog from '../Common/ConfirmDialog';
import { TopicInstance } from './TopicInstance';
import TableUI from '../Common/TableUI';
import { CustomerBreadcrumbs } from '../Customers/Breadcrumbs';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS, SUBJECTS } from '../../utils/contants';
import { useParams } from 'react-router-dom';
import ActivityLog from './ActivityLog';


const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: 17,
    textAlign: 'left',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
  buttonLabel: {
    textTransform: 'none',
  },
  breadCrumb: {
    color: "#0085ad",
    paddingBottom: 10,
  },
  emTable: {
    maxHeight: 275,
    overflowY: 'auto',
  },
  headerText: {
    padding: 3,
    paddingLeft: 15
  }
}));


const ParentTopicDetail = (props: any) => {
  const { customerId, customer } = props;
  const { id } = useParams();
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const state: any = useSelector(st => st);
  const { currentUser, channelList, subscriptionListByTopicId } = state;
  const [openModal, setOpenModal] = useState(false);
  const [topicData, setTopicData] = React.useState<any>();
  const [subscriptionData, setSubscriptionData] = React.useState<any>();
  const [notificationsData, setNotificationsData] = React.useState<any>([]);
  const [topicTitle, setTopicTitle] = React.useState<any>();
  const [alertMessage, setAlertMessage] = useState<any>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [msgType, setMsgType] = useState<any>();
  const [trigger, setTrigger] = useState(false);
  const [creationTrigger, setCreationTrigger] = useState(false);
  const [customerList, o] = useState<any>()
  const tableColumns = ['Customer List']
  const [alertType, setAlertType] = useState<any>('Alerts');
  const incidentAlert = currentUser.data.data.json.jSubCheckBox.incidentAlert
  const maintenanceAlert = currentUser.data.data.json.jSubCheckBox.maintenanceAlert
  useEffect(() => {
    const respData = subscriptionListByTopicId && subscriptionListByTopicId.data
    if (respData && respData.success) {
      setSubscriptionData(respData.data);
    }
  }, [subscriptionListByTopicId])

  useEffect(() => {
    if (id) {
      const topicParams: any = {
        id,
        customerId
      }
      dispatch(getAllNotifications(topicParams)).then((res: any) => {
        if (res && res.status === 200) {
          const respData = res.data
          if (!respData) {
            return;
          }
          setNotificationsData(respData)
        }
      }).catch(() => {
      });
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getTopicById(parseInt(id))).then((resp: any) => {
        const respData = resp && resp.data
        if (respData && respData.success) {
          setTopicData(respData.data);
          setTopicTitle(respData.data.title);
          if (respData.data.json && respData.data.json.selectedCustomer) {
            let sortByName = respData.data.json.selectedCustomer;
            sortByName.sort((a: any, b: any) => a.label.toLowerCase() < b.label.toLowerCase() ? - 1 : Number(a.label.toLowerCase() > b.label.toLowerCase()))
            o(sortByName)
          }
        }
      });
    }
    if (customerId) {
      dispatch(getSubscriptionListByTopicId(parseInt(id)));
    }
    setTrigger(false);
  }, [dispatch, trigger]);

  const handleUnsubscribe = () => {
    setShowConfirmDialog(true);
  }

  const handleUnsubscribeSubscription = () => {
    setShowConfirmDialog(false);
    dispatch(postUnsubscribeSubscription(subscriptionData, currentUser.data.data.id)).then((resp: any) => {
      if (resp.status === 200 || resp.status === 201) {
        dispatch(getSubscriptionListByTopicId(parseInt(id)));
        setAlertMessage('Subscription unsubscribed successfully');
        setMsgType("success");
        setTrigger(true);

      } else if (resp.status === 400) {
        setAlertMessage('Subscription updation failed');
        setMsgType("fail");
      }
    });
  }

  const handleDialogClose = () => {
    setAlertMessage(null);
  };

  const handleSubscription = () => {
    const data = {
      username: '',
      subCheckBox: {
        incidentAlert: alertType === 'Alerts' ? true : null,
        maintenanceAlert: alertType === 'Maintenance Windows' ? true : null
      }
    }

    if (data) {
      dispatch(changeUserName(data)).then((resp: any) => {
        const res = resp && resp.data;
        if (res && res.success) {
          dispatch(getCurrentUser()) 
        } else if (res && res.data) { 
        } else {
          console.log('Something went wrong. Try again...!');
        }
      });
    }
    setShowConfirmDialog(false)
  }



  let selectedWeek: any = [];
  let startingTime = null;
  let calendarValue = null;
  let scheduleData = null;
  let weekValue = null;
  let status = null;
  let systemStatus = null;
  if (topicData) {
    const { week, calendar, whichWeek } = topicData.json;
    startingTime = moment((topicData.instanceList ? topicData?.instanceList[0]?.eventTime : topicData?.parent?.instanceList[0]?.eventTime)).format('h:mm a');
    calendarValue = moment(calendar).format('Do');
    if (week) {
      week.forEach((items: any) => {
        if (items.checked === true) {
          const dayName = items.value.charAt(0).toUpperCase() + items.value.slice(1);
          selectedWeek.push(dayName);
        }
      })
    }

    if (topicData.scheduleType === 'daily') {
      scheduleData = `Scheduled Daily at ${startingTime}`
    }
    if (topicData.scheduleType === 'monthly') {
      let calendarDay  = moment(topicData.instanceList ? topicData?.instanceList[0]?.eventTime : topicData?.parent?.instanceList[0]?.eventTime).format('Do')
      scheduleData = `Scheduled Monthly on every ${calendarDay} at ${startingTime}`
    }
    if (topicData.scheduleType === 'weekly') {
      scheduleData = `Scheduled Weekly on every ${selectedWeek.join(', ')} at ${startingTime}`
    }
    if (topicData.scheduleType === 'fullWeek') {
      if (whichWeek == '1') {
        weekValue = '1st'
      }
      else if (whichWeek == '2') {
        weekValue = '2nd'
      }
      else if (whichWeek == '3') {
        weekValue = '3rd'
      }
      else {
        weekValue = '4th'
      }

      scheduleData = `Scheduled on every ${selectedWeek.join(', ')} of ${weekValue} week at ${startingTime}`
    }
    if (topicData.eventType === 'oneTimeEvent') {
      scheduleData = `Admin Triggered Instant Notification`;
    }
  }

  if (subscriptionData && subscriptionData.length > 0) {
    let list = subscriptionData.filter((i: any) => i.listId == currentUser.data.data.id && i.status === APP_STATUS.ACTIVE);
    subscriptionData.forEach((item: any) => {
      systemStatus = item.systemStatus;
    });

    if (channelList.data.data.length == list.length) {
      status = APP_STATUS.ACTIVE;
    }
  }

  let customerListTable: any[] = []
  if (customerList && customerList.length > 0) {
    customerListTable = customerList.map((customer: any) => {
      return (<TableRow key={customer.id}>
        <TableCell style={{ backgroundColor: 'white' }} className={classes.tDef}>{customer.label}</TableCell>
      </TableRow>
      )
    })
  }
  return (
    <div>
      {customer && <CustomerBreadcrumbs customer={customer} >
        <Link to={`/customer/${customerId}/alerts`}>Alerts</Link>
        <Typography color="textPrimary">{topicData && topicData.title}</Typography>
      </CustomerBreadcrumbs>
      }
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className={classes.title}>
          <h3>{topicData && topicData.title}</h3>
        </Grid>
        {topicData && topicData.customerType !== 'global_topic' &&
          <Can I="view" a={SUBJECTS.SUBSCRIPTION_BUTTON}>
            <Grid item xs={12} md={6} container alignItems="flex-end" justifyContent="flex-end" >
              {topicData.eventType === 'oneTimeEvent' ? <Button
                color="secondary"
                variant='contained'
                size='small'
                className={classes.buttonLabel}
                onClick={handleUnsubscribe}
                disabled={incidentAlert ? true : false}
              >
                {incidentAlert ? 'Subscribed' : 'Subscribe'}
              </Button> :
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={`${classes.buttonLabel}`}
                  onClick={() => {
                    setAlertType(topicData.eventType === 'scheduledEvent' ? 'Maintenance Windows' : 'Alerts')
                    setShowConfirmDialog(true)
                  }}
                  disabled={maintenanceAlert ? true : false}
                >
                  {maintenanceAlert ? 'Subscribed' : 'Subscribe'}
                </Button>}
            </Grid>
          </Can>
        }
        <Grid item xs={12} md={12}>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {topicData && topicData.description}
          </div>
        </Grid>
        <Grid item xs={12} md={7}    >
          <div className="w3-white w3-margin-bottom">
            <div className={`${classes.headerText} w3-border-bottom`}>
              <Grid container justifyContent="space-between">
                <h4>Event Schedule</h4>
                <HelperButton trigger="event_schedule" />
              </Grid>
            </div>
            <Grid container alignItems="center"
              className="w3-padding w3-border-bottom" >
              <Grid item>
                <EventNoteIcon style={{ color: '#666', fontSize: '60px' }} />
              </Grid>
              <Grid item ><b> {scheduleData}</b></Grid>
            </Grid>
          </div>

          {topicData && topicData.eventType === 'scheduledEvent' &&
            <TopicRemainderListing
              topic={topicData}
              classes={classes}
            />
          }
        </Grid>
        <Grid item xs={12} md={5} >
          {topicData && topicData.eventType === 'scheduledEvent' && <TopicInstance
            classes={classes}
            topicId={topicData.parentId == null ? topicData.id : topicData.parentId}
            topic={topicData.parentId == null ? topicData : topicData.parent}
            dispatch={dispatch}
            setTrigger={setTrigger}
            setAlertMessage={setAlertMessage}
          />}
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 15 }} >
        <Grid item xs={12} md={7}>
          <NotificationListing
            notificationsData={notificationsData}
          />
        </Grid>
        <Grid item xs={12} md={5}  >
          {!customerId && (customerList && customerList.length > 0) && <TableUI
            tableContent={customerListTable}
            scrollable={true}
            classes={classes}
            stickyHeader={true}
            tableColumns={tableColumns}
            borderTop={false}
          />}


          {customerId && topicData && topicData.customerType !== 'global_topic' &&

            <TopicSubscriptionListing
              id={id}
              trigger={trigger}
              creationTrigger={creationTrigger}
              customerId={customerId}
              eventType={topicData.eventType}
            />
          }

          {topicData && topicData.eventType === 'scheduledEvent' && <div style={{ marginTop: 15 }}>
            <ActivityLog
              classes={classes}
              // topicId={topicData.parentId == null ? topicData.id : topicData.parentId}
              // topic={topicData.parentId == null ? topicData : topicData.parent}
              topic={topicData}
            />
          </div>}
        </Grid>
      </Grid>

      {
        openModal ? (<DialogModal
          fullWidth
          isOpen={openModal}
          hideTitle={true}
          hideBtns={true}
          maxWidth="sm"
          dialogContent={(
            <SubscriptionModal
              isDisabled={true}
              topicId={id}
              topicTitle={topicTitle}
              customerId={customerId}
              handleClose={() => {
                setCreationTrigger(true);
                setOpenModal(false);
                dispatch(getSubscriptionListByTopicId(parseInt(id)));

              }}
            />
          )}
        />)
          : null
      }

      {
        alertMessage ? (
          <AlertDialog
            handleClose={() => setAlertMessage(null)}
            type={msgType}
            message={alertMessage}
            handleDialogClose={handleDialogClose}
          />) : null
      }
      {
        showConfirmDialog
          ? (
            <ConfirmDialog
              handleCancel={() => {
                setShowConfirmDialog(false)
              }}
              handleOk={handleSubscription}
              message={`This will get you subscribed to all ${alertType}. Are you sure ?`}
            />
          ) : null
      }

    </div >
  )
}
export default ParentTopicDetail;