import { Button, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicList, postUpdateSubscription, postCreateSubscription } from '../../Redux/actions';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import AlertDialog from '../Common/AlertDialog';
import { APP_STATUS } from '../../utils/contants';

const useStyles = makeStyles(theme => ({
  modalHeader: {
    padding: '15px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    textAlign: 'left',
    borderBottom: '1px solid black',
    marginBottom: 10
  },
  formGroup: {
    padding: '30px 40px',
  },
  marginBottom5: {
    marginBottom: '10px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  selectLabel: {
    background: 'white',
    padding: '0px 10px',
  },
  formControl: {
    marginLeft: '0px',
  },
  label: {
    background: 'white',
    padding: '0px 5px'
  },
  buttonLabel: {
    textTransform: "none"
  },
  reactSelect: {
    zIndex: 5
  },
}));

const SubscriptionModal = (props: any) => {
  const { handleClose, editData, customerId, topicTitle, topicId, isDisabled } = props;
  const classes = useStyles();
  const state: any = useSelector(st => st);
  const { currentUser, channelList } = state;
  const dispatch: any = useDispatch();
  const [alertMessage, setAlertMessage] = useState<any>();
  const [showLoader, setShowLoader] = useState(false);
  const error: any = {};
  const [errors, setErrors] = useState(error);
  let formData: any = {
    id: '',
    selectedTopic: '',
    selectedChannel: '',
  };
  let formValues: any = {
    id: '',
    selectedTopic: [{
      label: topicTitle ? topicTitle : '',
      value: topicId,
    }],
    selectedChannel: '',
  };
  if (editData) {
    const { selectedTopic, type } = editData.json;
    const editDataObj = {
      id: editData.id,
      selectedTopic,
      type
    }
    formData = editDataObj;
  }
  const [form, setForm] = useState(topicTitle ? formValues : formData);
  const modalTitle: string = editData ? 'Edit Subscription' : 'Create New Subscription';
  const [value, setValue] = useState<any>();
  const [subscriptionChannelData, setSubscriptionChannelData] = useState(props.channelList);
  let paginateData: any = {
    id: customerId,
  };

  const paginate = paginateData
  const [msgType, setMsgType] = useState("success");
  useEffect(() => {
    const list = channelList.data.data.map((item: any) => (
      {
        label: item.verifiedAt == null ? `Default ${item.channelId}` : item.channelId,
        value: item.id
      }
    ));
    setSubscriptionChannelData(list)
  }, channelList);

  useEffect(() => {
    getTopicList(paginate).then((resp: any) => {
      const respData = resp && resp.data.data
      const topics = respData.filter((item: any) => item.customerType != 'global_topic');
      if (topics && topics.length) {
        const list = topics.map((item: any) => (
          {
            label: item.title,
            value: item.id
          }
        ));
        setValue(list)
      }
    });

  }, [dispatch]);

  const handleSelect = (selected: any) => {
    setForm({
      ...form,
      selectedTopic: selected
    });
    const err = Object.assign({}, errors);
    if (err['selectedTopic']) {
      err['selectedTopic'] = null;
    }
    setErrors(err);
  }

  const handleSelectChannel = (selected: any) => {
    setForm({
      ...form,
      selectedChannel: selected
    });
    const err = Object.assign({}, errors);
    if (err['selectedChannel']) {
      err['selectedChannel'] = null;
    }
    setErrors(err);
  }

  const showAlert = (msg: any, type: any) => {
    setAlertMessage(msg);
    setMsgType(type);
  }

  const updateResponse = (resp: any, message: string) => {
    const res = resp && resp.data;
    let msg = res.message;
    let type = "fail";
    if (res && res.success) {
      msg = message;
      type = "success";
    }
    setShowLoader(false);
    showAlert(msg, type);
  };

  const handleDialogClose = () => {
    setAlertMessage(null);
  };


  const validateData = () => {
    const err = Object.assign({}, errors);
    if (!form.selectedTopic) {
      err.selectedTopic = 'Field is Required';
    }
    if (!form.selectedChannel) {
      err.selectedChannel = 'Field is Required';
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }
    return true;
  }; 
  const handleSubmit = () => {
    const json = {
      selectedTopic: form.selectedTopic,
    };
    const checkForm = validateData();
    if (checkForm) {
      Object.assign(form, {
        json,
        customerId,
        listId: currentUser.data.data.id,
        status: APP_STATUS.ACTIVE,
        channelId: form.selectedChannel.value,
      });
      setShowLoader(true);
      if (form.id) {
        dispatch(postUpdateSubscription(form, form.id)).then((resp: any) => {
          if (resp.status === 200 || resp.status === 201) {
            updateResponse(resp, 'Subscription edited successfully');
          } else if (resp.status === 400) {
            setAlertMessage('Subscription updation failed');
          }
        });
      } else {
        dispatch(postCreateSubscription(form)).then((resp: any) => {
          if (resp.status === 200 || resp.status === 201) {
            updateResponse(resp, 'Subscription created Successfully');
          } else if (resp.status === 400) {
            setAlertMessage('Subscription creation failed');
          }
        });
      }
    };
  }
  return (
    <div style={{ paddingBottom: '20px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.title}>
          {modalTitle}
        </Grid>
        <Grid item xs={12} className={classes.marginBottom5}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={`${classes.formControl} ${classes.reactSelect}`}>
            <Select
              isMulti
              id="select-multiple-topic"
              options={value}
              label="Select Alert"
              placeholder="Select Multiple Topics*"
              value={form.selectedTopic || ''}
              onChange={(e) => handleSelect(e)}
              maxMenuHeight={100}
              isDisabled={isDisabled}
            />
          </FormControl>
          <ErrorHelperText error={errors.selectedTopic} />
        </Grid>

        <Grid item xs={12} className={classes.marginBottom5}>
          <Select
            options={subscriptionChannelData}
            label="Select Alert"
            id="select-channel"
            placeholder="Select Channel*"
            value={form.selectedChannel || ''}
            onChange={(e) => handleSelectChannel(e)}
            maxMenuHeight={60}
          />
          <ErrorHelperText error={errors.selectedChannel} />
        </Grid>

        <Grid item xs={12} className={classes.marginTop10}>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                className={classes.buttonLabel}
                onClick={handleClose}
              >Cancel
                      </Button>
            </Grid>
            <Grid item>
              <Button
                color='primary'
                variant='contained'
                size='small'
                className={`${classes.buttonLabel}`}
                onClick={handleSubmit}
              >
                {editData ? 'Save' : 'Subscribe'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {alertMessage ?
        (
          <AlertDialog
            handleClose={() => {
              setAlertMessage(null);
              handleClose();
            }}
            message={alertMessage}
            type={msgType}
            handleDialogClose={handleDialogClose}
          />
        )
        : null
      }
      { showLoader ? <Loader open={showLoader} /> : null}
    </div>
  );
}

export default SubscriptionModal;