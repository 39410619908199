import React from 'react';
import { CircularProgress, TableCell, TableRow, Chip } from '@material-ui/core';
import TableUI from '../Common/TableUI';
import { SCHEDULED_EVENT_STATUS, SCHEDULED_EVENT_STATUS_COLOR } from '../../utils/contants';

const TopicRemainderListing = (props: any) => {
    const { topic, classes } = props;
    let manageReminders: any = null;
    const tableColumns = ['Reminder', 'Status']

  let reminderTable: any[] = [
    {
      key: 'at-time-of-start',
      reminder: 'At Start',
      status: SCHEDULED_EVENT_STATUS.ENABLED
    },
    {
      key: 'at-time-of-end',
      reminder: 'At End',
      status: SCHEDULED_EVENT_STATUS.ENABLED
    },
  ];
  if (topic.reminders && topic.reminders.length > 0) {
    const remindersList = topic.reminders.map((item: any) => {
      const { status } = item.json;
      return ({
        key: `rm-${item.offset}-${item.duration}-${item.type}`,
        reminder: `${item.offset} ${item.duration} ${item.type}`,
        status
      })
    });

    const everyReminderEnabled = remindersList.every((item: any) => item.status === SCHEDULED_EVENT_STATUS.ENABLED);
    const everyReminderPaused = remindersList.every((item: any) => item.status === SCHEDULED_EVENT_STATUS.PAUSED);
    const everyReminderDisabled = remindersList.every((item: any) => item.status === SCHEDULED_EVENT_STATUS.DISABLED);
    if (everyReminderEnabled) {
      reminderTable = reminderTable.map((item: any) => ({ ...item, status: SCHEDULED_EVENT_STATUS.ENABLED }));
    } else if (everyReminderPaused) {
      reminderTable = reminderTable.map((item: any) => ({ ...item, status: SCHEDULED_EVENT_STATUS.PAUSED }));
    } else if (everyReminderDisabled) {
      reminderTable = reminderTable.map((item: any) => ({ ...item, status: SCHEDULED_EVENT_STATUS.DISABLED }));
    }
    reminderTable = [...remindersList, ...reminderTable];
    reminderTable = reminderTable.map((item: any) => (
      <TableRow key={item.key}>
        <TableCell className={classes.tDef}>{item.reminder}</TableCell>
        <TableCell className={classes.tDef}>
          <Chip
            size="small"
            className={SCHEDULED_EVENT_STATUS_COLOR[item.status]}
            label={item.status}
          />
        </TableCell>
      </TableRow>
    ));
  }
  if (!topic.reminders) {
    manageReminders = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (topic.reminders && topic.reminders.length) {
    manageReminders = reminderTable;
  }
  else if (topic.reminders && topic.reminders.length === 0) {
    manageReminders = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Remainders Found
              </h5>
        </TableCell>
      </TableRow>
    );
  }



  return (
      <TableUI
        tableContent={manageReminders}
        tableColumns={tableColumns}
        stickyHeader={true}
        scrollable={false}
        classes={classes}
        borderTop={false}
      />
  );
};

export default TopicRemainderListing;