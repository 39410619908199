import React from "react";
import { TextField} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

interface TagOptionType {
    inputValue?: string;
    title: string;
}


export default function MuiSelectField(props: any) {

    const { options, size, limitTags, label, placeholder, value, onChange, allowNew, margin, disabled } = props;

    const filter = createFilterOptions<TagOptionType>();

    const filteredOptions = (optns: any, params: any) => {
        const filtered = filter(optns, params);
        if(allowNew){
            if (params.inputValue !== "") {
            filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`
            });
            }
        }
        return filtered;
    }

    const getOptionLabel = (option: any) => {
        if (typeof option === "string") {
            return option;
        }
        if (allowNew) {
            if (option.inputValue) {
                return option.inputValue;
            }
        }
        return option.title;
    }

    const renderInput = (params: any) => {
        return <TextField
            {...params}
            variant="outlined" 
            label={label}
            placeholder={placeholder} 
            size={size || 'small'}
            margin={margin || 'normal'}
        />
    }

    return (<Autocomplete
        size={size}
        limitTags={limitTags}
        multiple
        value={value}
        options={options}
        renderOption={(option) => option.title}
        filterOptions={filteredOptions}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        renderInput={renderInput}
        disabled={disabled}
    />);
}

