import React from 'react';
import {
    Button, Grid, TextField,  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ErrorHelperText } from '../Common/HelperInputFields';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px',
    },
    formControl: {
        marginLeft: '0px',
    },
    label: {
        background: 'white',
        padding: '0px 5px'
    },
    buttonLabel: {
        textTransform: 'none',
    },
}));

function SummaryRemainderEmailModal(props: any) {
    const { handleClose,value,onChange,onAdd,errorMsg } = props
    const classes = useStyles();

    return (
        <div style={{ paddingBottom: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} className={classes.title}>
                    Add Email List 
                    <IconButton
                         style={{ marginLeft: 20 , float:'right'}}
                         size="small"
                         onClick={handleClose}
                         className={`${classes.buttonLabel}`}
                        >
                            <CloseIcon className="cursor-pointer"
                                style={{ color: 'black' }}
                            />
                    </IconButton>

                </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            id="email"
                            name="email"
                            label="Add Multiple Emails Separated By Commas"
                            value={value.toLowerCase()}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <ErrorHelperText error={errorMsg} />

                    </Grid>
                    <div>
                        <Button
                            style={{ marginLeft: 18, marginTop: 7 }}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={onAdd}
                            className={`${classes.buttonLabel}`}
                        >
                            Add
                        </Button>
                    </div>
                </Grid>
        </div>
    )
}

export default SummaryRemainderEmailModal
