import { Button, Grid, TextField, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { postInviteUser, updateInviteUser, getCurrentUser, makeRequest, setAlertDialogMessage } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { ErrorHelperText } from '../Common/HelperInputFields';
import Loader from '../Common/Loader';
import MuiSelect from '@material-ui/core/Select';
import ConfirmDialog from '../Common/ConfirmDialog';
import { emailValidator, textValidator } from '../../utils'
import Axios from 'axios';
import HelperButton from '../Common/HelperButton';
import { APP_STATUS } from '../../utils/contants';

const useStyles = makeStyles((theme: Theme) => ({
    modalHeader: {
        padding: '15px',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        borderBottom: '1px solid black',
        marginBottom: 10
    },
    formGroup: {
        padding: '30px 40px',
    },
    marginBottom5: {
        marginBottom: '5px',
    },
    marginTop10: {
        marginTop: '10px',
    },
    label: {
        background: 'white',
        padding: '0px 5px',
    },
    formControl: {
        marginLeft: '0px'
    },
    selectLabel: {
        background: 'white',
        padding: '0px 10px'
    },
    buttonLabel: {
        textTransform: "none"
    }
}));

const UserModal = (props: any) => {
    const { handleClose, triggerList } = props;
    const classes = useStyles();
    const state: any = useSelector(st => st);
    const { currentUser } = state;
    const dispatch: any = useDispatch();
    const [editData, setEditData] = useState(props.editData);
    const [alertMessage, setAlertMessage] = useState<any>();
    const error: any = {};
    const [errors, setErrors] = useState(error);
    const [msgType, setMsgType] = useState("success");
    let formData: any = {
        name: '',
        email: '',
        type: '',
    };
    if (editData) {
        formData = editData;
    }
    const [form, setForm] = useState(formData);
    const [showLoader, setShowLoader] = useState(false);
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const data: any = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            delete err[name];
        }
        setErrors(err);
        setForm(data);
    };

    const showAlert = (msg: any, type: any) => {
        setAlertMessage(msg);
        setMsgType(type);
    }

    const updateResponse = (resp: any, message: string) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        setShowLoader(false);
        triggerList();
        showAlert(msg, type);
    };

    const handleDialogClose = () => {
        setAlertMessage(null);
    };

    const handleResendInvite = (id: any) => {
        Axios.post(`/api/v1/invites/resend-invite/${id}`).then(res => {
            setEditData(res.data);
        }).catch(ex => {
            console.error(ex);
            dispatch(setAlertDialogMessage('Error while completing action', 'fail'));
        });
    }

    const validateData = () => {
        const err = Object.assign({}, errors);
        if (!form.name) {
            err.name = 'Field is Required';
        } else if (!textValidator(form.name)) {
            err.name = 'Only letters,numbers and characters[.,-_] are allowed';
        }
        if (!form.email) {
            err.email = 'Field is Required';
        }
        if (form?.email){
            if (!emailValidator(form.email)) {
                err.email = "Please enter valid email address.";
            }
        }
        if (!form.type) {
            err.type = 'Field is Required';
        }

        if (Object.keys(err).length > 0) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleDeactivate = () => {
        setShowConfirmDialog(true);
    }

    const handleDeactivateUser = () => {
        setShowConfirmDialog(false);
        const status = editData && editData.status === APP_STATUS.JOINED ? APP_STATUS.INACTIVE : editData.userId != null ? 'Joined' : 'Invited'
        Object.assign(form, { status });
        dispatch(updateInviteUser(form, form.id)).then((resp: any) => {
            if (resp.status === 200 || resp.status === 201) {
                const respData = resp.data && resp.data.data;
                const currentUserData = currentUser && currentUser.data && currentUser.data.data
                if (currentUserData && respData && currentUserData.id === respData.id) {
                    dispatch(getCurrentUser())
                }
                if (editData && ((editData.status === APP_STATUS.JOINED) || (editData.status === APP_STATUS.INVITED))) {
                    updateResponse(resp, 'User activated successfully...!');
                } else {
                    updateResponse(resp, 'User deactivated successfully...!');
                }
            } else if (resp.status === 400) {
                setAlertMessage('Something went wrong. Try again...!');
            }
        });
    }

    const handleSubmit = () => {
        const checkForm = validateData();
        if (!checkForm) {
            return;
        }
        setShowLoader(true);
        if (form.id) {
            dispatch(updateInviteUser(form, form.id)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    const respData = resp.data && resp.data.data;
                    const currentUserData = currentUser && currentUser.data && currentUser.data.data
                    if (currentUserData && respData && currentUserData.id === respData.id) {
                        dispatch(getCurrentUser())
                    }
                    updateResponse(resp, 'Invited User edited successfully...!');
                } else if (resp.status === 400) {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        } else {
            dispatch(postInviteUser(form)).then((resp: any) => {
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'The user will receive an invitation email shortly.');
                } else if (resp.status === 400) {
                    setAlertMessage('Something went wrong. Try again...!');
                }
            });
        }
    };

    return (
        <div style={{ paddingBottom: '20px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.title}>
                    {editData ? 'Edit User' : 'Invite User'}
                    <HelperButton trigger="create_user_helper" />
                </Grid>
                <Grid item xs={12} className={classes.marginBottom5}>
                    <TextField
                        id="name"
                        required
                        fullWidth
                        variant='outlined'
                        label='Name'
                        margin='dense'
                        name='name'
                        value={form.name || ''}
                        onChange={handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.name} />
                </Grid>
                <Grid item xs={12} className={classes.marginBottom5}>
                    <TextField
                        disabled={editData ? true : false}
                        required
                        fullWidth
                        variant='outlined'
                        label='Email'
                        name='email'
                        id="email"
                        value={form.email.toLowerCase().trim() || ''}
                        onChange={handleChange}
                        margin='dense'
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                    />
                    <ErrorHelperText error={errors.email} />
                </Grid>
                <Grid item xs={12} className={classes.marginBottom5}>
                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl}>
                        <InputLabel className={classes.selectLabel} ref={inputLabel} id="roleLabel">
                            Role*
                        </InputLabel>
                        <MuiSelect
                            labelId="roleLabel"
                            id="type"
                            name="type"
                            value={form.type}
                            onChange={handleChange}
                            fullWidth
                            disabled={editData && currentUser && currentUser?.data?.data?.uniqueId === editData.email ? true : false}
                        >
                            <MenuItem value="admin">Xylem Admin</MenuItem>
                            <MenuItem value="xylem_customer_admin">Xylem Customer Admin</MenuItem>
                            <MenuItem value="xylem_patch_admin">Xylem Patch Admin</MenuItem>
                            <MenuItem value="xylem_service_user">Xylem Service Account User</MenuItem>
                        </MuiSelect>
                    </FormControl>
                    <ErrorHelperText error={errors.type} />
                </Grid>
                <Grid item xs={12} className={classes.marginTop10}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item sm={7} container justifyContent="flex-start">
                            {editData && currentUser && currentUser?.data?.data?.uniqueId !== editData.email && <>
                            {(editData && editData.status === APP_STATUS.JOINED) &&
                                <div><Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={handleDeactivate}
                                >
                                    Deactivate User
                                </Button>
                                    <HelperButton trigger="deactivate_user" />
                                </div>}

                            {(editData && (editData.status === APP_STATUS.INVITED || (editData.status === APP_STATUS.INVITE_REJECTED))) && <div>
                            {editData.type === form.type && <>
                                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={e => handleResendInvite(editData.id)}
                                >
                                    Resend Invite
                                </Button>
                                <HelperButton trigger="resend_user_invite" />
                                <span className="w3-text-grey w3-tiny w3-margin">
                                    {(editData.status !== APP_STATUS.INVITE_REJECTED) && <>
                                    {moment(editData.expiresAt).isBefore(Date.now()) ? "Current Invite Expired " : "Current Invite Expires "}
                                    {moment(editData.expiresAt).fromNow()}
                                    </>}
                                </span>
                            </>}
                            </div>

                            }
                            {editData && editData.status === APP_STATUS.INACTIVE ?
                                <Button
                                    color="primary"
                                    variant='contained'
                                    size='small'
                                    className={classes.buttonLabel}
                                    onClick={handleDeactivate}
                                >
                                    Activate User
                                </Button>
                                : ''}
                            </>}
                        </Grid>
                        <Grid item container sm={5} justifyContent="flex-end">
                            <Button
                                variant='contained'
                                size='small'
                                className={`w3-margin-right ${classes.buttonLabel}`}
                                onClick={handleClose}
                            >Cancel
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                className={`${classes.buttonLabel}`}
                                onClick={handleSubmit}
                            >
                                {editData ? 'Save' : 'Invite'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                            handleClose();
                        }}
                        message={alertMessage}
                        type={msgType}
                        handleDialogClose={handleDialogClose}
                    />
                )
                : null
            }
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeactivateUser}
                        message={'Are you sure to activate/deactivate the user ?'}
                    />
                ) : null}
            {showLoader ? <Loader open={showLoader} /> : null}
        </div>
    );
};

export default UserModal;
