import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, CircularProgress, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import AlertDialog from '../Common/AlertDialog';
import TeamModal from './TeamModal';
import TeamFilters from './TeamFilters';
import moment from 'moment';
import { getTeamList, destroyTeam } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import Pagination from '../Common/Pagination';
import DialogModal from '../Common/DialogModal';
import ConfirmDialog from '../Common/ConfirmDialog';
import TitleHeader from '../Common/TitleHeader';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSearchParams } from 'react-router-dom';
import { Can } from '../../Context/Can'
import TableUI from '../Common/TableUI';
import { removeNonValueFilters } from '../../utils';
import { SUBJECTS } from '../../utils/contants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '8px',
  },
  addPaper: {
    padding: '25px',
    height: 180,
    background: '#FFFFFF',
  },
  addBtnText: {
    verticalAlign: 'middle',
    lineHeight: '130px',
    textAlign: 'center',
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    // padding: '10px',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '18vw',
    },

    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
  },
  content: {
    padding: '0px 5px 5px 5px',
    marginBottom: '8px',
  },
  contentText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      width: '15vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40vw',
    },
  },
  paginateTopPadding: {
    paddingTop: '50px'
  },
  toolTip: {
    fontSize: '13px'
  },
  customToolTip: {
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 5px',
    borderRadius: '5px',
    color: 'white',
    fontSize: '13px'
  },
  logo: {
    height: 68,
  },
  table: {
    minWidth: 700,
  },
  tHead: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tDef: {
    fontSize: 15,
  },
  tDefName: {
    fontSize: 15,
    '&:hover': {
      color: '#0085ad',
    },
  },
  breadCrumb: {
    color: "#0085ad",
    paddingTop: 10,
  },
  buttonLabel: {
    textTransform: "none",
    backgroundColor: "#0085ad",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#0085ad',
    },
  },
  actionCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default function ManageTeams(props: any) {
  const { customerId,customer } = props;
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const initialData: any[] = [];
  let manageTeams: any = null;
  const [data, setData] = useState(initialData);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [alertMessage, setAlertMessage] = useState<any>(); 
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 12;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<any>(Object.assign({}));
  const [queryParams, setQueryParams] = useSearchParams();
  const existingParams = Object.fromEntries([...queryParams]);
  const tableColumns = ['Team', 'Department', 'Members', 'Created', 'Action']

  const fetchData = () => {
    let page: number = parseInt(queryParams.get('page'));
    const params = {
      ...Object.fromEntries(queryParams),
      id: customerId,
      offset: (page ? page - 1 : 0) * limit
    }
    setIsLoading(true);
    dispatch(getTeamList(params)).then((res: any) => {
      if (res && res.status === 200) {
        const respData = res.data
        if (!respData) {
          return;
        }
        setData(respData.data);
        setTotalCount(respData.total);
        setIsLoading(false);
      }
    });
  };
 

  const handleDeleteTeam = () => {
    if (selectedTeam) {
      setShowConfirmDialog(false);
      dispatch(destroyTeam(selectedTeam.id, customerId)).then((resp: any) => {
        if (resp && resp.status === 200) {
          const result = resp.data;
          if (!result.success) {
            setAlertMessage(result.message);
            return;
          }
          setSelectedTeam({});
          setAlertMessage(result.message);
          fetchData();
        } else {
          setAlertMessage('Team deletion failed');
        }
      });
    }
  };

  const triggerDelete = (team: any) => {
    setSelectedTeam(team);
    setShowConfirmDialog(true);
  }

  const updateQueryParams = (params: any) => {
    const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
    setQueryParams(nParams);
  }

  const handleSearch = (value: string) => {
    updateQueryParams({ search: value, page: 1 });
  }

  const handlePagination = (page: number, lmt: number) => {
    updateQueryParams({ page, limit: lmt });
  };

  const handleTeamFilters = (filters: any) => {
    const { teamId="" } = filters;
    updateQueryParams({ teamId, page: 1 });
  }

  let teamListTable: any[] = [];

  if (data && data.length > 0) {
    teamListTable = data.map((item) => (
      <TableRow key={item.name}>
        <TableCell className={classes.tDefName} align="left">
          <Link to={`/customer/${customerId}/team/${item.id}`}>{item.name}</Link>
        </TableCell>
        <TableCell className={classes.tDef} align="left">{item.department}</TableCell>
        <TableCell className={classes.tDef} align="left">{item.teamUsers_count}</TableCell>
        <TableCell className={classes.tDef} align="left">
          {moment(item.createdAt).format('Do MMM YYYY')}
        </TableCell>
        <TableCell className={`${classes.tDef} ${classes.actionCell}`} align="left">
          <Can do="manage" on={SUBJECTS.TEAM}>
            <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => {
                  setEditData(item)
                  setOpen(true)
                }}>
                  <EditIcon className="cursor-pointer" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton aria-label="delete" onClick={() => triggerDelete(item)}>
                  <DeleteIcon className="cursor-pointer"  />
                </IconButton>
              </Tooltip>
            </Box>
          </Can>
        </TableCell>
      </TableRow>
    ))
  }
  if (isLoading || !data) {
    manageTeams = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <div className="w3-center">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (data && data.length) {
    manageTeams = teamListTable;
  } else if (data && data.length === 0) {
    manageTeams = (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <h5>
            No Teams Found
              </h5>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <div >
      <Can do="manage" on={SUBJECTS.ALL_CUSTOMERS}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumb}>
          <Link to={`/customers`}>{'Customers'}</Link>
          <Typography color="textPrimary">{customer && customer.name}</Typography>
          <Typography color="textPrimary">{'Teams'}</Typography>
        </Breadcrumbs>
      </Can>
      <Can I="view" a={SUBJECTS.BREADCRUMB}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadCrumb}>
          <Typography color="textPrimary">{customer && customer.name}</Typography>
          <Typography color="textPrimary">{'Teams'}</Typography>
        </Breadcrumbs>
      </Can>
      <TitleHeader
        component={
          <Can do="manage" on={SUBJECTS.TEAM}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={` ${classes.buttonLabel}`}
              onClick={() => {
                setEditData(null);
                setOpen(true);
              }}
            >
              Add Team
        </Button>
          </Can>
        }
        showSearch={true}>
        <div style={{ marginRight: '10px' }}>
          <SearchBox
            value={queryParams.get('search') || ''}
            placeholder={'Search Teams...'}
            search={handleSearch}
          />
        </div>
        <TeamFilters
          customerId={customerId}
          filterData={existingParams}
          handleTeamFilters={handleTeamFilters}
        />
      </TitleHeader>
      <Grid container>
        <TableUI
          tableContent={manageTeams}
          tableColumns={tableColumns}
          stickyHeader={false}
          scollable={false}
          classes={classes}
          borderTop={false}
        />


        {(data && data.length > 0 && totalCount > limit) && (
          <Grid container className={`w3-center ${classes.paginateTopPadding}`}>
            <Pagination
              cPage={parseInt(queryParams.get('page'))}
              defaultPerPage={limit}
              data={{ totalCount }}
              onChange={handlePagination}
            />
          </Grid>)}
      </Grid>

      {open ? (<DialogModal
        fullWidth
        isOpen={open}
        hideTitle={true}
        hideBtns={true}
        maxWidth="sm"
        dialogContent={(
          <TeamModal
            triggerList={() => {
              // setSearchStr('')
              // setCurrentPage(1);
              fetchData()
            }}
            customerId={customerId}
            open={open}
            editData={editData}
            handleClose={() => {
              setOpen(false);
              setEditData(null);
            }}
          />
        )}
      />)
        : null}
      {alertMessage ? (
        <AlertDialog
          handleClose={() => setAlertMessage(null)}
          message={alertMessage}
        />) : null}
      {showConfirmDialog
        ? (
          <ConfirmDialog
            handleCancel={() => {
              setSelectedTeam({})
              setShowConfirmDialog(false)
            }}
            handleOk={handleDeleteTeam}
            message={'Are you sure to delete the Team?'}
          />
        ) : null}
    </div>
  );
}
