
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs } from "@material-ui/core";
import { Can } from '../../Context/Can';
import { SUBJECTS } from '../../utils/contants';
export const CustomerBreadcrumbs = (props: any) => {
  const { customer} = props;
  let bc = <Can do="manage" on={SUBJECTS.ALL_CUSTOMERS}>
    <Breadcrumbs separator="›" aria-label="breadcrumb" >
      <Link to={`/customers`}>{'Customers'}</Link>
      <Link to={`/customer/${customer.id}`}>{customer.name}</Link>
      {props.children}
    </Breadcrumbs>
  </Can>;
  if (!bc) {
    bc = <Can I="view" a={SUBJECTS.BREADCRUMB}>
      <Breadcrumbs separator="›" aria-label="breadcrumb" >
        <Link to={`/customer/${customer.id}`}>{customer.name}</Link>
        {props.children}
      </Breadcrumbs>
    </Can>
  }
  return <>
    {bc}
  </>
}