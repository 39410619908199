import React from 'react';
import { Card, CardContent, CardActions, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    }
}))

const CountCard = (props: any) => {
    const { title, count, btnText, handleClick } = props;
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className="w3-center">
                <Typography variant="h4" color="textSecondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h3" gutterBottom>
                    {count}
                </Typography>
            </CardContent>
            <CardActions>
                <Button className="button" onClick={handleClick} fullWidth>
                    {btnText}
                </Button>
            </CardActions>
        </Card>
    )
};

export default CountCard;